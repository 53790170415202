<template>
	<div>
		<MobileMenu />
		<TopBar /> 
		<div class="wrapper">
			<div class="wrapper-box">
				<nav class="side-nav side-nav--simple">
					<ul>
						<template v-for="(menu, menuKey) in formattedMenu">
							<li v-if="menu == 'devider'" :key="menu + menuKey" class="side-nav__devider my-6"></li>
							<li v-else :key="menu + menuKey">
								<Tippy
									tag="a"
									:content="menu.title"
									:options="{placement: 'left'}"
									:href="
									menu.subMenu
										? 'javascript:;'
										: router.resolve({ name: menu.pageName }).path
									"
									class="side-menu"
									v-if="User.role && menu.role_id && menu.role_id!=User.role.role_id ? false : true && menu.roles ? User.role ? menu.roles.includes(User.role.role_id) : false ? true : false : true"
									:class="{'side-menu--active': menu.active, 'side-menu--open': menu.activeDropdown}"
									@click="linkTo(menu, router, $event)"
								>
									<div class="side-menu__icon">
										<component :is="menu.icon" class="w-5 h-5" />
									</div>
									<div class="side-menu__title">
										{{ menu.title }}
										<div
											v-if="menu.subMenu"
											class="side-menu__sub-icon"
											:class="{ 'transform rotate-180': menu.activeDropdown }">
											<ChevronDownIcon />
										</div>
									</div>
								</Tippy>
								
								<transition @enter="enter" @leave="leave">
									<ul v-if="menu.subMenu && menu.activeDropdown">
										<li v-for="(subMenu, subMenuKey) in menu.subMenu" :key="subMenuKey">
											<Tippy
												tag="a"
												:content="subMenu.title"
												:options="{placement: 'left'}"
												:href="
													subMenu.subMenu
													? 'javascript:;'
													: router.resolve({ name: subMenu.pageName }).path
												"
												class="side-menu"
												v-if="User.role && subMenu.role_id && subMenu.role_id!=User.role.role_id ? false : true"
												:class="{ 'side-menu--active': subMenu.active }"
												@click="linkTo(subMenu, router, $event)"
											>
												<div class="side-menu__icon">
													<component :is="subMenu.icon" class="w-4 h-4 ml-2" />
												</div>
												<div class="side-menu__title">
													{{ subMenu.title }} 
													<div
														v-if="subMenu.subMenu"
														class="side-menu__sub-icon"
														:class="{'transform rotate-180': subMenu.activeDropdown}">
														<ChevronDownIcon />
													</div>
												</div>
											</Tippy>

											<!-- BEGIN: Third Child -->
											<transition @enter="enter" @leave="leave">
												<ul v-if="subMenu.subMenu && subMenu.activeDropdown">
													<li
														v-for="(lastSubMenu,
														lastSubMenuKey) in subMenu.subMenu"
														:key="lastSubMenuKey"
													>
														<SideMenuTooltip
															tag="a"
															:content="lastSubMenu.title"
															:href="
															lastSubMenu.subMenu
																? 'javascript:;'
																: router.resolve({
																	name: lastSubMenu.pageName
																}).path
															"
															class="side-menu"
															:class="{'side-menu--active': lastSubMenu.active}"
															@click="linkTo(lastSubMenu, router, $event)"
														>
															<div class="side-menu__icon">
																<ZapIcon />
															</div>
															<div class="side-menu__title">
																{{ lastSubMenu.title }}
															</div>
														</SideMenuTooltip>
													</li>
												</ul>
											</transition>
											<!-- END: Third Child -->
										</li>
									</ul>
								</transition>

							</li>
						</template>
					</ul>
				</nav>
				<div class="content">
					<router-view />
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { defineComponent, computed, onMounted, ref, watch, onBeforeMount } from "vue";
import { useRoute, useRouter }				from "vue-router";
import { linkTo, nestedMenu, enter, leave }	from "./index";
import { useStore } 						from "@/store";
import cash 								from "cash-dom";

import TopBar 				from "@/components/visuals/top-bar/Main.vue";
import MobileMenu 			from "@/components/visuals/mobile-menu/Main.vue";
import DarkModeSwitcher 	from "@/components/visuals/dark-mode-switcher/Main.vue";
import SideMenuTooltip 		from "@/components/visuals/side-menu-tooltip/Main.vue";

import * as rosepetalModel 	from "rosepetal-model";

const $helper	= rosepetalModel.default.helper;
const $ui	 	= rosepetalModel.default.ui;

export default defineComponent({
	components: {
		TopBar,
		MobileMenu,
		DarkModeSwitcher,
		SideMenuTooltip
	},

	setup() {
		const route 		= useRoute();
		const router 		= useRouter();
		const store 		= useStore();

		const formattedMenu	= ref([]);
		const mainMenu 		= ref();

		const sideMenu 		= computed(() => nestedMenu(store.state.sideMenu.menu, route));
		const User 			= computed(() => store.state.main.User);

		onBeforeMount(async () => {
			mainMenu.value = await $ui.getMainMenu();
			sideMenu.value = nestedMenu(mainMenu.value, route);
			store.dispatch("sideMenu/setSideMenu", mainMenu.value);
			formattedMenu.value = $helper.toRaw(sideMenu.value);
		});

		onMounted(() => {
			cash("body")
				.removeClass("error-page")
				.removeClass("login")
				.addClass("main");
			formattedMenu.value = $helper.toRaw(sideMenu.value);
		});

		watch(
			computed(() => route.path),
			() => {
				formattedMenu.value = $helper.toRaw(sideMenu.value);
			}
		);

		return {
			formattedMenu,
			router,
			User,
			linkTo,
			enter,
			leave
		};
	}
});
</script>

<style scoped>
	.side-nav{ padding-left: 0px !important; width: 70px !important; }
	.side-nav .side-menu{ margin-left: 10px !important; padding-left: 11px !important;}
</style>