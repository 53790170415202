<template>
	<div>
		<div class="container sm:px-10">
			<div class="block xl:grid grid-cols-2 gap-4">

				<!-- Login info -->
				<div class="hidden xl:flex flex-col min-h-screen">
					<a class="-intro-x flex items-center pt-5">
						<img class="w-80" :src="Brand" />
					</a>
					<div class="my-auto">
						<img class="-intro-x w-1/3 -mt-5 rplogo ml-10" :src="Icon" />
						<div class="-intro-x text-white font-medium text-4xl leading-tight mt-10">
							{{ $t('AI Visual inspection Platform') }}
						</div>
						<div class="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-gray-500">
							{{ $t('Login to access your management panel') }}.
						</div>
					</div>
				</div>

				<!-- Login form -->
				<div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
					<div
						class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
						<form @submit.prevent="Login">
							<div class="intro-x flex justify-center items-center sm:w-full">
								<img class="company-logo" :alt="firebaseConfig.projectId" :src="Logo">
							</div>
							<h2 class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left mt-10">
								{{ $t('Welcome') }}
							</h2>
							<div class="intro-x mt-8">
								<input type="text" class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
									:placeholder="$t('Email')" v-model="email" />
								<input type="password"
									class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
									:placeholder="$t('Password')" v-model="password" />
							</div>
							<div class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4">
								<div class="flex items-center mr-auto">
									<input id="remember-me" type="checkbox" class="form-check-input border mr-2" />
									<label class="cursor-pointer select-none" for="remember-me">{{ $t('Remember') }}</label>
								</div>
								<a href="#" class="hidden">{{ $t('Forgotten your password?') }}</a>
							</div>

							<div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
								<button type="submit" class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"> {{
									$t('Log in') }}
								</button>
							</div>
							<div class="intro-x mt-10 xl:mt-24 text-gray-700 dark:text-gray-600 text-center xl:text-left">
								{{ $t('By login, I accept the') }}<br />
								<a class="text-theme-17 dark:text-gray-300" href="">{{ $t('Terms of Service') }}</a>
								and the
								<a class="text-theme-17 dark:text-gray-300" href="">{{ $t('Privacy Policy') }}</a>
							</div>
						</form>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
import { ref, onMounted } 	from "vue";
import { useRouter }		from 'vue-router';
import { helper as $h } 	from "@/utils/helper";
import cash 				from "cash-dom";

import * as rosepetalModel 	from "rosepetal-model";
import config 				from '@/etc/rosepetal.json';

let $_firebase 			= rosepetalModel.default._firebase;
const firebaseConfig	= config.firebaseConfig;

export default {
	setup() {
		const router 	= useRouter();
		const email 	= ref("");
		const password 	= ref("");
		const Logo		= ref();
		const Brand 	= ref();
		const Icon 		= ref();

		const Login = async () => {
			try {
				const resp = await $_firebase.signInWithEmailAndPassword(email.value, password.value);
				
				if (resp.status) {
					const redirectPath = sessionStorage.getItem('redirect') || '/';
					router.replace(redirectPath);
				} else {
					$h.NotificationTxt({ text: "Wrong credentials" });
					password.value = "";
				}
			} catch (error) {
				alert(error);
			}
		}

		onMounted(() => {
			try { Logo.value = require(`@/assets/logo/` + firebaseConfig.projectId + `.jpg`)
			} catch (e) { Logo.value = require(`@/assets/logo/ais-vision-ai.jpg`) }

			try { Brand.value = require(`@/assets/logo/brand/` + config.brand + `_login.png`)
			} catch (e) { Brand.value = require(`@/assets/images/logo/logorosepetal.png`) }

			try { Icon.value = require(`@/assets/logo/brand/` + config.brand + `_icon.png`)
			} catch (e) { Icon.value = require(`@/assets/images/logo/rpicon2.png`) }

			cash("body")
				.removeClass("main")
				.removeClass("error-page")
				.addClass("login");
		});

		return {
			Login,
			email,
			password,
			firebaseConfig,
			Logo,
			Brand,
			Icon
		}
	}
}
</script>

<style>
.rplogo {
	max-width: 300px;
	opacity: 0.4 !important
}

.company-logo {
	max-width: 350px;
}
</style>