<template>
	<div class="w-full h-full p-0 m-0 hidden">
		<template v-if="layout.error">
			<div class="flex flex-col items-center text-center pt-32">
				<img :src="require('@/assets/images/rosepetal/icon/errorNet.png')" class="w-32" />
				<div class="mt-5 text-gray-600">{{ $t('Controller is offline') }}</div>
				<button class="btn flex items-center bg-gray-100 border-gray-300 py-1 px-8 mt-3 text-sm font-normal hover:bg-blue-900 hover:text-white" @click="forceReload()">{{ $t('Retry') }}</button>
			</div>
		</template>

		<template v-else>
			<div id="rpNodeCol1" class="float-left h-full w-full"></div>
			<div id="rpNodeCol2" class="float-right bg-gray-200 text-gray-700 overflow-x-hidden" 
				:style="`background-image: url(${require(`@/assets/images/rosepetal/icon/backnode1.png`)}); background-position: center; background-size: cover; background-repeat: no-repeat; height: 100%; min-height: 100vh; overflow-y: scroll;`">
				<div class="h-full">
					<template v-if="layout.loading">
						<div class="intro-y col-span-12 lg:col-span-12 flex flex-col items-center text-black" style="z-index: 999 !important;position: absolute;width: 25%;height: 100vh;background-color: rgba(255,255,255,0.9);top: 0 !important;padding-top: 150px;">
							<img class="w-24 h-auto" :src="require(`@/assets/images/rosepetal/icon/logoLoadingGray.png`)" />
							<LoadingIcon icon="three-dots" class="w-12 h-12" />
							<div class="text-black font-medium text-lg">{{ layout.loadingTxt }}</div>
							<div>{{ $t('Please wait') }}...</div>
						</div>
					</template>
					<div class="p-5">
						<div class="text-2xl text-theme-1 font-medium">{{ $t('Flow Assistant') }}</div>
						<div class="text-sm text-gray-600" v-if="layout.curflow" >{{ $t('Select flow') }}:</div>
						<div v-if="!layout.curflow" class="w-full">
							<div class="text-center mx-20">
								<img class="w-full h-auto" :src="require(`@/assets/images/rosepetal/icon/flow1.png`)" />
							</div>
						</div>
						<select class="w-full flex-col form-select p-3 cursor-pointer mt-2 text-gray-800 border-none mb-5" :class="!layout.curflow ? 'bg-yellow-200' : 'bg-gray-200'" v-model="layout.curflow" @change="forceReload()">
							<template v-if="!layout.curflow"><option :value="false">- {{ $t('Select flow') }} -</option></template>
							<option :value="f" v-for="(f,idx) in layout.flows.tabs" :key="idx" :selected="f.id==layout.curflow.id">{{f.label}}</option>
						</select> 
						<ul id="rpFlowAssistantOpt" class="nav nav-tabs text-sm m-0" v-if="layout.curflow">
							<li class="nav-item w-1/2 cursor-pointer border-r border-gray-600 p-3 text-center py-2" @click="layout.tab='import'" :class="layout.tab=='import' ? 'selecTab' : ''"><a class="nav-link" :class="layout.tab=='import' ? 'active' : ''">Import nodes</a></li>
							<li class="nav-item w-1/2 cursor-pointer p-3 text-center py-2" @click="layout.tab='modify'" :class="layout.tab=='modify' ? 'selecTab' : ''"><a class="nav-link"  :class="layout.tab=='modify' ? 'active' : ''">Modify flow</a></li>
						</ul>
						<div class="flex flex-col w-full">
							<div class="tab-content mt-5">
								<div class="tab-pane fade show p-0" role="tabpanel" :class="layout.tab=='import' ? 'active' : ''">
									<div class="rpFlowAssistantTools">
										<template v-if="layout.choices" class="w-full">
											<ul>
												<li id="faReqNode" class="nav selected" @click="assistantMenu('faReqNode')"><span>{{ $t('Request Node Flow') }}</span><span class="arrow"></span></li>
												<li class="menu-content faReqNode sel">
													<div class="p-5 text-sm">
														{{ layout.openai.user }}
														<div class="mt-5">
															<button class="btn bg-theme-2 text-white w-full py-2 text-sm zoom-in" style="border-radius: 0;" @click="newRequest()">{{ $t('Modify') }}</button>
														</div>
													</div>
												</li>
											</ul> 
											<ul>
												<li id="faInitFlow" class="nav" @click="assistantMenu('faInitFlow')"><span style="font-size: 18px !important;">{{ $t('Initial Flow') }}</span><span class="arrow"></span></li>
												<li class="menu-content faInitFlow">
													<div class="p-5 text-sm">
														<button class="btn bg-theme-5 text-white w-full px-3 py-2 text-sm zoom-in"  @click="recoveryInitFlow()" style="border-radius: 0;" v-if="layout.curflowInit">{{ $t('Recover initial flow') }}</button>
													</div>
												</li>
											</ul> 
											<ul v-for="(c,idx) in layout.choices" :key="idx">
												<li :id="'faResp'+idx" class="nav" :class="!idx ? 'selected' : ''" 
													@click="assistantMenu('faResp'+idx)"><span style="font-size: 18px !important;">{{ $t('Variation')+" "+(idx+1) }}</span><span> - Import {{ Object.keys(c.nodes).length }} new nodes</span><span class="arrow"></span></li>
												<li class="menu-content p-5" :class="!idx ? 'sel faResp'+idx : 'faResp'+idx">
													<div v-for="(n,ix) in c.nodes" :key="ix" class="flex flex-row items-center mt-1">
														<textarea :id="'text-response-'+idx+'-'+ix" v-model="layout.nodesToImport['text-response-'+idx+'-'+ix]" class="form-control p-3 prompt-textarea-response" spellcheck="false"></textarea>
													</div>
													<button class="btn mt-2 mb-2 text-white w-full px-3 py-2 text-lg zoom-in" @click="importNodes(idx)" style="border-radius: 0; background-color: #15a17e;">
														<Corner-up-leftIcon class="mr-2" /> {{ $t('Send to flow') }}
													</button>
												</li>
											</ul>  
										</template>
										<div v-else>
											<template v-if="layout.curflow">
												<ul>
													<li id="faSystem" class="nav" @click="assistantMenu('faSystem')"><span>{{ $t('System context') }}</span><span class="arrow"></span></li>
													<li class="menu-content faSystem">
														<textarea class="form-control text-gray-900 p-3 prompt-textarea" v-model="layout.openai.system" spellcheck="false"></textarea>
													</li>
													<li id="faUser" class="selected nav" @click="assistantMenu('faUser')"><span>{{ $t('Describe the node flow') }}</span><span class="arrow"></span></li>
													<li class="menu-content faUser sel">
														<textarea class="form-control p-3 prompt-textarea" v-model="layout.openai.user" spellcheck="false"></textarea>
														<div class="py-2 px-1 text-sm text-blue-500 cursor-pointer" v-if="layout.pretrained" @click="modal('model-import-examples')">{{ $t('Examples') }}</div>
													</li>
													<li id="faVariations" class="nav" @click="assistantMenu('faVariations')"><span>{{ $t('Variations') }}</span><span class="arrow"></span></li>
													<li class="menu-content faVariations">
														<div class="p-5 pt-2">
															{{ $t('Select number of variations to generate') }}:
															<select class="w-full flex-col form-select p-2 cursor-pointer text-gray-600 mt-2" v-model="layout.openai.variations">
																<option v-for="v in 10" :value="v" :key="v">{{ v }}</option>
															</select> 
														</div>
													</li>
												</ul>
												<button class="btn mt-2 text-white w-full px-3 py-4 text-lg zoom-in" @click="generateNodes()" style="border-radius: 0; background-color: #15a17e;">{{ $t('Send to assistant') }}</button>
												<div id="model-import-examples" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
													<div class="modal-dialog modal-lg">
														<div class="modal-content">
															<label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" data-dismiss="modal"><XIcon class="w-8 h-8" /></label>
															<div class="modal-body p-5 text-center">
																<div class="text-2xl text-theme-1 font-medium">{{ $t('Flow Assistant') }}</div>
																<div class="text-sm text-gray-600" v-if="layout.curflow" >{{ $t('Import nodes examples') }}</div>
																<div class="mt-5 text-left" v-if="layout.pretrained">
																	<div class="p-3 border mb-2 cursor-pointer bg-gray-100 text-gray-600 hover:bg-blue-500 hover:text-white" v-for="(ex,idx) in layout.pretrained" :key="idx" @click="loadExample(idx)">
																		<span class="float-right align-middle text-theme-1"><PlusIcon class="w-6 h-6" /></span>
																		<span class="pr-5">{{ idx }}</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</template>
										</div>
									</div>
								</div>

								<div class="tab-pane fade p-0" role="tabpanel" :class="layout.tab=='modify' ? 'active' : ''">
									<div class="rpFlowAssistantTools">
										<template v-if="layout.editChoices" class="w-full">
											<ul>
												<li id="faEditReqNode" class="nav selected" @click="assistantMenu('faEditReqNode')">
													<span>{{ $t('Request modify flow') }}</span>
													<span class="arrow"></span>
												</li>
												<li class="menu-content faEditReqNode sel">
													<div class="p-5 text-sm">
														{{ layout.openai.edit.user }}
														<div class="mt-5">
															<button class="btn bg-theme-2 text-white w-full py-2 text-sm zoom-in" style="border-radius: 0;" @click="newEditRequest()">{{ $t('Modify') }}</button>
														</div>
													</div>
												</li>
											</ul> 
											<ul>
												<li id="faEditInitFlow" class="nav" @click="assistantMenu('faEditInitFlow')">
													<span style="font-size: 18px !important;">{{ $t('Initial Flow') }}</span>
													<span class="arrow"></span>
												</li>
												<li class="menu-content faEditInitFlow">
													<div class="p-5 text-sm">
														<button class="btn bg-theme-5 text-white w-full px-3 py-2 text-sm zoom-in"  @click="recoveryInitFlow()" style="border-radius: 0;" v-if="layout.curflowInit">{{ $t('Recover initial flow') }}</button>
													</div>
												</li>
											</ul> 
											<ul v-for="(c, idx) in layout.editChoices" :key="idx">
												<li :id="'faEditResp' + idx" class="nav" :class="!idx ? 'selected' : ''" 
													@click="assistantMenu('faEditResp' + idx)">
													<span style="font-size: 18px !important;">{{ $t('Variation') + " " + (idx + 1) }}</span>
													<span> - {{ Object.keys(c.nodes).length }} new nodes</span>
													<span class="arrow"></span>
												</li>
												<li class="menu-content p-5" :class="!idx ? 'sel faEditResp' + idx : 'faEditResp' + idx">
													<div v-for="(n, ix) in c.nodes" :key="ix" class="flex flex-row items-center mt-1">
														<div class="w-full">
															<textarea :id="'text-edit-response-' + idx + '-' + ix" 
																v-model="layout.nodesToImport['text-edit-response-' + idx + '-' + ix]" class="form-control p-3 prompt-textarea-response" spellcheck="false"></textarea>
														</div>
													</div>
													<button class="btn mt-2 mb-2 text-white w-full px-3 py-2 text-lg zoom-in" @click="importEditNodes(idx)" style="border-radius: 0; background-color: #15a17e;">
														<Corner-up-leftIcon class="mr-2" /> {{ $t('Send to flow') }}
													</button>
												</li>
											</ul>  
										</template>
										<template v-else>
											<ul>
												<li id="faEditSystem" class="nav" @click="assistantMenu('faEditSystem')">
													<span>{{ $t('System context') }}</span>
													<span class="arrow"></span>
												</li>
												<li class="menu-content faEditSystem">
													<textarea class="form-control text-gray-900 p-3 prompt-textarea" v-model="layout.openai.edit.system" spellcheck="false"></textarea>
												</li>
												<li id="faEditUser" class="selected nav" @click="assistantMenu('faEditUser')">
													<span>{{ $t('Describe the changes') }}</span>
													<span class="arrow"></span>
												</li>
												<li class="menu-content faEditUser sel">
													<textarea class="form-control p-3 prompt-textarea" v-model="layout.openai.edit.user" spellcheck="false"></textarea>
												</li>
												<li id="faEditVariations" class="nav" @click="assistantMenu('faEditVariations')">
													<span>{{ $t('Variations') }}</span>
													<span class="arrow"></span>
												</li>
												<li class="menu-content faEditVariations">
													<div class="p-5 pt-2">
														{{ $t('Select number of variations to generate') }}:
														<select class="w-full flex-col form-select p-2 cursor-pointer text-gray-600 mt-2" v-model="layout.openai.edit.variations">
															<option v-for="v in 10" :value="v" :key="v">{{ v }}</option>
														</select> 
													</div>
												</li>
											</ul>  
											<button class="btn mt-2 text-white w-full px-3 py-4 text-lg zoom-in" style="border-radius: 0; background-color: #15a17e;" @click="modifyFlow()">{{ $t('Send to assistant') }}</button>
										</template>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div style="overflow-y: scroll; min-height: 100%;"></div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import { defineComponent,onMounted, computed, ref, onUnmounted, watch} from "vue";
import { useStore }			from "@/store";
import cash 				from "cash-dom";

import * as rosepetalModel	from "rosepetal-model";
import config				from '@/etc/rosepetal.json';

const nodeConfig = config.nodeConfig;

let $controller	= rosepetalModel.default.controller;
let $nodeRed	= rosepetalModel.default.nodeRed;
let $openai		= rosepetalModel.default.openai;
let $ui 		= rosepetalModel.default.ui;
let $user 		= rosepetalModel.default.user;

export default defineComponent({
	setup() {
		const store		= useStore();   
		const User		= computed(() => store.state.main.User);
		const userData	= computed(() => store.state.main.userData);

		const layout	= ref({ 
			loading:    	false,
			loadingTxt: 	false,
			error:      	false, 
			tab:        	'import',
			host:        	false,
			curflow:     	false,
			curflowInit:	false,
			flows:       	false,
			openai:	{
				system:     "",
				user:       "",
				variations: 1,
				edit: {
					system:     "",
					user:       "",
					variations: 1,
				}
			},
			choices: false,
			nodesToImport: {},
			pretrained:  {},
		});

		const modal       	= (m) => { cash("#"+m).modal("show"); cash(".main").removeClass("overflow-y-hidden")};
		const modalClose  	= (m) => { cash("#"+m).modal("hide"); };
		const modalRemove 	= (m) => { cash("#"+m).remove(); };

		const loadExample = (e) => {
			modalClose("model-import-examples");
			if (layout.value.pretrained[e]) layout.value.openai.user = e;
			layout.value.openai.variations = 1;
		}
    
		const loadNR = async (url) => {  
			document.getElementById("flowFrame").style.display = 'block';
			let host = $controller.host;
			if ($controller.zerotierEnabled) host = $controller.zerotier;

			let loadSrc = host + "/nodered" + url + "?uid=" + User.value.uid;

			if (layout.value.curflow) {
				loadSrc += "&flow=" + layout.value.curflow.id;
				loadSrc += "#flow/" + layout.value.curflow.id;
			}

			document.getElementById("flowFrame").src = loadSrc;
			layout.value.error = false;
		}

		const modifyFlow = async () => { 
			layout.value.loading = true;
			layout.value.loadingTxt = "Generating nodes";
			const initialFlow = await $nodeRed.getFlow({flowId: layout.value.curflow.id});

			$openai.chatCompletion({
				n: layout.value.openai.edit.variations,
				system: layout.value.openai.edit.system + JSON.stringify(initialFlow.nodes),
				user: layout.value.openai.edit.user,
			}).then(async (choices) => {
				layout.value.loadingTxt = "Import nodes";
				let editChoices = $openai.extractNodes({choices: choices});
				layout.value.editChoices = editChoices;
				layout.value.loading = false;
				if (editChoices.length) {
					for (var ix = 0; ix < editChoices.length; ix++) {
						var i = 0;
						for (const _n of Object.keys(editChoices[ix].nodes)) {  
							console.log('item', "text-edit-response-" + ix + "-" + i, _n);

							layout.value.nodesToImport["text-edit-response-" + ix + "-" + i] =  JSON.stringify(editChoices[ix].nodes[i]);
							i++;
						}
					}
				}
			});
		}

		const generateNodes = () => {  
			layout.value.loading = true;
			layout.value.loadingTxt = "Generating nodes";
			let isPretained = pretrained(layout.value.openai.user);

			if (isPretained) {
				layout.value.loadingTxt = "Import nodes";
				var _nodesPretrained = false;
				try { 
					_nodesPretrained = JSON.parse(isPretained);
					layout.value.choices = [];
					layout.value.choices.push({ nodes: _nodesPretrained }); 
					for (var ix = 0; ix < _nodesPretrained.length; ix++) {
						layout.value.nodesToImport["text-response-0-" + ix] =  JSON.stringify(_nodesPretrained[ix]);
					}
				} catch(e) {
					console.log('error', e);
				}
				layout.value.loading = false;
			} else {
				$openai.chatCompletion({
					n: layout.value.openai.variations,
					system: layout.value.openai.system,
					user: layout.value.openai.user,
				}).then((choices) => {
					layout.value.loadingTxt = "Import nodes";
					layout.value.choices = $openai.extractNodes({choices: choices});
					layout.value.loading = false;
					if (layout.value.choices.length) {
						for (var ix = 0; ix < layout.value.choices.length; ix++) {
							var i = 0;
							for (const _n of Object.keys(layout.value.choices[ix].nodes)) {  
								console.log('item', "text-response-" + ix + "-" + i, _n);

								layout.value.nodesToImport["text-response-" + ix + "-" + i] =  JSON.stringify(layout.value.choices[ix].nodes[i]);
								i++;
							}
						}
					}
				});
			}
		}

		const pretrained = (query) => {  
			if (layout.value.pretrained[query]) {
				return Buffer.from(layout.value.pretrained[query], 'base64').toString();
			} else {
				return false;
			}
		}

		const insertNode = () => {  
			layout.value.loading = true;
			layout.value.loadingTxt = "Generating nodes";
		}

		const importNodes = (variation) => {  
			layout.value.loading = true;
			var _nodes = [];
			for (let i = 0; i < layout.value.choices[variation].nodes.length; i++) {
				let nodeVariant = false;
				if (document.getElementById("text-response-" + variation + "-" + i)) {
					try {
						nodeVariant = JSON.parse(document.getElementById("text-response-" + variation + "-" + i).value);
					} catch(e) { 
						console.log("Error parsing node", e);
					}
					if (nodeVariant) {
						_nodes.push(nodeVariant);
					}
				}
			}
			if (_nodes.length) {
				$nodeRed.importToFlow({
					flowId: layout.value.curflow.id,
					node: _nodes
				}).then(() => { 
					layout.value.loading = false;
					loadNR('/');
				}).catch(e => console.log(e));
			} else {
				layout.value.loading = false;
			}
		}

		const importEditNodes = async (variation) => {  
			const initialFlow = await $nodeRed.getFlow({flowId: layout.value.curflow.id});
			layout.value.loading = true;
			var _nodes = [];
			for (let i = 0; i < layout.value.editChoices[variation].nodes.length; i++) {
				if (document.getElementById("text-edit-response-" + variation + "-" + i)) {
					_nodes.push(JSON.parse(document.getElementById("text-edit-response-" + variation + "-" + i).value));
				}
			}
			if (_nodes.length) {
				const finalFlow = initialFlow;
				finalFlow.nodes = _nodes;
				$nodeRed.setFlow({
					flowId: layout.value.curflow.id,
					node: finalFlow
				}).then(() => { 
					layout.value.loading = false;
					loadNR('/');
				}).catch(e => console.log(e));
			} else {
				layout.value.loading = false;
			}
		}
    
		const assistantMenu = (navId) => {
			let menuItem = document.querySelector('#' + navId);
			if (menuItem.classList.contains('selected')) {
				menuItem.classList.remove('selected');
				if (menuItem.id && document.querySelector('.' + menuItem.id) && document.querySelector('.' + menuItem.id).classList.contains('sel')) {
					document.querySelector('.' + menuItem.id).classList.remove('sel');
				}
			} else {
				menuItem.classList.add('selected');
				if (menuItem.id && document.querySelector('.' + menuItem.id)) {
					document.querySelector('.' + menuItem.id).classList.add('sel');
				}
			}
		}

		const recoveryInitFlow = () => {
			if(confirm("Are you sure you want to recover the initial flow?")){
				layout.value.loading    = true;
				layout.value.loadingTxt = "Recovering initial flow";
				if(layout.value.curflowInit){
					$nodeRed.setFlow({flowId: layout.value.curflow.id, node: layout.value.curflowInit}).then(() => { 
						layout.value.loading = false
						loadNR('/')
					}).catch(e => console.log(e))
				}
			}
		}

		const newRequest = () => {
			if(confirm("Are you sure you want to leave the current edition?")){
				layout.value.loading    = true;
				layout.value.loadingTxt = "Loading";
				layout.value.choices    = false
				setTimeout(async () => {
					layout.value.loading = false;
					await forceReload()
				}, 1000);
			}
		}

		const newEditRequest = () => {
			if(confirm("Are you sure you want to leave the current edition?")){
				layout.value.loading     = true;
				layout.value.loadingTxt  = "Loading";
				layout.value.editChoices = false
				setTimeout(async () => {
				layout.value.loading = false;
				await forceReload()
				}, 1000);
			}
		}
    
		const forceReload = async () => { loadNR('/'); }

		onMounted(async () => { 
			let host = $controller.host;
			if ($controller.zerotierEnabled) host = $controller.zerotier;

			layout.value.host = host;

			if (User.value && User.value.uid) {
				await $user.getUserData(User.value.uid).then((userData) => { 
					if (userData) store.dispatch('main/setUserData', userData);
				})
			}

			if (cash("#rpFlowAssistantTopBtn")) cash("#rpFlowAssistantTopBtn").removeClass("hidden");

			$nodeRed.setConfig({ config: { host: layout.value.host } });

			layout.value.flows = await $nodeRed.getFlows();
			if (layout.value.flows.tabs && layout.value.flows.tabs.length) {
				layout.value.flows.tabs = layout.value.flows.tabs.reverse();
			}

			let openaiDefault = await $ui.getConfig("flow");
			if (openaiDefault && Object.keys(openaiDefault).length) {
				layout.value.openai.system 		= openaiDefault.systemDefault;
				layout.value.openai.user		= openaiDefault.userDefault;
				layout.value.openai.edit.system	= openaiDefault.editSystemDefault;
				layout.value.openai.edit.user 	= openaiDefault.editUserDefault;
				layout.value.pretrained 		= openaiDefault.pretrained;
			}

			cash(".content").addClass("rpContentNodeBackground");
			cash("#topBarFlowIframe").css("display", "block");
			cash(".dashboxItem,.side-nav").addClass("hidden");
			cash(".top-bar-node-menu").removeClass("hidden");
			cash(".top-bar-node-menu-side-menu-flow").addClass("top-bar-node-menu-current");
			cash("body").css("overflow-y", "hidden");

			if (cash("#flowFrame").attr("src") == "about:blank") {
				loadNR('/');
				document.getElementById("topBarFlowIframe").style.display = 'block';
				document.getElementById("flowFrame").style.display = 'block';
			} else {
				layout.value.error = false;
				document.getElementById("topBarFlowIframe").style.display = 'block';
				document.getElementById("flowFrame").style.display = 'block';
			}
		});

		onUnmounted( async () => { 
			if(cash("#rpFlowAssistantTopBtn")) cash("#rpFlowAssistantTopBtn").addClass("hidden");
			cash("#flowFrame").attr("src", "about:blank");
			cash(".content").removeClass("rpContentNodeBackground");
			cash(".dashboxItem,.side-nav,.content").removeClass("hidden");
			cash(".top-bar-node-menu").addClass("hidden");
			cash("#topBarFlowIframe").css("display", "none");
			cash(".top-bar-node-menu-current").removeClass("top-bar-node-menu-current");
			cash("body").css("overflow-y", "scroll");
		});

		watch(() => layout.value.curflow, async () => { 
			$nodeRed.getFlow({flowId: layout.value.curflow.id}).then(data => { 
				layout.value.curflowInit = data;
			}).catch(e => console.log(e))
		});

		return{
			layout,
			modal,
			modalClose,
			modalRemove,
			forceReload,
			insertNode,
			generateNodes,
			recoveryInitFlow,
			newRequest,
			newEditRequest,
			importNodes,
			importEditNodes,
			modifyFlow,
			loadExample,
			assistantMenu,
			userData
		}
	}
});
</script>

<style>
#rpNodeCol1{ background-color: #f3f3f3;-ms-overflow-style: none; scrollbar-width: none; overflow-y: scroll; width: 75%;outline: none; }
#rpNodeCol2{ width: 25%; height: 100%; -ms-overflow-style: none; scrollbar-width: none; overflow-y: scroll;outline: none; background-color: #fff;min-height: 100vh; }

.rpNodeHidden{ display: none !important; }
.rpNodeFull{ width: 100% !important; }

#iframeTopBarContent{ max-width: 100%; min-width: 1100px; z-index: 999 !important;}
#uiFrame{-ms-overflow-style: none; scrollbar-width: none; overflow-y: scroll;}

.prompt-textarea { -ms-overflow-style: none; scrollbar-width: none; overflow-y: scroll;outline: none; font-size: 15px; border: none !important;min-height: 230px;border-radius: 0 !important;background-color: #353640;color: #fff !important;}
.prompt-textarea:focus {border-color: none;box-shadow: none !important; outline: none !important; border-radius: 0 !important;background-color: #353640;color: #fff !important;}

.prompt-textarea-response{ -ms-overflow-style: none; scrollbar-width: none; overflow-y: scroll;outline: none; font-size: 15px; border: none !important;min-height: 90px;border-radius: 0 !important;background-color: #353640;color: #fff !important;}
.prompt-textarea-response:focus {border-color: none;box-shadow: none !important; outline: none !important; border-radius: 0 !important;background-color: #15a17e;color: #fff !important;}

#rpFlowAssistantOpt{ background-color: #071a50; color: #fff; text-decoration: none; }
#rpFlowAssistantOpt .selecTab{ background-color: #3472db; }
#rpFlowAssistantOpt li{ border-color: #fff !important; }
#rpFlowAssistantOpt li .active{ border-bottom: none !important; }
.rpFlowAssistantTools {  width: 100%;font-size: 14px; }
.rpFlowAssistantTools li { background-color: #f3f3f3; width: 100%; cursor: pointer; padding: 20px 15px; margin-bottom: 1px;display: block; }
.rpFlowAssistantTools li span { font-size: 15px;}
.rpFlowAssistantTools ul li:hover { background-color: #f3f3f3 }
.rpFlowAssistantTools ul li.selected { background-color: #f3f3f3;}
.rpFlowAssistantTools ul li .arrow {display: block;float: right;width: 0;height: 0;border-top: 8px solid transparent;border-bottom: 7px solid transparent;border-left: 7px solid #ccc; margin: 2px 8px 0 0;}
.rpFlowAssistantTools ul li.selected .arrow{ transform: rotate(90deg);}
.rpFlowAssistantTools ul li.menu-content { background-color: #fff; padding: 0; font-size: 12px; display: none; cursor: default;}
.rpFlowAssistantTools ul li.menu-content.sel{ display: block; }
</style>