'use strict';

import _firebase    from './_firebase.js';
import helper       from './helper.js';

import { doc, collection, getDoc, getDocs, setDoc } from 'firebase/firestore';

const ui = {

    async get() {
        let tabs = []
        const querySnapshot = await getDocs(collection(_firebase.firestore, 'view'));
        querySnapshot.forEach((doc) => {
            let view = doc.data();
            view.id = doc.id;
            tabs.push(this.setTab(view));
        });
        return tabs;
    },
    
    getMainMenu: async function(opt = {}) { 
        const snapshot  = await getDoc(doc(_firebase.firestore, 'view', 'layout'));
        
        let item = snapshot.data(); 
        item.id = snapshot.id;
        
        if (!item.main_menu) return [];
        
        let mainMenu = JSON.parse(item.main_menu);
        
        if (opt.raw) return JSON.parse(JSON.stringify(mainMenu));
        else return mainMenu; 
    },

    getSettings: async function(viewId, param = false) {
        let viewSettings = false;
        const docSnap = await getDoc(doc(_firebase.firestore, 'view', viewId));
        if (docSnap.exists()) {
            viewSettings = docSnap.data();
            if(param) 
                viewSettings = viewSettings[param];
        }
        return viewSettings;
    },

    getConfig: async function(type) {
        const snapshot = await getDoc(doc(_firebase.firestore, 'view', type));
        let item = snapshot.data();
        if (item) {
            item.id = snapshot.id;
            return item;
        } else return {}
    },

    setTab: function(t){
        let name  = t.id; let params = {}; let icon = 'EditIcon';
        for (const [key, p] of Object.entries(t)){ if(key!='id')params[key] = p } 
        if(t.icon)icon = t.icon; 
        if(t.title)name = t.title; 
        return { id: t.id,  name: name, icon: icon, params: helper.orderParams(params) }
    },
    
    add: async function(view, msg=false){
        if(view) await setDoc(doc(_firebase.firestore, "view", view.value), { icon: "BoxIcon", title: view.value });
        if(msg) helper.Notification(msg);
    },

    update: async function(viewID, data, msg=false){
        if(viewID) await setDoc(doc(_firebase.firestore, "view", viewID), data, { merge: true });
        if(msg) helper.Notification(msg);
    },

    delete: async function(viewID, msg=false){
        if(viewID) await deleteDoc(doc(_firebase.firestore, "view", viewID));
        if(msg) helper.Notification(msg);
    },

    updateAttr: async function(doc, Attr, msg=false){ 
        await updateDoc(doc(_firebase.firestore, "view", doc), Attr);
        if(msg) helper.Notification(msg);
    },

    deleteAttr: async function(doc, Attr, msg=false){ 
        await setDoc(doc(_firebase.firestore, 'view', doc), Attr, { merge: true });
        if(msg) helper.Notification(msg);
    },
};

export default ui;