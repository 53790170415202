'use strict';

import _firebase	from './_firebase.js';
import aws 			from './aws.js';
import helper 		from './helper.js';
import image 		from './image.js';
import project 		from './project.js';

import { 
	collection, 
    doc, 
    getDocs, 
	addDoc,
    setDoc, 
    deleteDoc, 
    query, 
    where, 
    orderBy, 
    limit, 
    serverTimestamp
} from 'firebase/firestore';

const event = {

	get: async function(e = false) {
		let events = collection(_firebase.firestore, 'events');
		let listEvents = [], queryRef = query(events);

		if (e.uid) 	queryRef = query(queryRef, where("payload.uid", "==", e.uid));
		if (e.api) 	queryRef = query(queryRef, where("source", "==", "api"));
		if (e.ui) 	queryRef = query(queryRef, where("source", "==", "ui"));
		if (e.dataset) queryRef = query(queryRef, where("payload.dataset", "==", e.dataset));
		if (e.isCritical) queryRef = query(queryRef, where("isCritical", "==", true));
		if (e.type && e.type != 'all') queryRef = query(queryRef, where("name", "==", e.type));
		queryRef = query(queryRef, orderBy("createdAt", "desc"));
		if (e.limit) queryRef = query(queryRef, limit(e.limit));

		let projectUsers = await project.getUsers();

		await getDocs(queryRef)
			.then(snapshot => {
				snapshot.forEach(async doc => {
					let item = doc.data();
					item.id = doc.id;

					if (item.name) item.nameSplit = item.name.split(".");
					if (item.name) item.type = this.getEventTypes(item.name);
					if (item.createdAt) item.created = helper.getTimestampDate(item.createdAt.toDate(), 'full');
					if (item.createdAt) item.date = helper.getTimestampDate(item.createdAt.toDate(), 'date');
					if (item.payload && item.payload.uid && projectUsers.users[item.payload.uid]) item.user = projectUsers.users[item.payload.uid];
					
					listEvents.push(item);
				});
			});

		if (e.preview && listEvents.length) {
			for (let i = 0; i < listEvents.length; i++) {
				if (listEvents[i] && listEvents[i].payload && listEvents[i].payload.imageId) {
					let ePrev = await image.previewB64(listEvents[i].payload.imageId);
					if (ePrev.b64) { listEvents[i].preview = []; listEvents[i].preview.push({ name: ePrev.image, src: ePrev.b64 }); }
				}
				if (listEvents[i] && listEvents[i].payload && listEvents[i].payload.uploadedFiles && listEvents[i].payload.uploadedFiles.files) {
					let awsFiles = listEvents[i].payload.uploadedFiles.files.slice(0, 3);
					for (const file of awsFiles) {
						let ePrev = await aws.getObject(file, "64");
						if (ePrev.render) {
							if (!listEvents[i].preview) listEvents[i].preview = [];
							listEvents[i].preview.push({ name: file.toString().split("/").pop(), src: ePrev.render });
						}
					}
				}
			}
		}

		if (e.byDate && listEvents.length) {
			let byDate = {};
			for (let i = 0; i < listEvents.length; i++) {
				console.log(listEvents[i].date);
				if (!byDate[listEvents[i].date]) byDate[listEvents[i].date] = { date: listEvents[i].date, events: [] };
				byDate[listEvents[i].date].events.push(listEvents[i]);
			}
			return byDate;
		}

		if (e.last && listEvents.length) return listEvents[0];

		return listEvents;
	},

	saveEvent: async function(eName, payload, error = false) {
		let colRef = collection(_firebase.firestore, 'events');
		let docData = { 
			name: eName, 
			source: 'ui', 
			payload: payload, 
			isCritical: error, 
			createdAt: serverTimestamp()
		};
		await addDoc(colRef, docData);
	},

	deleteEvent: async function(eventId) {
		let docRef = doc(_firebase.firestore, 'events', eventId);
		await deleteDoc(docRef);
	},

	updateEvent: async function(eventId, payload) {
		let upd = { "updatedAt": serverTimestamp() };
		if (payload.status)	upd["status"] = payload.status;
		if (payload.discard) upd["discard"] = Boolean(payload.discard);
		let docRef = doc(_firebase.firestore, 'events', eventId.toString());

		await setDoc(docRef, upd, { merge: true });
	},

	getLastOperation: async function() {
		let lastOperation = '';
		let colRef = collection(_firebase.firestore, 'events');
		let events = query(colRef, where("payload.operation", "!=", ""), orderBy("payload.operation", "asc"), orderBy("createdAt", "desc"), limit(1));
		
		await getDocs(events).then(async snapshot => {
			snapshot.forEach(async doc => {
				let item = doc.data();
				lastOperation = item.payload.operation;
			});
		});

		return lastOperation;
	},

	getLastPipeline: async function() {
		let lastPipeline = '';
		let colRef = collection(_firebase.firestore, 'events');
		let events = query(colRef, where("payload.operationID", "!=", ""), orderBy("payload.operationID", "asc"), orderBy("createdAt", "desc"), limit(1));
		
		await getDocs(events).then(async snapshot => {
			snapshot.forEach(async doc => {
				let item = doc.data();
				lastPipeline = item.payload.operationID;
			});
		});

		return lastPipeline;
	},

	discard: async function(eventId) {
		let resp = { status: "error", error: false };
		await this.updateEvent(eventId, { discard: true }).then(() => { resp.status = "success" });
		return resp;
	},

	getEventTypes: function(type = false) {
		let types = [
			{ id: 'all' },
			{ id: 'user.login', name: 'Login' },
			{ id: 'user.logout', name: 'Logout' },
			{ id: 'user.saveprofile', name: 'User profile update' },
			{ id: 'user.sendverifyemail', name: 'Send profile verification' },
			{ id: 'user.updatepass', name: 'Password update' },
			{ id: 'dataset.upload.zip', name: 'Images uploaded by zip file', icon: 'UploadCloudIcon' },
			{ id: 'dataset.upload.image', name: 'Uploaded a single image', icon: 'UploadCloudIcon' },
			{ id: 'dataset.tag.update', name: 'Dataset tag updated', icon: 'TagIcon' },
			{ id: 'dataset.create', name: 'Dataset creation', icon: "PlusCircleIcon" },
			{ id: 'dataset.update', name: 'Dataset updated', icon: "DatabaseIcon" },
			{ id: 'dataset.import.vertex', name: 'Importing the dataset to Rosepetal AI Storage', icon: 'CornerLeftUpIcon' },
			{ id: 'dataset.download', name: 'Dataset downloaded in Zip format', icon: 'DownloadIcon' },
			{ id: 'dataset.upload.s3', name: 'Importing the dataset to Rosepetal AI Storage', icon: 'CornerLeftUpIcon' },
			{ id: 'dataset.create.vertex', name: 'Dataset creation in Rosepetal AI Platform', icon: 'GitPullRequestIcon' },
			{ id: 'dataset.datadivision.random', name: 'Data division random assignment', icon: 'PieChartIcon' },
			{ id: 'api.dataset.generateCsv.response', name: 'Generate Csv for upload', icon: 'FileTextIcon' },
		]
		if (type) return this.getEventName(types, type);
		return types;
	},

	getEventName: function(e, t) {
		e.forEach(type => { if (type.id == t) t = type });
		return t;
	}
}

export default event;