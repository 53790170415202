'use strict';

import { Configuration, OpenAIApi } from "openai";
import extract from 'extract-json-from-string';

const configuration = new Configuration({
    apiKey: "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"
});

const openai = new OpenAIApi(configuration);

const openaiWrapper = {

    chatWithGPT: async function(chat) {
        const messages = [
            { role: 'system', content: chat.system },
            { role: 'user', content: chat.user },
        ];

        const response = await openai.createChatCompletion({
            model: 'gpt-4',
            messages: messages,
        });
        return response.data.choices[0].message.content
    },

    chatCompletion: function({ system, user, _messages, temperature, n }) {
        return new Promise((resolve, reject) => {
            const messages = [
                { role: 'system', content: system },
                { role: 'user', content: user },
            ];
            if (_messages) init_messages.concat(_messages)
            openai.createChatCompletion({
                model: 'gpt-4',//'gpt-4',//'gpt-3.5-turbo',
                temperature: temperature || 1,
                n: n || 1,
                messages: messages
                //max_tokens: 3000,
            }).then(response => resolve(response.data.choices))
                .catch(error => {
                    console.log('Error chatCompletion:', error)
                    reject(error)
                })
        })
    },

    extractNodes: function({ choices }) {
        const _choices = []
        for (const choice of choices) {
            let node = ""
            try {
                node = JSON.parse(choice.message.content)
            } catch (e) {
                node = extract(choice.message.content)[0]
            }
            choice.nodes = node
            if (node) _choices.push(choice)
        }
        return _choices
    }
}

export default openaiWrapper;