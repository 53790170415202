'use strict';

import { getStorage, ref }                          from 'firebase/storage';
import { getDatabase }                              from 'firebase/database';
import { getFirestore, doc, collection }            from 'firebase/firestore';
import { initializeApp, getApp, getApps }           from 'firebase/app';
import { signInWithEmailAndPassword, getAuth }      from 'firebase/auth';
import { getFunctions, connectFunctionsEmulator }   from 'firebase/functions';

const _firebase = {

	config: {},
    
    firebase:   null,
    auth:       null,
    firestore:  null,
    database:   null,
    storage:    null,
    functions:  null,

	setHost: function(config = false) {
        console.log("Hostname:", location.hostname);
        if (config && location.hostname.includes(config.localhost)) {
            const functions = getFunctions(this.firebase, config.firebaseRegion);
            connectFunctionsEmulator(functions, '127.0.0.1', 5001);
        }
    },

	initializeApp: function(config = false) {
        this.config = config;

        if (!getApps().length) {
            initializeApp(this.config);
        }
        
        this.firebase   = getApp();
        this.auth       = getAuth();
        this.firestore  = getFirestore();
        this.database   = getDatabase();
        this.storage    = getStorage();
        this.functions  = getFunctions(this.firebase, 'europe-west1');

        getApp().options.region = 'europe-west1';
        const projectId = getApp().options.projectId;

        return {
            status: true,
            projectId: projectId,
            msg: 'Rosepetal Model is ready with ' + projectId + ' project'
        };
	},

	signInWithEmailAndPassword: async function(email, password) {
        const auth = this.auth;
        let response = { status: false, message: '' };

        await signInWithEmailAndPassword(auth, email, password)
            .then(() => {
                console.log(email + ' logged in rosepetal-model');
                response.status = true;
                response.message = email + ' logged in successfully';
            })
            .catch((error) => {
                response.status = false;
                response.message = 'error login:' + error;
            });

        return response;
    },

	getConfig: function() {
        let config = getApps().length ? getApps()[0].options : { error: 'No firebase config found' };
        if (config.databaseURL) { config.region = config.databaseURL.split('.')[1]; }
        return config;
    },

	getApiHost: function() {
        let url = { host: "", name: "online" };
        let firebaseFunctions = getFunctions(this.firebase);

        if (firebaseFunctions.emulatorOrigin) {
            url.name = "localhost";
            url.host = firebaseFunctions.emulatorOrigin;
            url.host = firebaseFunctions.app && firebaseFunctions.app.options && firebaseFunctions.app.options.projectId ? url.host + '/' + firebaseFunctions.app.options.projectId : url.host;
            url.host = firebaseFunctions.region ? url.host + '/' + firebaseFunctions.region : url.host;
            url.host += "/";
        } else {
            url.host = "https://";
            url.host = this.getConfig().region ? url.host + '' + this.getConfig().region : url.host;
            url.host = firebaseFunctions.app && firebaseFunctions.app.options && firebaseFunctions.app.options.projectId ? url.host + '-' + firebaseFunctions.app.options.projectId : url.host;
            url.host += ".cloudfunctions.net/";
        }

        return url;
    },

    getDoc: async function(path) {
        const docRef = doc(this.firestore, path);
        return docRef;
    },

    getCollection: function(path) {
        const collectionRef = collection(this.firestore, path);
        return collectionRef;
    },

    getStorage: function(configPath, childPath) {
        const storageRef = ref(this.storage, configPath);
        const uploadRef  = ref(storageRef, childPath);
        return uploadRef;
    },

    getFirestore: function() {
        return this.firestore;
    },

    updateDoc: async function(datasetID) {
        let docRef = doc(_firebase.firestore, 'dataset', datasetID);
        let data = { "updatedAt": serverTimestamp() };
        await setDoc(docRef, data, { merge: true });
    }
};

export default _firebase;