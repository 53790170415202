'use strict';

import axios        from 'axios';

const controller = { 

    host:               false,
    zerotier:           false,
    zerotierEnabled:    false,

    setHost: function(host)         { this.host = host; },
    setZerotier: function(zerotier) { this.zerotier = zerotier; },

    getStatus: async function(externalHost = false) {
        let resp    = { status: "error", error: false, label: 'offline' }
        let host    = externalHost ? externalHost : this.host;
        resp.host   = host + '/nodered/flow/global';

        console.log("Host:", resp.host);

        try {
            return axios.get(resp.host, { timeout: 3000 }, { headers: {} })
                .then(response => { 
                    if(response.status && response.status==200){ 
                        resp.status = "success";  
                        resp.label = "online"; 
                        return resp; 
                    } else {
                        resp.error = response.statusText;
                        return resp; 
                    }
                }).catch(error => {
                    resp.error = error;
                    return resp; 
                });
        } catch (error) {
            resp.error = error;
            return resp;
        }
    },

    getNavMenu: function(opt = false) {
        let resp  = { status: "success", error: false};

        resp.menu = {
            information: [ 
                            { name: "Dashboard",        load: "AJ",     url: "/view/dashboard" },
                            { name: "Metrics",          load: "CP",     url: "/metrics/index.html" },
                            { name: "Logs",             load: "CP",     url: "/system/logs.html" },
                            { name: "Hardware",         load: "CP",     url: "/system/hwinfo.html" },
                        ],
            tools:       [ 
                            { name: "IDE",              url: ":4040" },
                            { name: "File Manager",     load: "CP",     url: "/navigator/index.html" },
                            { name: "Terminal",         load: "CP",     url: "/system/terminal.html" },
                            { name: "Power",            load: "AJ",     url: "/view/power" },
                        ],
            services:    [ 
                            { name: "Services",         load: "CP",     url: "/system/services.html" },
                            { name: "Containers",       load: "CP",     url: "/podman/index.html" },
                            { name: "Virtual",          load: "CP",     url: "/machines/index.html" },
                            { name: "Updates",          load: "CP",     url: "/updates/index.html" },
                            { name: "APT Packages",     load: "AJ",     url: "/view/packages/apt" },
                            { name: "PIP Packages",     load: "AJ",     url: "/view/packages/pip" },
                        ],
            settings:    [ 
                            { name: "System",           load: "CP",     url: "/system/index.html" },
                            { name: "Storage",          load: "CP",     url: "/storage/index.html" },
                            { name: "Network",          load: "CP",     url: "/network/index.html" },
                            { name: "DHCP status",      load: "DH",     url: "/" },
                            { name: "DHCP leases",      load: "DH",     url: "/dhcp_leases" },
                            { name: "DHCP config",      load: "DH",     url: "/dhcp_config" },
                            { name: "DHCP start/stop",  load: "DH",     url: "/dhcp_start_stop_restart" },
                            { name: "Users",            load: "CP",     url: "/users/index.html" },
                            { name: "Datetime",         load: "AJ",     url: "/view/datetime" },
                        ],
        };
        
        if (opt.pageList) {
            let pages = [];
            for (const nav of Object.keys(resp.menu)) { 
                for (var n = 0; n < resp.menu[nav].length; n++) {
                    pages.push(resp.menu[nav][n]);
                }
            }
            resp.menu = pages;
        }

        return resp;
    },
}

export default controller;