<template>
	<div class="dropzoneBoxContent">
		
		<div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-0 uploadFileFull" v-if="layout.loading">
			<img class="w-1/2" :src="require(`@/assets/images/rosepetal/icon/loading.gif`)" />
		</div>

		<div class="w-full grid grid-cols-12" v-else>

			<!-- URL Input and Submit Button -->
			<!-- <form @submit.prevent="submitUrl" class="col-span-12 grid grid-cols-12 gap-3 pb-3">
				<input v-model="dropzoneUrl" type="text" placeholder="Enter URL here" class="col-span-10 w-full p-2 rounded border border-gray-300"/>
				<button type="submit" class="col-span-2 w-full p-2 rounded border border-gray-300 bg-green-200">Add</button>
			</form> -->

			<!-- DROPZONE -->
			<div v-if="showDropzone" v-bind="getRootProps()" class="text-xs cursor-pointer rounded" id="uploadComponent" :class="!layout.file || layout.error ? 'col-span-12' : 'col-span-4'">
				<input v-bind="getInputProps()" />
				<h2 class="text-lg font-medium truncate mt-5">{{$t('Upload files')}}</h2>
				<div class="mt-2 text-xs font-medium text-gray-800" v-if="isDragActive">{{$t('Drop the files here...')}}.</div>
				<div class="mt-2 text-xs font-medium text-gray-800" v-else>{{$t('Drag and drop files here or click to upload')}}.</div>
				<UploadCloudIcon class="w-14 h-14 mt-1 mb-5 text-gray-400" />
			</div>

			<!-- INFO -->
			<div v-if="!layout.error" class="grid grid-cols-12" :class="!showDropzone || !layout.file ? 'col-span-12' : 'col-span-8'">
				<div class="col-span-12 ml-2">

					<!-- ZIP UPLOAD EXPLANATION -->
					<div class="pt-3 pl-3 text-gray-600" v-if="!layout.file && layout.type=='zip'">
						<div class="grid grid-cols-12">

							<div class="col-span-2">
								<img class="w-20 h-auto mt-3 ml-5" :src="require(`@/assets/images/rosepetal/icon/zip.png`)" />
							</div>

							<div class="col-span-10">
								
								<!-- MULTICLASS / MULTILABEL -->
								<div v-if="layout.datasetData.type=='MULTICLASS' || layout.datasetData.type=='MULTILABEL'" class="text-xs py-3 w-full">
									<div class="font-medium text-sm mb-3 text-gray-700">{{$t('How to add multiple images to dataset using a Zip File')}}</div>
									<div>
										1. {{$t('Create a directory for each class tag Identifier')}}. 
										<div class="pl-3 mt-1">{{$t("Use directory name '0' for images you don't want to classify")}}.</div>   
									</div> 
									<div class="mt-2">2. {{$t('Insert the images in each directory according to their class')}}.</div> 
									<div v-if="layout.datasetTags" class="ml-3 mt-1">
										<span >{{$t('Examples')}}: </span>
										<span v-if="layout.datasetTags && Object.keys(layout.datasetTags).length>1"><span class="pr-1 font-medium" v-for="(v,k,i) in layout.datasetTags" :key="i"><span v-if="(i<4)">/{{v.id}}/image{{(i+1)}}.jpg,</span></span>...</span>
										<span v-else class="pr-1 font-medium">OK/image1.jpg, NOK/image1.jpg</span>
									</div> 
									<div class="mt-2">3. {{$t('Compress in Zip format and drag the file to the top uploading area')}}.</div>
								</div>

								<!-- IMGOBJECTDETECTION -->
								<div v-if="layout.datasetData.type=='imageObjectDetection'" class="text-xs py-3 w-full">
									<div class="font-medium text-sm mb-3">{{$t('How to add multiple images to object detection dataset using a Zip File')}}</div>
									<div>1. {{$t('Create a directory and insert all the images in the root')}}.</div> 
									<div>2. {{$t('Compress in Zip format')}}.</div>
									<div>3. {{$t('Drag the file to the top uploading area')}}.</div>
								</div>

							</div>

						</div>
					</div>

					<!-- FILE UPLOAD -->
					<div class="text-sm" v-if="layout.file">

						<!-- UPLOADING FILE -->
						<div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-0 pt-0 uploadingFull" v-if="layout.uploading">
							<div class="w-full text-center pt-5" style="background-color: #f1f2f1">
								<div class="text-lg" style="font-size: 26px !important">{{layout.debug ? layout.debug : $t('Starting the upload, please wait') + "..."}}</div>
								<div class="text-xs mb-3 mt-3 text-center w-full" v-if="layout.datasetImageCount">
									<div class="text-3xl mb-1">{{layout.datasetImageCount}}</div>
									<div>{{$t('Total dataset images')}}</div>
								</div>
								<span class="text-base text-red-600" style="line-height: 60px; font-size: 20px">{{$t("Please do not close your browser")}}</span>
							</div>
							<div class="w-1/2 text-center" :style="`background-image: url(${require(`@/assets/images/rosepetal/icon/loading.gif`)}); background-position: top center; background-size: contain; background-repeat: no-repeat; height: 60%`"></div>
						</div>

						<div v-else>

							<!-- FILE UPLOADED COMPLETE -->
							<div v-if="layout.success" class="text-center">
								<div class="text-center  w-full">
									<img class="w-20 h-auto d-block mx-auto rounded" :src="require(`@/assets/images/rosepetal/icon/ok.png`)" />
								</div>
								<div class="items-center justify-center"></div>
								<div class="text-base mt-3 font-medium">{{$t('Upload Successful')}}</div>
								<div class="mt-2">{{$t('The images have been correctly incorporated into the dataset')}}</div>
								<div class="tex-xs mb-3 text-center w-full mt-2" v-if="layout.datasetImageCount">
									<div class="text-3xl mb-1">{{layout.datasetImageCount}}</div>
									<div>{{$t('Total dataset images')}}</div>
								</div>
								<div class="mb-10 mt-1 text-normal text-xs">{{$t('In a few moments you will have them available in data labeling')}}</div>
								<span class="px-5 py-3 bg-blue-500 cursor-pointer w-full font-normal text-white hover:bg-theme-10 mr-2" @click="resetDropzone()">{{$t('New upload')}}</span> 
								<div id="refreshMosaicBtn" @click="refreshMosaic()"></div>
							</div>

							<!-- FILE INFORMATION -->
							<div v-else>
								<div class="grid grid-cols-12 gap-3">
									<div class="intro-y col-span-12 box shadow-none">

										<!-- LAST FILE ADDED -->
										<div v-if="!layout.error" class="px-5 py-4 text-sm">
											<span><FileIcon class="w-4 h-4" style="vertical-align: top;" /> {{$t('Last file added')}}: </span>
											<div class="flex items-center pt-2 pb-1">
												<div class="w-10 h-10 flex-none image-fit rounded-full border flex items-center justify-center text-xs">{{ layout.file.name.toString().toUpperCase().split('.').pop() }}</div>
												<div class="ml-3 mr-auto">
													<span  class="font-medium">
														{{ layout.file.name.toString().toLowerCase().slice(0, 40) }} <span v-if="(layout.file.name.toString().toLowerCase().split('.')[0].length > 40)">...</span>
													</span>
													<div class="flex text-gray-600 truncate text-xs mt-0.5">
														<span v-if="layout.file.type"><span>{{$t('Type')}}: </span><span class="text-green-500">{{layout.file.type}}</span></span>
														<span class="mx-1">•</span>  <span v-if="layout.file.size"><span>{{$t('Size')}}: </span><span class="text-green-500">{{layout.file.size}}</span></span>
													</div>
												</div>
											</div>
										</div>
										
										<!-- VALID FILES -->
										<div v-if="(Object.keys(layout.file?.files).length && !layout.success && !layout.uploading)" class="px-5 pt-4 pb-1 text-sm border-t border-gray-200">
											<span><ImageIcon class="w-4 h-4" style="vertical-align: top;" /> {{$t('Valid files')}}: </span>
											<div class="mt-1 ml-4">
												<span v-for="(f,k) in layout.file?.files" :key="k" class="pr-3">
													<span class="text-green-500">.{{(k)}} ({{layout.file.files[k].length}})</span> 
												</span>
											</div>
										</div>
										
										<!-- VALID DIRECTORIES -->
										<div v-if="(Object.keys(layout.file?.dir).length && !layout.success && !layout.uploading)" class="px-5 pt-1 pb-4 text-sm">
											<span><FolderIcon class="w-4 h-4" style="vertical-align: top;" /> {{$t('Tags directories')}}: </span>
											<div class="mt-2 ml-4">
												<span v-for="(d,k) in layout.file?.dir" :key="k" class="pr-2">
													<span class="text-green-500">{{(k)}} ({{(d.length)}}) <span v-if="k=='0'" class="text-green-500">*</span></span> 
												</span>
												<div v-if="Object.keys(layout.file?.dir).includes('0')" class="mt-2 __uploaddzmin font-italic font-normal ">* {{$t("Use directory name '0' for unclassified images")}}</div>
											</div>
										</div>

										<!-- ANNOTATIONS -->
										<div v-if="(Object.keys(layout.file?.annotations).length && !layout.success && !layout.uploading)" class="px-5 pt-1 pb-4 text-sm">
											<span><FileTextIcon class="w-4 h-4" style="vertical-align: top;" /> {{$t('Annotations')}}: </span>
											<div class="mt-2 ml-4">
												<span v-for="(d,k) in layout.file?.annotations" :key="k" class="pr-2">
													<span v-if="d.length > 0" class="text-green-500">.{{k}} ({{d.length}}) <span v-if="k=='0'" class="text-green-500">*</span></span>
												</span>
											</div>
										</div>

										<!-- INVALID FILES -->
										<div v-if="(Object.keys(layout.file?.invalidFiles).length && !layout.uploading)" class="px-5 pt-4 pb-1 text-sm text-red-400 border-t border-gray-200">
											<span><AlertTriangleIcon class="w-4 h-4" style="vertical-align: top;" /> {{$t('Invalid files')}}:</span>
											<div class="mt-1 ml-4">
												<span v-for="(d,k) in layout.file?.invalidFiles" :key="k" class="pr-3">
													<span>.{{(k)}} ({{(d.length)}})</span> 
												</span>
											</div>
										</div>

										<!-- INVALID DIRECTORIES -->
										<div v-if="(Object.keys(layout.file?.invalidDir).length && !layout.uploading)" class="px-5 pt-1 pb-4 text-sm text-red-400">
											<span><FolderIcon class="w-4 h-4" style="vertical-align: top;" /> {{$t('Invalid directories')}}:</span>
											<div class="mt-1 ml-4">
												<span v-for="(k) in layout.file?.invalidDir" :key="k" class="pr-3">
													<span>{{k}}</span> 
												</span>
											</div>
										</div>

										<!-- IMAGE PREVIEW -->
										<!-- <div class="p-5" v-if="layout.type=='image' && layout.uploadThumb">
											<div class="h-40 xxl:h-56 image-fit">
												<img :src="layout.uploadThumb.image" class="d-block mx-auto w-80 h-auto rounded-md" />
											</div>
											<div v-if="layout.uploadThumb?.size" class="mt-3">
												<Maximize2Icon class="w-6 h-6 pr-2" />
												<span v-if="layout.uploadThumb.size?.width">{{$t('Width')}}: <span class="font-medium">{{layout.uploadThumb.size?.width}}px</span></span> /
												<span v-if="layout.uploadThumb.size?.height">{{$t('Height')}}: <span class="font-medium">{{layout.uploadThumb.size?.height}}px</span></span>
											</div>
										</div> -->

										<!-- IMAGE COMMENT -->
										<!-- <div class="px-5 pt-3 pb-5 border-t border-gray-200" v-if="layout.type=='image' && !layout.error">
											<div class="w-full flex items-center mt-3">
												<div class="flex-1 relative text-gray-700">
													<input type="text" class="form-control form-control-rounded border-transparent bg-gray-200 pr-10 placeholder-theme-8" v-model="layout.comments" placeholder="Add an comment..."/>
													<SmileIcon class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"/>
												</div>
											</div>
										</div> -->

									</div>
								</div>
							</div>

						</div>

					</div>

				</div>
			</div>

			<!-- WARNINGS -->
			<div v-if="(layout.warnings && Object.keys(layout.warnings).length)" class="col-span-12 text-xs py-3">
				<div class="text-xs mb-1 w-full px-1 py-2 text-center border text-gray-900 rounded font-normal">
					<div class="w-full text-truncate font-italic" v-for="k in Object.keys(layout.warnings)" :key="k">{{k}}</div>  
				</div>
			</div>

			<!-- ERROR -->
			<div v-if="layout.error" class="col-span-12 text-xs mb-1 flex py-3 border text-red-400 rounded font-normal mt-3 pl-5">
				<span class="w-full text-truncate font-italic pt-2">{{layout.error}}.</span>
				<span class="py-2 px-6 rounded-full text-xs font-normal align-top text-center cursor-pointer float-right ml-auto bg-gray-700 text-white mr-2" v-if="(layout.file)" @click="resetDropzone()">{{ $t('Back') }}</span>
			</div>

			<!-- CANCEL / UPLOAD BUTTONS -->
			<div class="col-span-12 flex items-center px-5 py-4 " v-if="!layout.error && layout.file">
				<Tippy tag="a" class="intro-x w-20 h-8 flex items-center justify-center rounded-full bg-theme-31 text-gray-900 ml-auto cursor-pointer py-5" v-if="(layout.file)" @click="resetDropzone()"
					content="Cancel upload and back">{{$t('Cancel')}}</Tippy>
				<Tippy tag="a" class="intro-x w-32 h-8 flex items-center justify-center rounded-full bg-theme-17 text-white ml-2 cursor-pointer py-5" v-if="(layout.file && !layout.error)" @click="uploadFile()"
					content="Confirm and upload image">{{$t('Upload')}}</Tippy>
			</div>

		</div>

	</div>
</template>

<script>
import { defineComponent, onMounted, ref, watch } from "vue";
import { useStore } 			from "@/store";
import { useDropzone }			from "vue3-dropzone";
import JSZip        			from "jszip";

import * as rosepetalModel  	from "rosepetal-model";
import config               	from '@/etc/rosepetal.json';

import { uploadBytesResumable, uploadBytes }	from "firebase/storage";
import { FileIcon, FileTextIcon } 			from "@zhuowenli/vue-feather-icons";

let $_firebase	= rosepetalModel.default._firebase;
let $dataset    = rosepetalModel.default.dataset;
let $helper     = rosepetalModel.default.helper;
let $image 		= rosepetalModel.default.image;
let $event      = rosepetalModel.default.event;

export default defineComponent({
	props: {
		datasetId: {
			type: String,
			required: true
		}
	},

	setup(props) {
		const layout		= ref({ dataset: false });
		const showDropzone	= ref(true);
		const dropzoneUrl	= ref("");

		const loadDataset = async () => {
			if (layout.value.dataset) {
				layout.value.datasetData = await $dataset.get(layout.value.dataset, { tagsCounter: true });
				layout.value.datasetTags = layout.value.datasetData['tags'];
			}
		}

		const initDropzone = async () => {
			showDropzone.value = true;

			layout.value = { 
				dataset:        "", 
				datasets:       await $dataset.list(), 
				datasetData:    false, 
				datasetTags:    false,
				loading:        false,
				uploading:      false, 
				success:        false, 
				file:          	false, 
				files:		  	[],
				error:          false,
				data:		 	new JSZip(),
				warnings:       {},
				comments:       "",
				type:           "zip", 
				zip:            { bytesTransferred: 0, totalBytes: 0, uploadStatus: false, percentage: 0 },
				acceptedFiles:  ['jpg', 'jpeg', 'gif', 'png', 'bmp', 'webp', 'raw', 'ico', 'tiff', 'csv', 'txt']
			}

			if (!layout.value.dataset && props.datasetId) { 
				layout.value.dataset = props.datasetId;
				await loadDataset();
			}
		}

		const resetDropzone = async () => {
			showDropzone.value 		= true;
			layout.value.loading  	= false;
			layout.value.uploading	= false, 
			layout.value.success  	= false;
			layout.value.file		= false;
			layout.value.files		= [];
			layout.value.error    	= false;
			layout.value.warnings 	= {};
			layout.value.comments	= "";
			layout.value.type     	= layout.value.type ? layout.value.type : "zip";
			layout.value.zip      	= { bytesTransferred: 0, totalBytes: 0, uploadStatus: false, percentage: 0 };
		}

		const onDrop = async (acceptedFiles) => {
			layout.value.loading  = layout.value.uploaded  = true;
			for (const file of acceptedFiles)
				await processFile(file);
			layout.value.loading = false;
		}

		const submitUrl = async () => {
			if (dropzoneUrl.value) {
				const response = await fetch(dropzoneUrl.value);
				if (response.ok) {
					const blob = await response.blob();
					const zip = new JSZip();
					zip.file("myFile", blob);
					const content = await zip.generateAsync({type:"blob"});
				} else {
					layout.value.error = "The URL provided is not valid";
				}

				dropzoneUrl.value = "";
			}
		}

		const processFile = async (file) => {
			layout.value.file = { 
				name:         file.name, 
				type:         file.type, 
				size:         $helper.formatBytes(file.size), 
				dir:          layout.value.file && layout.value.file.dir 			? layout.value.file.dir 			: {},
				invalidDir:   layout.value.file && layout.value.file.invalidDir 	? layout.value.file.invalidDir 		: [],
				files:        layout.value.file && layout.value.file.files 			? layout.value.file.files 			: {},
				invalidFiles: layout.value.file && layout.value.file.invalidFiles 	? layout.value.file.invalidFiles 	: {},
				annotations:  layout.value.file && layout.value.file.annotations 	? layout.value.file.annotations 	: { csv: [], txt: [] },
			}

			/* ZIP */
			if (file.type === 'application/zip' || file.type === 'application/x-zip-compressed') {
				let zipData		= await new Promise((resolve, reject) => { JSZip.loadAsync(file).then(function(content) { resolve(content) }).catch(() => { reject });  });
				let empty       = false;

				if (zipData.files) {
					Object.keys(zipData.files).forEach(function(filePath) {
						let fileData		= zipData.files[filePath];
						let fileName		= filePath.toString().split('/').pop();
						let extension		= filePath.toString().split('.').pop();
						let isDir			= fileData.dir;
						
						let excludeFile 	= false;
						let fileProcessed	= false;

						if (!isDir) {
							let file = { name: filePath, extension: extension, file: fileData };

							if (!layout.value.acceptedFiles.includes(extension))
								excludeFile = true;
							
							if (layout.value.files.includes(fileName)) {
								excludeFile 	= true;
								fileProcessed	= true;
							}

							if (filePath.includes("__MACOSX") || filePath.includes(".DS_Store") || filePath.includes(".db"))
								excludeFile = true;

							if (!excludeFile) {
								let path = filePath.toString().split("/");
								let tag = "0";

								if (path.length >= 2 && layout.value.datasetData.type !== 'imageObjectDetection') {
									tag = layout.value.datasetData.type === 'MULTILABEL' && path[path.length - 2] === "OK" ? "OK" 
										: layout.value.datasetData.type === 'MULTICLASS' ? path[path.length - 2] 
										: "0";
								}

								if (extension === 'csv' || extension === 'txt') {
									layout.value.file.annotations[extension].push(file);
								} else {
									if (!layout.value.file.dir[tag])
										layout.value.file.dir[tag] = [];

									layout.value.file.dir[tag].push(file);

									if (!layout.value.file.files[extension]) 
										layout.value.file.files[file.extension] = [];
										layout.value.file.files[extension].push(file);
								}
								
								layout.value.files.push(fileName);
							}
							
							else if (!fileProcessed) {
								if (!layout.value.file.invalidFiles[extension])
									layout.value.file.invalidFiles[extension] = [];

								layout.value.file.invalidFiles[extension].push(file);
								layout.value.files.push(fileName);
							}
						} else {
							if (filePath.includes("__MACOSX") || filePath.includes(".DS_Store") || filePath.includes(".db"))
								excludeFile = true;

							if (excludeFile && filePath && !layout.value.file.invalidDir.includes(filePath))
								layout.value.file.invalidDir.push("/" + filePath);
						}
					});

					if (!Object.keys(layout.value.file.files).length) { empty = true; }

				} else { empty = true; }
				
				if (Object.keys(layout.value.file.invalidFiles).length || Object.keys(layout.value.file.invalidDir).length)
					layout.value.warnings["Invalid files or directories will not be included in the dataset"] = true;

				if (layout.value.datasetData.type == 'imageObjectDetection' && Object.keys(layout.value.file.dir).length)
					layout.value.warnings["Object detection dataset do not require tag directories"] = true;
				
				if (!layout.value.error && empty)
					layout.value.error = "The uploaded zip file is empty or does not have valid images";
			}

			/* SINGLE FILE */
			else {
				const extension 	= file.name.toString().split('.').pop();

				let excludeFile 	= false;
				let excludeDir		= false;
				let fileProcessed	= false;

				if (!layout.value.acceptedFiles.includes(extension))
					excludeFile 	= true;

				if (layout.value.files.includes(file.name)) {
					excludeFile 	= true;
					fileProcessed	= true;
				}

				if (file.path.includes("__MACOSX") || file.path.includes(".DS_Store")) {
					excludeFile 	= true;
					excludeDir		= true;
				}

				if (!excludeFile) {
					let path = file.path.toString().split("/");
					let tag = "0";

					if (path.length >= 2) {
						tag = path[path.length - 2] !== "OK" && layout.value.datasetData.type === 'MULTILABEL' ? "0" : path[path.length - 2];
					} else {
						tag = layout.value.datasetData.type === 'MULTILABEL' ? "OK" : "0";
					}

					if (!layout.value.file.dir[tag])
						layout.value.file.dir[tag] = [];

					let reader = new FileReader();
					reader.onload = function(e) {
						let fileObj = {
							name: file.name,
							extension: file.name.split('.').pop(),
							file: new Blob([e.target.result], { type: file.type })
						};
						layout.value.file.dir[tag].push(fileObj);
					}
					reader.readAsArrayBuffer(file);

					if (!layout.value.file.files[extension])
						layout.value.file.files[extension] = [];

					layout.value.file.files[extension].push(file);
					layout.value.files.push(file.name);
				}

				else if (!fileProcessed) {
					if (!layout.value.file.invalidFiles[extension])
						layout.value.file.invalidFiles[extension] = [];

					layout.value.file.invalidFiles[extension].push(file);
					layout.value.files.push(file.name);

					let pathParts = file.path.toString().split("/");
					pathParts.pop();
					let filePath = pathParts.join("/");

					if (excludeDir && !layout.value.file.invalidDir.includes(filePath))
						layout.value.file.invalidDir.push(filePath);
				}

				if (Object.keys(layout.value.file.invalidFiles).length || Object.keys(layout.value.file.invalidDir).length)
					layout.value.warnings["Invalid files or directories will not be included in the dataset"] = true;
			}

			if (!Object.keys(layout.value.file.files).length) {
				layout.value.error = "The uploaded zip is empty or there are no valid files";
			} else { layout.value.error = false; }
		}

		const uploadFile = async () => { 
			showDropzone.value 		= false;
			layout.value.uploading	= true;
			layout.value.debug 		= "";

			let countFiles 			= 0;
			let filesZip 			= new JSZip();
			let annotationsZip		= new JSZip();

			const isMultiClassOrLabel = layout.value.datasetData.type == 'MULTICLASS' || layout.value.datasetData.type == 'MULTILABEL';

			if (Object.keys(layout.value.file.dir).length) {
				for (let key in layout.value.file.annotations) {
					if (layout.value.file.annotations[key].length) {
						layout.value.file.annotations[key].forEach(file => {
							annotationsZip.folder('annotations').file(file.file.name, file.file._data, { binary: true });
						});
					}
				}

				for (let zf in layout.value.file.dir) {
					if (isMultiClassOrLabel) filesZip.folder(zf);

					for (let zi = 0; zi < layout.value.file.dir[zf].length; zi++) {
						let ifile = layout.value.file.dir[zf][zi];
						if (!ifile) continue;

						const fileName = ifile.file.name ? ifile.file.name.toString().split('/').pop() : ifile.name.toString().split('/').pop();
						const fileData = ifile.file._data || ifile.file;
						const target = isMultiClassOrLabel ? filesZip.folder(zf) : filesZip

						target.file(fileName, fileData, { binary: true });
						countFiles++;
					}
				}

				await manageUpload(filesZip, annotationsZip, countFiles);
				//await manageUpload_old(filesZip, countFiles);

				layout.value.uploading = false;
				layout.value.success = true;
			}
		}

		const manageUpload = async (filesZip, annotationsZip, countFiles) => {
			const date = new Date();
			const dateString = date.toLocaleString('en-GB', {
			year:	'numeric',
			month:	'2-digit',
			day: 	'2-digit',
			hour: 	'2-digit',
			minute: '2-digit',
			second: '2-digit',
			hour12: false
			}).replace(/\/|, /g, '-').replace(/:/g, '-');

			const configPath	= 'gs://' + config.firebaseConfig.projectId;
			const childPath 	= 'upload/manual/' + layout.value.dataset + "." + dateString;
			
			let annotations = {};

			if (annotationsZip.files) {
				annotations = await uploadAnnotationsZip(annotationsZip, configPath, childPath);
			}

			await uploadFilesZip(filesZip, configPath, childPath, countFiles, annotations);
		}

		const uploadFilesZip = async (zip, configPath, childPath, totalImages, annotations = {}) => {
			const concurrencyLimit 	= 15;
			let imgsProcessed 		= 0;

			const files = Object.keys(zip.files)
				.map(fileName => ({
					name: fileName,
					file: zip.files[fileName]
				}))
				.filter(file => !file.file.dir);

			const uploadImage = async ({name: fileName, file}) => {
				try {
					await $image.upload({
						file:		file,
						fileName: 	fileName,
						datasetID: 	layout.value.dataset,
						configPath: configPath,
						childPath: 	childPath,
						tags: 		annotations[fileName] ? annotations[fileName].tags : []
					});

					imgsProcessed++;
					layout.value.debug = `Processed ${imgsProcessed} / ${totalImages} images...`;
				} catch (err) {
					console.error(`Error uploading file ${fileName}:`, err);
				}
			};

			for (let i = 0; i < files.length; i += concurrencyLimit) {
				const group = files.slice(i, i + concurrencyLimit);
				console.log("Uploading group...", i);
				await Promise.all(group.map(file => uploadImage(file)));
			}

			layout.value.debug = "All images processed, finalizing...";
			await new Promise(resolve => setTimeout(resolve, 2000));
		};

		const uploadFilesZip_o = async (zip, configPath, childPath, totalImages, annotations = {}) => {
			const concurrencyLimit = 5;
			let activePromises = [];
			let imgsProcessed = 0;

			const files = Object.keys(zip.files)
				.map(fileName => ({
					name: fileName,
					file: zip.files[fileName]
				}))
				.filter(file => !file.file.dir);

			const uploadImage = async ({name: fileName, file}) => {
				try {
					await $image.upload({
						file: file,
						fileName: fileName,
						datasetID: layout.value.dataset,
						configPath: configPath,
						childPath: childPath,
						tags: annotations[fileName] ? annotations[fileName].tags : []
					});
					imgsProcessed++;
					layout.value.debug = `Processed ${imgsProcessed} / ${totalImages} images...`;
				} catch (err) {
					console.error(`Error uploading file ${fileName}:`, err);
				}
			};

			for (const file of files) {
				const promise = uploadImage(file).then(() => {
					activePromises = activePromises.filter(p => p !== promise);
				});
				activePromises.push(promise);
				if (activePromises.length >= concurrencyLimit) {
					await Promise.race(activePromises);
				}
			}

			await Promise.all(activePromises);
			layout.value.debug = "All images processed, finalizing...";
			await new Promise(resolve => setTimeout(resolve, 2000));
		}

		const uploadAnnotationsZip = async (zip, configPath, childPath) => {
			let annotations = {};

			try {
				for (let fileName in zip.files) {
					const file = zip.files[fileName];
					if (!file.dir) {
						const extension = fileName.split('.').pop().toLowerCase();
						const blob 		= await file.async('blob');
						const text 		= await blob.text();
						const lines 	= text.split('\n');

						for (const line of lines) {
							if (line.trim() === '') continue;

							if (extension === 'csv') {
								const [key, tagType, ...rest] = line.split(',');
								if (key.trim() !== '') {
									if (!annotations[key]) {
										annotations[key] = { tags: [] };
									}
									const filteredData = rest.filter(item => item.trim() !== '');
									annotations[key].tags.push({ type: tagType, data: filteredData });
								}
							} else if (extension === 'txt') { 
								const [tagType, ...data] = line.split(' ');
								const simpleFileName = fileName.split('/').pop();
								if (simpleFileName.trim() !== '') { 
									if (!annotations[simpleFileName]) {
										annotations[simpleFileName] = { tags: [] };
									}
									annotations[simpleFileName].tags.push({ type: tagType, data });
								}
							}
						}

						try {
							const uploadRef = $_firebase.getStorage(configPath, childPath + '/' + fileName);
							await uploadBytes(uploadRef, blob, { contentType: 'text/' + extension });
						} catch (error) {
							console.error('Error uploading', fileName, error);
						}
					}
				}
			} catch (err) { console.error('Error processing annotations Zip:', err); }

			return annotations;
		}

		const uploadFilesZip_v21 = async (zip, configPath, childPath, totalImages, annotations = {}) => {
			let imgsProcessed = 0;

			try {
				const files = Object.keys(zip.files).map(fileName => ({
					name: fileName,
					file: zip.files[fileName]
				})).filter(file => !file.file.dir);

				for (const { name: fileName, file } of files) {
					layout.value.debug = `Processed ${imgsProcessed} / ${totalImages} images...`;

					await $image.upload({
						file:       file,
						fileName:   fileName,
						datasetID:  layout.value.dataset,
						configPath: configPath,
						childPath:  childPath,
						tags: 	 	annotations[fileName] ? annotations[fileName].tags : []
					});

					imgsProcessed++;
				}

				layout.value.debug = "Adding images to the dataset, Please wait...";
				await new Promise(resolve => setTimeout(resolve, 2000));

			} catch (err) {
				console.error('Error processing files Zip:', err);
			}
		}

		const manageUpload_old = async (zip, files) => {
			let initialCount	= layout.value.datasetData.tagsCounter.count;

			let nowDate			= new Date();
			let configPath		= 'gs://' + config.firebaseConfig.projectId;
			let zipName			= layout.value.dataset + "." + nowDate.getTime() + ".zip"
			let childPath 		= 'upload/manual/zip/' + zipName;
			let uploadRef		= $_firebase.getStorage(configPath, childPath);

			console.log("Initial count:", initialCount);
			console.log("Files to upload:", files);

			await zip.generateAsync({ type: "blob" }).then(async function (blob) { 
				await new Promise((resolve, reject) => {
					const uploadTask = uploadBytesResumable(uploadRef, blob);
					uploadTask.on("state_changed",
						(snapshot) => {
							if (snapshot.bytesTransferred)		layout.value.zip.bytesTransferred = snapshot.bytesTransferred;
							if (snapshot.totalBytes) 			layout.value.zip.totalBytes = snapshot.totalBytes;
							if (snapshot.state) 				layout.value.zip.uploadStatus = snapshot.state;
							if (snapshot.bytesTransferred && snapshot.totalBytes) layout.value.zip.percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
							if (layout.value.zip.percentage)	layout.value.debug = layout.value.zip.percentage.toString().split(".")[0] + "%";
						},

						(error) => {
							layout.value.error = error;
							reject(error);
						},

						async () => {
							const version = 1;
							
							if (version === 1) {
								await $dataset.fileOnFinalize({ datasetID: layout.value.dataset, filename: zipName });
								await $dataset.uploadZip(layout.value.dataset, config.functions.usapi);

								let intervCnt	= 0;
								let intervClr 	= false;

								let intervId = setInterval(async () => {
									layout.value.debug = "Adding images to the dataset, Please wait...";

									let dsData = await $dataset.get(layout.value.dataset, { tagsCounter: true });
									layout.value.datasetImageCount = dsData.tagsCounter.count ? dsData.tagsCounter.count : 0;

									if (zipName == dsData.uploadRef) {
										if (dsData.uploadStatus) {
											switch(dsData.uploadStatus) {
												case "reintent":	console.log('--- Load Reintent ---'); break;
												case "error": 		console.log('--- Load Error'); break;
												case "success":		intervClr = true; break;
												case "processing":	break;
												default: break;
											}
										}
									}

									if (intervCnt == 240 || !(parseInt(layout.value.datasetImageCount) - parseInt(initialCount) - parseInt(files))) intervClr = true;
									else intervCnt++;

									if (intervClr) {
										layout.value.uploading = false;

										if (!dsData.uploadStatus || dsData.uploadStatus == "error") layout.value.error = dsData.uploadStatusMsg;
										else layout.value.success = true;
										
										if (!((parseInt(layout.value.datasetImageCount) - parseInt(initialCount)) - parseInt(files))) layout.value.success = true;

										if (layout.value.success) {
											setTimeout(async () => { 
												if (document.getElementById("refreshMosaicBtn")) {
													document.getElementById("refreshMosaicBtn").click();
												}
											}, 300);
										}

										if (layout.value.datasetData.type == 'imageObjectDetection') {
											await $dataset.updateTagsContained(layout.value.dataset);
										}

										await $dataset.update(layout.value.dataset, { uploadStatus: layout.value.error ? "error" : "success" });

										await $event.saveEvent('dataset.upload.zip', { 
											uid: useStore().state.main.User.uid, 
											status: layout.value.error ? "error" : "success", 
											dataset: layout.value.dataset, 
											zip: zipName 
										}, layout.value.error ? true : false);

										clearInterval(intervId);
										resolve();
									}
								}, 30000);
							}
							
							else {
								await $dataset.uploadZip(layout.value.dataset, config.functions.usapi);
								await $dataset.trackZipUpload(layout.value.dataset, async (currentStatus, currentMessage) => {
									switch (currentStatus) {
										case 'success':
											layout.value.uploading = false;
											layout.value.success = true;
											if (document.getElementById("refreshMosaicBtn")) {
												document.getElementById("refreshMosaicBtn").click();
											}
											await $_firebase.updateDoc(layout.value.dataset);
											resolve();
											break;

										case 'error':
											layout.value.uploading = false;
											layout.value.error = currentMessage;
											await $_firebase.updateDoc(layout.value.dataset);
											resolve();
											break;

										case 'processing':
											console.log("Processing...")
											layout.value.debug = "Adding images to the dataset, Please wait...";
											break;
									}
								});
							}
						}
					);
				});
			});
		}

		onMounted(async () => { 
			console.log("------dropzone mounted------")
			await initDropzone();
			layout.value.dataset = props.datasetId;
		});

		watch(() => props.datasetId, async () => {  layout.value.dataset = props.datasetId; });
		watch(() => layout.value.dataset, async () => { await loadDataset() });

		const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

		return {
			layout,
			props,
			showDropzone,
			isDragActive,
			dropzoneUrl,
			initDropzone,
			resetDropzone,
			uploadFile,
			getRootProps,
			getInputProps,
			submitUrl
		};
	},

	methods: {
		goAction: async function (a) { if (this.$parent?.goAction) this.$parent.goAction(a); },
		refreshMosaic: async function () { if (this.$parent?.refreshMosaic) this.$parent.refreshMosaic(); },
	}
});
</script>

<style>
.dropzoneBoxContent { 
	max-width: 100%; 
	min-width: 450px; 
}

.font-italic { 
	font-style: italic !important; 
}

.__uploaddzmin { 
	font-size: 11px; 
}

.uploadFileFull { 
	position: fixed !important; 
	left: 0 !important; 
	top: 0 !important; 
	z-index: 999 !important; 
	width: 100% !important; 
	height: 100vh !important; 
	background-color: #f1f2f1; 
}

.uploadingFull { 
	position: fixed !important; 
	left: 0 !important; 
	top: 0 !important; 
	z-index: 999 !important; 
	width: 100% !important; 
	height: 100vh !important; 
	background-color: #f1f2f1; 
}

#uploadComponent{ 
	width: 100%;
	height: 100%;
	border: 2px dashed #edeaeaeb;
	background-color: #fff;
	padding: 20px;
	text-align: center;
}
</style>