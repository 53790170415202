'use strict';

import _firebase    from './_firebase.js';
import dataset      from './dataset.js';
import event        from './event.js';
import helper       from './helper.js';
import other        from './other.js';
import project      from './project.js';

import { 
    collection, 
    doc, 
    getDoc,
    getDocs, 
    serverTimestamp,
    setDoc,
    query, 
    orderBy, 
    limit,
    updateDoc
} from "firebase/firestore";

const model = {

    create: async function(datasetID, modelName, modelData = false) {
        console.log("Creating model for:", datasetID);
        console.log("Model name:", modelName);
        console.log("Model data:", modelData);

        const db = _firebase.firestore;
        let resp = { status: "error", error: false };

        if (datasetID && modelName) {
            let nowdate = new Date();
            resp.modelId = modelName.toString().toLowerCase().replace(/\s+/g, '-') + "-" + nowdate.getTime();
            resp.modelCreated = {
                automl:             "",
                aws:                "",
                dataset:            doc(db, 'dataset', datasetID.toString()),
                description:        "",
                savedModel:         "",
                status:             "undeployed",
                trainBudget:        "",
                annotationSetId:    "",
                createdAt:          serverTimestamp() 
            }
            
            if (modelData) resp.modelCreated = { ...resp.modelCreated, ...modelData };

            console.log("Model created:", resp.modelCreated);
            
            const docRef = doc(db, "model", resp.modelId);
            await setDoc(docRef, resp.modelCreated)
                .then(function() { resp.status = "success"; })
                .catch(function(error) { resp.error = error; })

            resp.modelCreated.dataset = datasetID;
            resp.status = "success";
        } else { resp.error = "dataset Id and model name is required" }

        return resp;
    },

    list: async function(opt = false) {
        const db = _firebase.firestore;
        let models = [];
        let m = collection(db, 'model');
        m = query(m, orderBy('createdAt', 'desc'));
        if (opt.limit) m = query(m, limit(opt.limit));
        const snapshot = await getDocs(m);
        snapshot.docs.forEach(doc => {
            let item = doc.data();
            item.id = doc.id;
            if (item.dataset) item.dataset = item.dataset.path.toString().split('/').pop();
            if (item.createdAt) item.created = helper.getTimestampDate(item.createdAt.toDate(), 'full');
            let pushItem = true;
            if (opt.export && !item.savedModel) pushItem = false;
            if (opt.vertex && !item.automl) pushItem = false;
            if (item.deleted) pushItem = false;
            if (pushItem) models.push(item);
        });
        return models;
    },

    get: async function(modelId) {
        let model = {};
        const docRef = doc(_firebase.firestore, 'model', modelId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            model = docSnap.data();
            model.id = docSnap.id;
            if (model.dataset) model.dataset = model.dataset.path.toString().split('/').pop();
            if (model.createdAt) model.created = helper.getTimestampDate(model.createdAt.toDate(), 'full');
            await dataset.update(model.dataset, { trained: Boolean(true) });
        }
        return model;
    },

    update: async function(modelId, data) {
        const db = _firebase.firestore;
        let resp = { status: "error", error: false }

        console.log("Updating model...");

        if (modelId) {
            data["updatedAt"] = serverTimestamp();
            const docRef = doc(db, "model", modelId);
            await updateDoc(docRef, data);
            await event.saveEvent('model.update', JSON.stringify(data), false);
            resp.status = "success";
        } else { 
            resp.error = "modelId is required" ;
        }

        return resp;
    },

    getVertex: async function(opt = false) {
        let resp = { status: "error", error: false };

        if (opt.datasetID) {
            let dsData = await dataset.get(opt.datasetID);
            resp.dataset = opt.datasetID;
            if (dsData.automl) {
                resp.automl = dsData.automl;
                let dsVertex = await other.httpsCallable('api/model/datasets/dataset_id/' + dsData.automl);
                
                if (dsVertex.data) {
                    resp.response = dsVertex.data;
                    resp.status = "success";
                } else { resp.error = "dataset " + dsData.automl + " not found in Vertex AI"; }
            } else { resp.error = "dataset is not created in Vertex AI"; }
        }
        
        else if (opt.modelID) {
            await other.httpsCallable('api/model/get/model_id/' + opt.modelID).then(function(vertexModel) {
                if (vertexModel.data) {
                    resp.data = vertexModel.data
                    resp.status = "success"
                } else { resp.error = "model " + opt.modelID + " not found" }
            })
        } 
        
        else { resp.error = "DatasetID or ModelID is required"; }
    
        return resp;
    },

    createVertex: async function(datasetID) {
        let resp = { status: "error", error: false };

        if (datasetID) {
            let dsData = await dataset.get(datasetID);
            resp.dataset = datasetID;

            if (!dsData.automl) {
                let vxName = dsData.name.replace(/-/g, "_");
                let createVertex = await other.httpsCallable('api/model/create/dataset/' + vxName.toLowerCase() + '-' + dsData.type);

                if (createVertex.data) {
                    await event.saveEvent('dataset.create.vertex', { dataset: dsData.name.toString(), response: JSON.stringify(createVertex.data) }, false);
                    resp.automl = createVertex.data.name.toString().split('/').pop();
                    await dataset.update(resp.dataset, { automl: resp.automl });
                    resp.status = "success";
                } else { resp.error = "failed to create dataset " + (createVertex.error ? createVertex.error : ""); }
            } else { resp.error = "The dataset is already created in vertex with the identifier: " + dsData.automl; }
        } else { resp.error = "dataset Id is required"; }

        return resp;
    },

    getVertexCsv: async function(datasetID, set = false) {
        let resp = { status: "error", error: false, csv: [] };

        if (datasetID) {
            let call = 'api/dataset/' + datasetID + '/csv';
            if (set.method && set.method == "auto") call = call + '?test=' + set.test + "&validation=" + set.validation;
            let csv = await other.httpsCallable(call);
            if (csv.data) {
                resp.csv = csv;
                if (csv.data && csv.data.training && csv.data.training.dataset) await dataset.update(datasetID, { importCsv: csv.data.training.dataset })
                resp.status = "success";
            } else { resp.error = "dataset not found CSV with division data" }
        } else { resp.error = "datasetI Id is required" }

        return resp;
    },

    downloadVertexCsv: async function(datasetID, projectID) {
        let resp = { status: "error", error: false, csv: false }

        try {
            if (projectID && datasetID) {
                const storage = _firebase.storage;
                const gsReference = ref(storage, 'gs://' + projectID + '/model-config/' + datasetID + '.csv');
                const url = await getDownloadURL(gsReference);

                if (url) {
                    resp.status = "success";
                    resp.csv = url;
                } else { 
                    resp.error = "dataset " + datasetID + " CSV not found in project " + projectID;
                }
            } else {
                resp.error = "datasetId and projectID are required";
            }
        } catch (error) { 
            resp.error = error; 
        }

        return resp;
    },
    
    getExportUrl: async function(modelId) {
        let resp = { status: "error", error: false }
        if (modelId) {
            resp.model = modelId
            let item = await this.get(modelId)
            if (item.automl) {
                resp.automlId = item.automl
                resp.api_url = 'api/model/export/model_id/' + item.automl
                let config = project.getConfig()
                if (config.modelBucket) {
                    resp.bucket = config.modelBucket
                    resp.api_url += '-|-' + config.modelBucket.replace(/\//g, "!!-")
                }
                resp.status = "success"
            } else { resp.error = "model does not have automl Id" }
        } else { resp.error = "modelId is required" }
        return resp
    },

    export: async function(modelId, opt = false) {
        let resp = { status: "error", error: false }
        if (modelId) {
            let exportUrl = await this.getExportUrl(modelId)
            if (exportUrl.api_url) {
                let req = await other.httpsCallable(exportUrl.api_url)
                if (!req.error) {
                    let savedModel = req.data.metadata.outputInfo.artifactOutputUri + "/saved_model.pb"
                    let _rq = await this.update(modelId, { savedModel: savedModel })
                    resp.exportStorage = { model: exportUrl.automlId, name: exportUrl.model, bucket: exportUrl.bucket, url: exportUrl.api_url }
                    if (!_rq.error) {
                        resp.savedModel = savedModel
                        if (opt.updateModel) await this.update(modelId, { savedModel: savedModel })
                        resp.status = "success"
                    } else { resp.error = _rq.error }
                } else { resp.error = req.error }
            } else { resp.error = exportUrl.error }
        } else { resp.error = "modelId is required" }
        return resp
    },

    uploadStorage: async function(dsData, opt = false) {
        let resp = { status: "error", error: false }

        if (dsData.id) {
            let tagsCounter = await dataset.getTagStats(dsData);
            resp.dataset = dsData.id;

            if (tagsCounter.count) {
                if (dsData.automl) {
                    resp.dataset    = dsData.id;
                    resp.api        = _firebase.getApiHost();
                    resp.csvQry     = 'api/dataset/' + dsData.id + '/csv';

                    if (opt.test || opt.validation) {
                        resp.csvQry += opt.test ? "?test=" + opt.test : "";
                        resp.csvQry += opt.validation && opt.test ? "&" : "";
                        resp.csvQry += opt.validation ? "validation=" + opt.validation : "";
                    }

                    if (opt.tagMap) {
                        if (opt.test || opt.validation) resp.csvQry  += '&tagmap=';
                        else resp.csvQry  += '?tagmap=';
                        Object.keys(opt.tagMap.tags).forEach(key => {
                            Object.keys(opt.tagMap.tags[key].tags).forEach(k => {
                                if (k != "count") resp.csvQry += key.replace(/\s/g, "!!-") + "-|-" + k.toString().replace(/\s/g, "!!-") + '--||--';
                            });
                        });
                    }

                    // LLamada a api/dataset/:dataset/csv
                    let csv = await other.httpsCallable(resp.csvQry);
                    resp.csv = csv.data ? csv.data.training.dataset : false;

                    if (csv.data && csv.data.training && csv.data.training.dataset) await dataset.update(dsData.id, { importCsv: csv.data.training.dataset })

                    if (csv.data && resp.csv) {
                        // Llamada a '/model/:action (import)/:param (dataset)/:value'
                        resp.importQry = 'api/model/import/dataset/' + dsData.automl + '-|-' + resp.csv.replace(/\//g, "!!-") + '-|-' + dsData.type.replace(/\//g, "!!-");

                        let importResp = await other.httpsCallable(resp.importQry);

                        if (importResp.data && importResp.data.name) {
                            resp.operationId    = importResp.data.name.toString().split('/').pop();
                            resp.operationName  = importResp.data.name.toString();

                            await event.saveEvent('dataset.import', { dataset: dsData.automl, csv: resp.csv, operation: resp.operationId, operationName: resp.operationName }, false)
                            
                            resp.status = "success";
                        } else { resp.error = "failed to import to Vertex" }
                    } else { resp.error = "failed to generate csv"; }
                } else { resp.error = "dataset automl Id is required"; }
            } else { resp.error = "the dataset does not have images"; }
        } else { resp.error = "dataset Id is required"; }

        if (resp.error) await event.saveEvent('dataset.import.vertex', { dataset: dsData.id, response: resp }, true);

        return resp;
    },

    validateToTrain: async function(dsData, opt = {}) {
        let validation = {
            dataset:        dsData.data.name,
            type:           dsData.data.type,
            tags:           await dataset.getTagStats(dsData.data, opt.tagMap ? opt.tagMap : false),
            division:       opt.noDivision ? false : dsData.vertexDivision,
            validated:      true,
            errors:         [],
            warnings:       [],
            trainingImages: await dataset.getImages(dsData.data, { resume: true, tagMap: opt.tagMap ? opt.tagMap : false }),
        }

        if (opt.tagMap) validation.tagMap = opt.tagMap;

        if (validation.trainingImages.count < 20) {
            validation.validated = false;
            validation.errors.push("Must have at least 20 labeled images")
        }

        if (opt?.tagMap?.tags && Object.keys(opt.tagMap.tags).length < 2 && validation.type == "MULTICLASS") {
            validation.validated = false;
            validation.errors.push("Must select at least 2 classes for training")
        }

        if (validation.trainingImages.images.length) {
            let repeatName = { imgNames: {}, validate: true }
            for (let i = 0; i < validation.trainingImages.images.length; i++) {
                if (repeatName.imgNames[validation.trainingImages.images[i].name]) { repeatName.validate = false; } else { repeatName.imgNames[validation.trainingImages.images[i].name] = true }
            }
            if (!repeatName.validate) { validation.warnings.push("There are repeated image names") }
        }

        if (validation.type == "MULTICLASS") {
            if (!validation.tags.tags || !Object.keys(validation.tags.tags).length) {
                validation.errors.push("No defined required tags, must have at least 2 tags")
                validation.validated = false
            } else {
                for (let index in validation.tags.tags) {
                    if (validation.tags.tags[index] < 10) { validation.validated = false; validation.errors.push("The " + index + " tag must have at least 10 images for the tag") }
                }
            }
        } else if (validation.type == "imageObjectDetection" || validation.type == "MULTILABEL") {
            if (!validation.tags.tagsLabeledImg || !Object.keys(validation.tags.tagsLabeledImg).length) {
                validation.errors.push("No defined required tags, must have at least 2 tags")
                validation.validated = false
            } else {
                for (let index in validation.tags.tagsLabeledImg) {
                    if (validation.tags.tagsLabeledImg[index] < 10) { validation.validated = false; validation.errors.push("The " + index + " tag must have at least 10 images for the tag") }
                }
            }
        }

        if (!opt.noDivision) {
            if (!Object.keys(validation.division).length) {
                validation.validated = false; validation.errors.push("No defined data division")
            } else {
                validation.division.percentages = {
                    train:      validation.division['train']        ? ((validation.division['train'] * 100) / validation.division['total']).toFixed(2) : 0,
                    test:       validation.division['test']         ? ((validation.division['test'] * 100) / validation.division['total']).toFixed(2) : 0,
                    validation: validation.division['validation']   ? ((validation.division['validation'] * 100) / validation.division['total']).toFixed(2) : 0
                }

                if (validation.division.percentages.train < 70)         { validation.validated = false; validation.errors.push("Must have at least 70% train") }
                if (validation.division.percentages.test < 10)          { validation.validated = false; validation.errors.push("Must have at least 10% test") }
                if (validation.division.percentages.validation < 10)    { validation.validated = false; validation.errors.push("Must have at least 10% validation") }

                if (validation.type == "MULTICLASS" || validation.type == "MULTILABEL") {
                    if (validation.division.test < 10)          { validation.validated = false; validation.errors.push("Must have at least 10 test images") }
                    if (validation.division.validation < 10)    { validation.validated = false; validation.errors.push("Must have at least 10 validation images") }
                    if (validation.division.train < 10)         { validation.validated = false; validation.errors.push("Must have at least 10 train images") }
                }

                validation.division.chart = {
                    labels: ["test", "validation", "train"],
                    datasets: [{
                        data: [validation.division.test, validation.division.validation, validation.division.train],
                        backgroundColor: [helper.StringtoHex("test"), helper.StringtoHex("validation"), helper.StringtoHex("train")],
                        hoverBackgroundColor: [helper.StringtoHex("test"), helper.StringtoHex("validation"), helper.StringtoHex("train")],
                        borderWidth: 5,
                        borderColor: "#fff"
                    }]
                }
            }
        }

        return validation;
    },

    train: async function(trainingData) {
        let resp = { status: "error", error: false };

        if (trainingData.datasetID) {
            resp.apiQry = 'api/model/create/model/' + trainingData.datasetID + '-' + trainingData.modelName + '-' + trainingData.trainBudget + '-' + trainingData.type;
            if (trainingData.annotationSetId)   resp.apiQry += '-' + trainingData.annotationSetId;
            if (trainingData.divisionType)      resp.apiQry += '-' + trainingData.divisionType;
            if (trainingData.division)          resp.apiQry += '-' + trainingData.division;

            let trainingResp = await other.httpsCallable(resp.apiQry);

            if (trainingResp.data) {
                resp.operationId = trainingResp.data.name.toString().split('/').pop();
                resp.operationName = trainingResp.data.name.toString();
                resp.status = "success";
            } else { resp.error = "failed to init training api request to create a model"; }
        } else { resp.error = "dataset Id is required"; }

        if (resp.error) await event.saveEvent('dataset.train.vertex', { dataset: trainingData.datasetID, response: resp }, true); //uid: useStore().state.main.User.uid,

        return resp;
    },

    parserVertexModel: async function(model, modelObj = false) {
        let resp = { status: "error", error: false }
        if (model) {
            let m = modelObj ? modelObj : {}
            m.displayName = model.displayName
            m.type = model.metadata.structValue.fields.modelType.stringValue
            m.versionId = model.versionId
            m.trainingDataItemsCount = model.metadata.structValue.fields.trainingDataItemsCount.stringValue
            m.trainingAnnotationsCount = model.metadata.structValue.fields.trainingAnnotationsCount.stringValue
            m.validationDataItemsCount = model.metadata.structValue.fields.validationDataItemsCount.stringValue
            m.validationAnnotationsCount = model.metadata.structValue.fields.validationAnnotationsCount.stringValue
            m.eligibleAsBaseModel = model.metadata.structValue.fields.eligibleAsBaseModel ? model.metadata.structValue.fields.eligibleAsBaseModel.boolValue : false
            let trainingPipeline = await other.httpsCallable('api/model/trainingpipeline/status/' + model.trainingPipeline.replace(/\//g, "--"))
            let training = trainingPipeline.data.trainingTaskMetadata.structValue.fields
            if (training) m.training = {
                budgetMilliNodeHours: trainingPipeline.data.trainingTaskInputs.structValue.fields.budgetMilliNodeHours.stringValue,
                costMilliNodeHours: training.costMilliNodeHours.stringValue,
                stopReason: training.successfulStopReason.stringValue
            }
            return m
        } else { resp.error = "model data is required" }
        return resp
    },

    parserVertexMetrics: async function(metrics) {
        let resp = { status: "error", error: false }
        if (metrics) {
            let m = {}
            //metrics confidence
            m.auPrc = metrics.structValue.fields.auPrc ? metrics.structValue.fields.auPrc.numberValue : false
            m.logLoss = metrics.structValue.fields.logLoss ? metrics.structValue.fields.logLoss.numberValue : false
            m.boundingBoxMeanAveragePrecision = metrics.structValue.fields.boundingBoxMeanAveragePrecision ? metrics.structValue.fields.boundingBoxMeanAveragePrecision.numberValue : 0
            m.evaluatedBoundingBoxCount = metrics.structValue.fields.evaluatedBoundingBoxCount ? metrics.structValue.fields.evaluatedBoundingBoxCount.numberValue : 0
            let meItem = metrics.structValue.fields.confidenceMetrics ? metrics.structValue.fields.confidenceMetrics.listValue.values : false
            if (metrics.structValue.fields.boundingBoxMetrics) meItem = metrics.structValue.fields.boundingBoxMetrics.listValue.values
            if (meItem) {
                let _mt = { count: meItem.length, metrics: [] /*{}*/ }
                for (let _i = 0; _i < meItem.length; _i++) {
                    let bItem = meItem[_i]
                    let m = { metricId: _i + 1 }
                    //let index   = 0
                    for (const _b of Object.keys(bItem.structValue.fields)) {
                        let _bitem = bItem.structValue.fields[_b];
                        m[_b] = _bitem.numberValue
                        if (_b == 'recall' || _b == 'precision') m[_b + "Percent"] = (_bitem.numberValue * 100).toFixed(1)

                        //if(_b =='confidenceThreshold')index = _bitem.numberValue

                        if (_bitem.listValue) {
                            let _bitemList = bItem.structValue.fields[_b].listValue.values
                            m[_b] = { count: _bitemList.length, confidence: [] }
                            for (const _bl of Object.keys(_bitemList)) {
                                let _bitemListFields = _bitemList[_bl].structValue.fields;
                                let v = {}
                                for (const _bf of Object.keys(_bitemListFields)) {
                                    v[_bf] = _bitemListFields[_bf].numberValue
                                    if (_bf == 'recall' || _bf == 'precision' || _bf == 'f1Score') v[_bf + "Percent"] = (_bitemListFields[_bf].numberValue * 100).toFixed(1)
                                }
                                m[_b].confidence.push(v)
                            }
                        }
                    }
                    //_mt.metrics[index] = m
                    _mt.metrics.push(m)
                }
                m.confidenceMetrics = _mt
            }
            //metrics confusion matrix
            let confusionMatrix = metrics.structValue.fields.confusionMatrix ? metrics.structValue.fields.confusionMatrix.structValue.fields : false
            if (confusionMatrix) {
                let matrix = {}
                for (const con of Object.keys(confusionMatrix)) {
                    matrix[con] = []
                    let cm = confusionMatrix[con].listValue.values
                    for (let _i = 0; _i < cm.length; _i++) {
                        let m = false
                        if (cm[_i].structValue) {
                            m = {}
                            let _cm = cm[_i].structValue.fields
                            for (const _c of Object.keys(_cm)) { m[_c] = _cm[_c].stringValue }
                        }
                        if (cm[_i].listValue) {
                            m = []
                            let _cm = cm[_i].listValue.values
                            for (let _c = 0; _c < _cm.length; _c++) { m.push(_cm[_c].numberValue) }
                        }
                        matrix[con].push(m)
                    }
                }
                if (matrix.rows) {
                    matrix.rowsPercent = []
                    for (let _cr = 0; _cr < matrix.rows.length; _cr++) {
                        let countRows = 0
                        let rowVals = []
                        for (let _r = 0; _r < matrix.rows[_cr].length; _r++) {
                            countRows = countRows + matrix.rows[_cr][_r]
                            rowVals.push(matrix.rows[_cr][_r])
                        }
                        let rowAdd = []
                        for (let _rv = 0; _rv < rowVals.length; _rv++) {
                            rowAdd.push(Math.round((rowVals[_rv] / countRows) * 100, 2))
                        }
                        matrix.rowsPercent.push(rowAdd)
                    }
                }
                m.confusionMatrix = matrix
            }
            return m
        } else { resp.error = "metrics are required" }
        return resp
    },

    getEvaluations: async function(modelId) {
        let resp = { status: "error", error: false }
        if (modelId) {
            let item = await this.get(modelId)
            resp.model = modelId
            if (item && item.automl) {
                resp.automl = item.automl
                let vertexModel = await other.httpsCallable('api/model/get/model_id/' + item.automl)
                let req = await other.httpsCallable('api/model/evaluations/model_id/' + item.automl)
                resp.count = req.data ? req.data.length : 0
                resp.evaluations = []
                if (resp.count) {
                    for (var i = 0; i < resp.count; i++) {
                        let vertexModelSlices = await other.httpsCallable('api/model/evaluationslices/evaluation_id/' + req.data[i].name.toString().replace(/\//g, "_"))
                        //evaluation
                        let eva = {
                            evaluationId: req.data[i].name.toString().split('/').pop(),
                            name: req.data[i].name.toString(),
                            typeObjects: req.data[i].metrics.structValue.fields.boundingBoxMetrics ? true : false,
                            created: helper.getFbDate(req.data[i].createTime),
                            explanations: req.data[i].explanationSpecs.length ? { count: req.data[i].explanationSpecs.length, types: [] } : false,
                            model: { id: item.automl, tagMap: item.tagMap ? item.tagMap : false },
                            slices: { ALL: await this.parserVertexMetrics(req.data[i].metrics) },
                            evaluatedCount: req.data[i].metrics.structValue.fields.evaluatedBoundingBoxCount ? req.data[i].metrics.structValue.fields.evaluatedBoundingBoxCount.numberValue : 0,
                        }

                        //explanations
                        for (var ex = 0; ex < req.data[i].explanationSpecs.length; ex++) { eva.explanations.types.push(req.data[i].explanationSpecs[ex].explanationType) }

                        //slices          
                        if (vertexModelSlices.data) {
                            for (let _s = 0; _s < vertexModelSlices.data.length; _s++) {
                                eva.slices[vertexModelSlices.data[_s].slice.value] = await this.parserVertexMetrics(vertexModelSlices.data[_s].metrics)
                            }
                        }

                        //model data
                        if (vertexModel.data) eva.model = await this.parserVertexModel(vertexModel.data, eva.model)

                        //add evaluation
                        resp.evaluations.push(eva)
                    }
                    resp.status = "success"
                } else { resp.error = "could not get the vertex evaluation" }
            } else { resp.error = "model does not have automl Id" }
        } else { resp.error = "modelId is required" }
        return resp
    },

    renderEvaluations: async function(modelId, opt = false) {
        let resp = { status: "error", error: false, render: false }
        if (modelId) {
            let modelEvaluations = await this.getEvaluations(modelId)
            let themeSettings = {
                dark: {
                    backgroundColor: '#1a202c',
                    textColor: '#fff',
                    matrixBackColor: '#1a202c',
                    padBox: '20px'
                },
                light: {
                    backgroundColor: '#fff',
                    textColor: '#1a202c',
                    matrixBackColor: '#ededed',
                    padBox: '0'
                },
                settings: {
                    textSize: opt && opt.textSize ? opt.textSize : '0.75rem',
                    titleSize: opt && opt.titleSize ? opt.titleSize : '15px',
                    titles: opt && opt.titles ? opt.titles : true,
                }
            }
            resp.theme = opt && opt.theme ? opt.theme : 'dark'
            if (modelEvaluations.evaluations && Object.keys(modelEvaluations.evaluations).length) {

                resp.evaluations = modelEvaluations.evaluations
                resp.render = "<div id='evaluationBox' style='color: " + themeSettings[resp.theme].textColor + "; background-color:" + themeSettings[resp.theme].backgroundColor + "; padding: " + themeSettings[resp.theme].padBox + "; font-size:" + themeSettings.settings.textSize + "'>"
                for (let _c = 0; _c < resp.evaluations.length; _c++) {

                    if (!opt.onlyMetrics) {
                        //Evaluation
                        resp.render += "<table style='width: 100%; margin: 0;'>"
                        resp.render += "<tr><td style='padding-bottom: 10px;font-size:" + themeSettings.settings.titleSize + "; font-weight:500' colspan='2'>Evaluation</td></tr>"
                        if (resp.evaluations[_c].evaluationId) resp.render += "<tr><td style='padding:5px; width: 250px'>ID</td><td style='padding:5px'>" + resp.evaluations[_c].evaluationId + "</td></tr>"
                        if (resp.evaluations[_c].created) resp.render += "<tr><td style='padding:5px'>Created</td><td style='padding:5px'>" + resp.evaluations[_c].created + "</td></tr>"
                        if (resp.evaluations[_c].model.id) resp.render += "<tr><td style='padding:5px'>Model</td><td style='padding:5px'>" + resp.evaluations[_c].model.displayName + "</td></tr>"
                        if (resp.evaluations[_c].model.versionId) resp.render += "<tr><td style='padding:5px'>Version</td><td style='padding:5px'>" + resp.evaluations[_c].model.versionId + "</td></tr>"
                        resp.render += "</table>"

                        //Training
                        resp.render += "<table style='width: 100%;margin: 0 0 30px 0;'>"
                        if (resp.evaluations[_c].model.training) {
                            resp.render += "<tr><td style='padding: 20px 0 10px 0;font-size:" + themeSettings.settings.titleSize + "; font-weight:500' colspan='2'>Training</td></tr>"
                            if (resp.evaluations[_c].model.training.budgetMilliNodeHours)
                                resp.render += "<tr><td style='padding:5px; width: 250px'>budget</td><td style='padding:5px'>" + resp.evaluations[_c].model.training.budgetMilliNodeHours + " milliNodeHours" + "</td></tr>"
                            if (resp.evaluations[_c].model.training.costMilliNodeHours)
                                resp.render += "<tr><td style='padding:5px'>cost</td><td style='padding:5px'>" + resp.evaluations[_c].model.training.costMilliNodeHours + " milliNodeHours" + "</td></tr>"
                            if (resp.evaluations[_c].model.training.stopReason)
                                resp.render += "<tr><td style='padding:5px'>stopReason</td><td style='padding:5px'>" + resp.evaluations[_c].model.training.stopReason + "</td></tr>"
                        }
                        if (resp.evaluations[_c].model) {
                            let modelItems = ["trainingDataItemsCount", "trainingAnnotationsCount", "validationDataItemsCount", "validationAnnotationsCount"]
                            for (let _m = 0; _m < modelItems.length; _m++) {
                                if (resp.evaluations[_c].model[modelItems[_m]]) { resp.render += "<tr>" + "<td style='padding:5px'>" + modelItems[_m] + "</td>" + "<td style='padding:5px'>" + resp.evaluations[_c].model[modelItems[_m]] + "</td>" + "</tr>" }
                            }
                        }
                        if (resp.evaluations[_c].metrics) {
                            let metrics = ["auPrc", "logLoss", "boundingBoxMeanAveragePrecision", "evaluatedBoundingBoxCount"]
                            for (let _m = 0; _m < metrics.length; _m++) {
                                if (resp.evaluations[_c].metrics[metrics[_m]]) { resp.render += "<tr>" + "<td style='padding:5px'>" + metrics[_m] + "</td>" + "<td style='padding:5px'>" + resp.evaluations[_c].metrics[metrics[_m]] + "</td>" + "</tr>" }
                            }
                        }
                        resp.render += "</table>"
                    }

                    if (resp.evaluations[_c] && resp.evaluations[_c].slices && resp.evaluations[_c].slices["ALL"].confusionMatrix) {
                        //if(themeSettings.settings.titles)resp.render  += "<div style='padding: 10px 5px; font-size:"+ themeSettings.settings.titleSize +"; font-weight:500'>Confusion matrix</div>"
                        resp.render += "<table style='margin: 60px 10px 0 10px'>"
                        resp.render += "<tr style='border-bottom: 1px solid #ccc;'>"
                        resp.render += "<td style='padding:3px 0 0 0; min-width: 180px; max-width: 180px;'></td>"
                        for (let _cm = 0; _cm < resp.evaluations[_c].slices["ALL"].confusionMatrix.annotationSpecs.length; _cm++) {
                            resp.render += "<td style='padding:3px 5px;transform: translateX(-5%) translateY(-30px) rotate(-40deg) !important; overflow: hidden; overflow-x: visible; white-space: nowrap;min-width: 70px !important;max-width: 70px !important;'>"
                                + resp.evaluations[_c].slices["ALL"].confusionMatrix.annotationSpecs[_cm].displayName
                                + "</td>"
                        }
                        resp.render += "</tr>"
                        resp.render += "</table>"
                        resp.render += "<table style='margin:5px 10px;'>"
                        for (let _cm = 0; _cm < resp.evaluations[_c].slices["ALL"].confusionMatrix.annotationSpecs.length; _cm++) {
                            resp.render += "<tr style='border-bottom: 1px solid #f7f8f9;line-height: 35px'>"
                            resp.render += "<td style='padding: 3px 0 0 0; min-width: 160px; max-width: 160px; overflow-x: hidden'>" + resp.evaluations[_c].slices["ALL"].confusionMatrix.annotationSpecs[_cm].displayName + "</td>"
                            for (let _r = 0; _r < resp.evaluations[_c].slices["ALL"].confusionMatrix.rows[_cm].length; _r++) {
                                resp.render += "<td style='padding:3px 5px; max-width: 70px !important; width: 70px !important; min-width: 70px !important; text-align: center;"
                                resp.render += "background-color: " + (resp.evaluations[_c].slices["ALL"].confusionMatrix.rowsPercent[_cm][_r] == "100" ? '#104b9e' : resp.evaluations[_c].slices["ALL"].confusionMatrix.rowsPercent[_cm][_r] > "50" ? '#b7d3fa' : themeSettings[resp.theme].matrixBackColor) + ";"
                                resp.render += "color: " + (resp.evaluations[_c].slices["ALL"].confusionMatrix.rowsPercent[_cm][_r] == "100" ? '#fff' : resp.evaluations[_c].slices["ALL"].confusionMatrix.rowsPercent[_cm][_r] > "50" ? '#174EA6' : themeSettings[resp.theme].textColor)
                                resp.render += "'>"
                                //resp.render += "<div style='display: inline-block; text-align: center'>"+resp.evaluations[_c].slices["ALL"].confusionMatrix.rows[_cm][_r] + "</div>"
                                resp.render += "<div style='padding: 0 0 0 5px; display: inline-block; line-height: 18px;'>"
                                if (resp.evaluations[_c].slices["ALL"].confusionMatrix.rowsPercent[_cm][_r]) resp.render += resp.evaluations[_c].slices["ALL"].confusionMatrix.rowsPercent[_cm][_r] + "%"
                                else resp.render += "-"
                                resp.render += "</div>"
                                resp.render += "</td>"
                            }
                            resp.render += "</tr>"
                        }
                        resp.render += "</table>"
                    }

                    //tagMap
                    if (resp.evaluations[_c].model && resp.evaluations[_c].model.tagMap) {
                        var _tagMap = JSON.parse(resp.evaluations[_c].model.tagMap)
                        console.log(_tagMap)
                        resp.render += "<table style='margin: 5px 0 0 5px; width:100%; font-size: " + themeSettings.settings.textSize + ";'>"
                        resp.render += "<tr>"
                        resp.render += "<td style='padding:5px 0 15px 0;font-size:" + themeSettings.settings.titleSize + ";' colspan='2'>TagMap</td>"
                        resp.render += "</tr>"
                        for (var _tag in _tagMap.tags) {
                            resp.render += "<tr>"
                            resp.render += "<td style='width:30%; padding: 5px 5px 5px 10px; border: 1px solid #e2e8f0;background-color: rgba(247, 250, 252, 0.8);'>" + _tag + "</td>"
                            resp.render += "<td style='width:10%; padding: 5px 5px 5px 10px; border: 1px solid #e2e8f0; text-align: center'>" + _tagMap.tags[_tag].total + "</td>"
                            resp.render += "<td style='width:60%; padding: 5px 5px 5px 10px; border: 1px solid #e2e8f0;'>"
                            var _labelTags = Object.keys(_tagMap.tags[_tag].tags).length
                            var _cntTags = 0;
                            for (var _t in _tagMap.tags[_tag].tags) {
                                if (_t != 'count') {
                                    _cntTags++;
                                    resp.render += _t + " (" + _tagMap.tags[_tag].tags[_t].count + ")"
                                    if (_cntTags < _labelTags - 1) resp.render += " + "
                                }
                            }
                            resp.render += "</td>"
                            resp.render += "</tr>"
                        }
                        resp.render += "</table>"
                    }

                    //slices metrics objects
                    if (resp.evaluations[_c].slices && resp.evaluations[_c].slices["ALL"]["boundingBoxMeanAveragePrecision"]) {
                        resp.render += "<table style='margin: 20px 0 0 5px;'>"
                        resp.render += "<tr>"
                        resp.render += "<td style='width:200px; padding:5px 0 15px 0;font-size:" + themeSettings.settings.titleSize + ";'>Tag</td>"
                        if (resp.evaluations[_c].slices["ALL"]["auPrc"]) resp.render += "<td style='width:100px; padding:5px 0 15px 0;font-size:" + themeSettings.settings.titleSize + "; font-weight:500'>auPrc</td>"
                        if (resp.evaluations[_c].slices["ALL"]["logLoss"]) resp.render += "<td style='width:100px; padding:5px 0 15px 0;text-align: center;font-size:" + themeSettings.settings.titleSize + "; font-weight:500'>logLoss</td>"
                        if (resp.evaluations[_c].slices["ALL"]["boundingBoxMeanAveragePrecision"]) resp.render += "<td style='width:150px; padding:5px 0 15px 0;font-size:" + themeSettings.settings.titleSize + "'>Average precision</td>"
                        if (resp.evaluations[_c].slices["ALL"]["evaluatedBoundingBoxCount"]) resp.render += "<td style='width:200px; padding:5px 0 15px 0;font-size:" + themeSettings.settings.titleSize + "'>Evaluated BoundingBox</td>"
                        //if(!resp.evaluations[_c].typeObjects && resp.evaluations[_c].slices["ALL"]["confidenceMetrics"])resp.render  += "<td style='padding:5px 0 15px 0;text-align: center; font-size:"+ themeSettings.settings.titleSize +"; font-weight:500'>Confidence / Precision / Recovery</td>"
                        resp.render += "</tr>"
                        for (const _s of Object.keys(resp.evaluations[_c].slices)) {
                            resp.render += "<tr style='border-bottom: 1px solid #e2e8f0;'>"
                            resp.render += "<td style='width:200px;padding: 5px 0;'>" + _s + "</td>"
                            if (resp.evaluations[_c].slices[_s]["auPrc"]) resp.render += "<td style='width:100px'><div style='background-color: #f7f8f9; color: #333; text-align: center; padding: 5px 2px;width: 45px;'>" + resp.evaluations[_c].slices[_s]["auPrc"].toFixed(3) + "</div></td>"
                            if (resp.evaluations[_c].slices[_s]["logLoss"]) resp.render += "<td style='width:100px;text-align: center'>" + resp.evaluations[_c].slices[_s]["logLoss"].toFixed(3) + "</td>"
                            if (resp.evaluations[_c].slices[_s]["boundingBoxMeanAveragePrecision"]) resp.render += "<td style='width:150px; '><div style='background-color: #e8f0fe; color: #333; text-align: center; padding: 2px 2px;width: 40px;'>" + resp.evaluations[_c].slices[_s]["boundingBoxMeanAveragePrecision"].toFixed(3) + "</div></td>"
                            if (resp.evaluations[_c].slices[_s]["evaluatedBoundingBoxCount"]) resp.render += "<td style='width:200px'>" + resp.evaluations[_c].slices[_s]["evaluatedBoundingBoxCount"] + "</td>"
                            resp.render += "</tr>"
                        }
                        resp.render += "</table>"
                    }
                }
                resp.status = "success"
                resp.render += "</div>"
            } else { resp.error = "model does not have evaluations" }
        } else { resp.error = "modelId is required" }
        return resp
    },
}

export default model;