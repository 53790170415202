import { createRouter, createWebHistory } from "vue-router";

import SideMenu 		from "@/layouts/side-menu/Main.vue";

import Api 				from "@/views/api/Main.vue";
import Controller 		from "@/views/controller/Main.vue";
import Dashboard 		from "@/views/dashboard/Main.vue";
import Datasets 		from "@/views/datasets/Main.vue";
import ErrorPage 		from "@/views/error-page/Main.vue";
import Flow 			from "@/views/flow/Main.vue";
import Login 			from "@/views/login/Main.vue";
import Node 			from "@/views/node/Main.vue";
import Profile 			from "@/views/profile/Main.vue";
import Settings 		from "@/views/settings/Main.vue";

const routes = [
	{
		path: "/",
		component: SideMenu,
		children: [
			{
				path: "/",
				name: "side-menu-dashboard",
				component: Dashboard
			},
			{
				path: "profile",
				name: "side-menu-profile",
				component: Profile
			},
			{
				path: "node",
				name: "side-menu-node",
				component: Node
			},
			{
				path: "flow",
				name: "side-menu-flow",
				component: Flow
			},
			{
				path: "datasets/:id?/:i?",
				name: "side-menu-datasets",
				component: Datasets
			},
			{
				path: "controller",
				name: "side-menu-controller",
				component: Controller
			},
			{
				path: "settings",
				name: "side-menu-settings",
				component: Settings
			},
		]
	},
	{
		path: "/login",
		name: "login",
		component: Login
	},
	{
		path: "/api",
		name: "api",
		component: Api
	},
	{
		path: "/error-page",
		name: "error-page",
		component: ErrorPage
	},
	{
		path: "/:pathMatch(.*)*",
		component: ErrorPage
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes, scrollBehavior(to, from, savedPosition) {
		return savedPosition || { left: 0, top: 0 };
	}
});

export default router;