<template>
	<div class="mt-5">
		<div class="grid grid-cols-12 gap-6 mt-2">
			<div class="col-span-12 lg:col-span-12 xxl:col-span-12">
				<div><ManageDatasets ref="ManageDatasets" /></div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent, computed, onMounted } from "vue";
import { useRouter } 	from "vue-router";
import { useI18n } 		from 'vue-i18n';
import { useStore } 	from "@/store";

import ManageDatasets 	from "@/components/manage-datasets/Main.vue";

export default defineComponent({
	components: {
		ManageDatasets
	},
	setup() {
		const store		= useStore();
		const router 	= useRouter();
		const User 		= computed(() => store.state.main.User);
		const { t } 	= useI18n();

		onMounted(async () => {});

		return {
			User,
			t,
		};
	}
});
</script>