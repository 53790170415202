<template>
	<div>
		<div id="rpTopBarLine"
			class="top-bar-line top-bar-boxed border-b border-theme-2 -mt-7 md:-mt-5 -mx-3 sm:-mx-8 px-3  md:pt-0 bg-theme-1">
			<div class="h-full flex items-center">
				<a href="#" class="-intro-x hidden md:flex pt-2">
					<img class="w-64 ml-5 pl-5 pr-5" :src="Brand"  @click="linkTo('side-menu-dashboard')" />
				</a>  
				<div class="-intro-x breadcrumb mr-auto text-sm pl-3 ml-5 border-theme-2">
					<div class="top-bar-node-menu ml-0 hidden w-full mb-0.5">

						<div class="inline-block">
							<div class="px-3 py-1.5  bg-theme-1 text-gray-600 hover:text-white border-r border-gray-800 top-bar-node-menu-fullscreen" title="FullScreen">
								<div class="flex zoom-in items-center" @click="toFullScreen()" v-if="!layout.isFullScreen"><MaximizeIcon class="w-5 h-5" /></div>
								<div class="flex zoom-in items-center" @click="toNormalScreen()" v-else><MinimizeIcon class="w-5 h-5" /></div>
							</div>
						</div>

						<template v-for="(menu, menuKey) in mainMenu">
							<div class="inline-block" 
								:key="menu + menuKey" :class="menu != 'devider' ? '' : 'hidden'" 
								v-if="User.role && menu.role_id && menu.role_id!=User.role.role_id ? false : true && menu.roles ? User.role ? menu.roles.includes(User.role.role_id) : false ? true : false : true">
								<div class="px-3 py-1.5 bg-theme-1 text-gray-600 hover:text-white" :class="menu.pageName!='side-menu-dashboard' ? 'border-l border-gray-800 top-bar-node-menu-' + menu.pageName : 'w-12'" :title="menu.title">
									<div class="flex zoom-in items-center" @click="linkTo(menu.pageName)">
										<component :is="menu.icon" class="w-5 h-5"  /> <span class="ml-1.5" v-if="menu.pageName!='side-menu-dashboard'">{{ menu.title }}</span>
									</div>
								</div>
							</div>
						</template>

					</div>
				</div>
				<div id="rpFlowAssistantTopBtn" class="intro-x dropdown w-7 h-7 mr-4 hidden" title="Flow Assistant">
					<div class="dropdown-toggle w-7 h-7 rounded-full overflow-hidden shadow-lg image-fit scale-110 bg-theme-16 text-center pt-0" 
						@click="ShowAssistant()"
						role="button" aria-expanded="false">
						<span class="text-white"><img :src="require('@/assets/images/rosepetal/icon/assistantTop.png')" class="w-6 mt-2 ml-0.5" /></span>
					</div>
				</div>
				<div class="intro-x dropdown w-7 h-7 mr-8">
					<div class="dropdown-toggle w-7 h-7 rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110 bg-theme-16 text-center pt-0 " role="button" aria-expanded="false" @click="ShowMenu()">
						<span class="text-white" v-if="User.displayName">
							<UserIcon  class="w-7 h-8 " /> 
						</span>
					</div>
					<div class="dropdown-menu w-56">
						<div class="dropdown-menu__content box bg-theme-11 dark:bg-dark-6 text-white" v-if="menuvisible">
							<div class="p-4 border-b border-theme-12 dark:border-dark-3">
								<div class="font-medium" v-if="User.displayName">{{ User.displayName }}</div>
								<div class="text-xs text-theme-13 mt-0.5 dark:text-gray-600" v-if="User.email">{{ User.email }}</div>
								<div class="text-xs text-theme-13 mt-0.5 dark:text-gray-600" v-if="User.role">{{ User.role.name }}</div>
							</div>
							<div class="p-2">
								<a class="flex items-center p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md cursor-pointer" @click="linkTo('side-menu-profile')">
									<UserIcon class="w-4 h-4 mr-2" /> {{ $t('My account') }}
								</a>
								<a v-if="User.role && root" class="flex items-center p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md cursor-pointer" @click="linkTo('side-menu-settings')">
									<SlidersIcon class="w-4 h-4 mr-2" /> {{ $t('Settings') }}
								</a>
							</div>
							<div class="p-2 border-t border-theme-12 dark:border-dark-3" v-if="$i18n.locale">
								<div v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" class="cursor-pointer relative flex items-center" @click="ChangeLanguage(locale)">
									<div class="overflow-hidden">
										<div class="flex items-center">
											<a class="font-medium truncate ml-3" v-if="locale != $i18n.locale">{{ $t("lang" + locale) }}</a>
										</div>
									</div>
								</div>
							</div>
							<div class="p-2 border-t border-theme-12 dark:border-dark-3">
								<a class="flex items-center p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md cursor-pointer TopBarMenu" @click="Logout" >
									<PowerIcon class="w-4 h-4 mr-2" /> {{ $t('Logout') }}
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div id="topBarUiIframe" class="hidden"> 
			<div class="grid grid-cols-12 pr-0 mr-0">
				<div class="col-span-12"><div id="iframeTopBarContent" class="iframeContent"><iframe id="uiFrame" style="width: 100%; height: 100%" class="w-full node-frame mt-5" src="about:blank"></iframe></div></div>
			</div>
		</div>

		<div id="nodeLog" :class="!nodeLog.display ? 'hidden' : ''">
			<div id="nodeLogheader">
				<MoveIcon class="w-4 h-4 float-left" />  NodeRed Log 
				<XIcon class="w-5 h-5 float-right cursor-pointer" @click="nodeLog.display = false" />
				<Trash2Icon class="w-4 h-4 float-right cursor-pointer mt-0.5" @click="nodeLog.log = []" />
			</div>
			<div id="nodeLogContent">
				<div v-if="Object.keys(nodeLog.log).length">
					<div v-for="(log,index) in nodeLog.log" :key="index" class="nodeItem">
						{{log.func}} <span class="date">{{log.createdAt}}</span> 
						<div class="message" v-if="log.message">{{log.message}}</div>
					</div>
				</div>
				<div v-else>
					<div class="empty"><TerminalIcon class="w-20 h-20" /></div>
				</div>  
			</div>
		</div>

		<div id="topBarFlowIframe" class="hidden"> 
			<div class="grid grid-cols-12 pr-0 mr-0">
				<div id="topBarFlowIframeBox" class="col-span-12">
					<div id="iframeFlowTopBarContent" class="iframeContent" style="position: relative !important;">
						<iframe id="flowFrame" style="width: 100%; height: calc(100% - 56px);" class="w-full node-frame mt-5" src="about:blank"></iframe>
					</div>
				</div>
			</div>
		</div>

		<div id="topBarControllerIframe" class="hidden"> 
			<div class="grid grid-cols-12 pr-0 mr-0">
				<div class="col-span-12 ">
					<div class="iframeContent"><iframe id="controllerFrame" style="width: 100%; height: 100%" class="w-full node-frame mt-5" src="about:blank"></iframe></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent, ref, onMounted, computed, watch } from "vue";
import { useRouter }			from "vue-router";
import { useStore } 			from "@/store";
import { helper as $h } 		from "@/utils/helper";
import cash 					from "cash-dom";

import * as rosepetalModel 		from "rosepetal-model";
import config 					from '@/etc/rosepetal.json';

import { signOut }				from 'firebase/auth';

const nodeConfig = config.nodeConfig;

let $_firebase 	= rosepetalModel.default._firebase;
let $controller = rosepetalModel.default.controller;
let $ui			= rosepetalModel.default.ui;

export default defineComponent({
	setup() {
		const store 		= useStore();
		const User 			= computed(() => store.state.main.User);
		const root 			= ref(false);
		const router 		= useRouter();
		const date 			= ref();
		const menuvisible	= ref(true)
		const CurLocale 	= ref(false)
		const mainMenu 		= ref();
		const Brand 		= ref();
		const nodeLog 		= ref({ log: [], display: false, hide: true });
		const layout      	= ref({ isFullScreen: false });

		const getMenu = async () => {
			mainMenu.value = await $ui.getMainMenu({ raw: true });
		}

		const Logout = async () => {
			menuvisible.value = false;
			$controller.setHost(nodeConfig.host);
			sessionStorage.clear();
			router.replace('/login');
			signOut($_firebase.auth).then(() => console.log('Sign Out')).catch(err => alert(err.message));
		}

		const ShowAssistant = () => {
			if (cash("#topBarFlowIframe").hasClass("topBarFlowIframeAssistant")) {
				cash("#topBarFlowIframe").removeClass("topBarFlowIframeAssistant");
				if (cash("#rpNodeCol2")) cash("#rpNodeCol2").addClass("rpNodeHidden");
			} else {
				cash("#topBarFlowIframe").addClass("topBarFlowIframeAssistant");
				if (cash("#rpNodeCol2")) cash("#rpNodeCol2").removeClass("rpNodeHidden");
			} 
		}

		const linkTo = (page, params = false) => {  
			let p = { type: false }; 
			if (params) p.type = params;
			let url = new URL(window.location.href);
			if (page === 'side-menu-datasets' && params && url.pathname === '/node') {
				sessionStorage.setItem('nodered', true);
			} else {
				sessionStorage.setItem('nodered', false);
			}
			router.push({ name: page, params: p.type }); 
		}

		const testLinkTo = () => {
			linkTo('side-menu-' + 'datasets', { id: 'rp-obj-insermatest', i: 'PBYXWh8Hdn5Gu4KixSIt' });
		}

		onMounted(async() => {
			if (store.state.main.User.role && (store.state.main.User.role.role_id == 2 || store.state.main.User.role.role_id == 3)) {
				root.value = true; 
			}
			
			try { 
				Brand.value = require(`@/assets/logo/brand/` + config.brand + `.png`)
			} catch (e) { 
				Brand.value = require(`@/assets/images/logo/logorosepetal.png`) 
			}
			
			window.addEventListener('message', (event) => {
				if (event.data) {
					if (event.origin == nodeConfig.host) {
						if (event.data.createdAt) event.data.createdAt = $h.getTimestampDate(new Date())
						nodeLog.value.log.push(event.data)
						if (event.data.func == "goPage" && event.data.message) {
							console.log("Event obtained:", event.data.message, event.data.params)
							linkTo('side-menu-' + event.data.message, event.data.params);
						}
					}
				}
			});

			await getMenu();
			$h.dragElement(document.getElementById("nodeLog"));
		});

		watch(() => store.state.main.User.role, () => { 
			if (store.state.main.User.role && (store.state.main.User.role.role_id == 2 || store.state.main.User.role.role_id == 3)) root.value = true;  
		});

		watch(() => router.currentRoute.value, () => { 
			nodeLog.value.hide = router.currentRoute.value.fullPath != '/node' ? true : false; 
		});

		return {
			date,
			mainMenu,
			User,
			menuvisible,
			root,
			CurLocale,
			nodeLog,
			Brand,
			layout,
			Logout,
			linkTo,
			testLinkTo,
			ShowAssistant,
		};
	},
	
	methods: {
		ShowMenu() { 
			this.menuvisible = true 
		},
		ChangeLanguage(locale) { 
			this.$i18n.locale = locale; 
			cash(".language-menu").removeClass("show"); 
			this.$router.replace('/');  
		},
		toFullScreen() {
			this.layout.isFullScreen = true;
			document.documentElement.requestFullscreen();
		},
		toNormalScreen() {
			this.layout.isFullScreen = false;
			document.exitFullscreen();
		}
	}
});
</script>

<style scoped>
.language-menu{ max-width: 200px;}

.top-bar-node-menu-current{ color: #fff; }

.language-menu-locale{ line-height: 27px; text-transform: uppercase; color: #ccc;}
#topBarUiIframe{min-height: 100vh !important;position: absolute;width: 100% !important;padding: 0;margin: 0 !important; display: none;}
#topBarFlowIframe{min-height: 100vh !important;position: absolute;padding: 0;margin: 0 !important; display: none;}

#topBarFlowIframe{z-index: 99 !important; width: 100%}
#topBarFlowIframeBox{ height: 100% !important;min-height: 100vh !important;}

.topBarFlowIframeAssistant{width: 75% !important;}

#topBarControllerIframe{height: 90%;min-height: 90%;position: absolute;width: 100% !important;padding: 0;margin: 45px 0 0 0 !important; display: none;}
.node-frame{ width: 100%; margin: 0 !important; padding: 0;}
.iframeContent{height: 100%;min-height: 100%;display: block;position: absolute;width: 100% !important;padding: 0;margin: 0 !important; }
#nodeLog{position: absolute;z-index: 9;right:0;background-color: #000;border: 1px solid #d3d3d3;text-align: left;width: 350px;}
#nodeLogheader {padding: 10px;cursor: move;z-index: 10;background-color: #000;color: #fff; text-align: center;}
.nodeItem{ padding: 2px 8px; color: #00ff00; font-size:12px;line-height: 13px; margin: 3px 2px}
.nodeLogContent{padding: 10px;}
.nodeItem .date{ color: #fff; font-size:10.5px; color: #ccc; float: right}
.nodeItem .message{ color: #fff; font-size:11px}
#nodeLog .empty{ color: #fff; font-size:13px; text-align: center; color: #00ff00}
#nodeLogBtn{position: absolute;z-index: 99;right:15px;background-color: #071a50;text-align: center;width: 60px; color: #fff; cursor: pointer; ;}
</style>