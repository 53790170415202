import { createApp } 		from "vue";

import App 					from "./App.vue";
import router 				from "./router";
import store 				from "./store";
import globalComponents 	from "./global-components";

import fEs 					from "./assets/locales/es.json";
import fEn 					from "./assets/locales/en.json";
import { createI18n } 		from "vue-i18n";
import "./libs";

//SASS Theme
import "./assets/sass/app.scss";

const messages = { es: fEs, en: fEn };
const i18n = new createI18n({
	legacy: false,
	locale: 'en',
	fallbackLocale: 'en',
	messages
});

const app = createApp(App)
	.use(store)
	.use(router)

app.config.isCustomElement = tag => tag.startsWith('smart-');

globalComponents(app);

app.use(i18n);
app.mount("#app");