'use strict';

import _firebase    from './_firebase.js';
import helper       from './helper.js';

import { getDoc, doc }		from "firebase/firestore";
import { httpsCallable }	from "firebase/functions";

const user = {

    getUserData: async function (uid) {
        let userData = await getDoc(doc(_firebase.firestore, 'users', uid));
        return userData.data() ? userData.data() : false;
    },

    list: async function() {
        let users = [];
        let functions = _firebase.firebase ? _firebase.functions : null;
        if (!functions) return users;
        const action = httpsCallable(functions, 'api/user/list');
        await action({}).then(response => { if (response.data) users = response.data; }).catch(async (error) => { console.log(error) });
        if (users.length) {
            for (let i = 0; i < users.length; i++) {
                let user = users[i];
                user.data = await this.getUserData(user.uid);
                users[i] = user;
            }
        }
        return users;
    },

    getRole: async function (email) {
        let userRole = { role_id: 1, name: 'user' };

		const usersDoc = await getDoc(doc(_firebase.firestore, 'view', 'users'));
		if (!usersDoc.exists()) return role;
		
		else {
			const roles = usersDoc.data().roles;
			Object.keys(roles).forEach((role) => {
				if (roles[role].includes(email)) {
					if (role === 'admin') 			userRole = { role_id: 2, name: role };
					else if (role === 'superadmin') userRole = { role_id: 3, name: role };
				}
			})
			return userRole;
		}
    },

    delete: async function(uid, msg = false) {
        let functions = _firebase.firebase ? _firebase.functions : null;
        const action = httpsCallable(functions, 'api/user/delete');
        await action({ uid: uid }).then(() => { if (msg) helper.NotificationTxt({ text: 'Usuario eliminado correctamente' }); })
            .catch((error) => { if (msg) helper.NotificationTxt({ text: 'No se ha podido eliminar el usuario. ' + error }); });
    },

    update: async function(user, msg = false) {
        let functions = _firebase.firebase ? _firebase.functions : null;
        const action = httpsCallable(functions, 'api/user/update');
        if (user.data) delete (user.data);
        await action(user).then(() => { if (msg) helper.NotificationTxt({ text: 'Usuario actualizado correctamente' }); })
            .catch((error) => { if (msg) helper.NotificationTxt({ text: 'No se ha podido actualizar el usuario. ' + error }); });
    },

    create: async function(user, msg = false) {
        let functions = _firebase.firebase ? _firebase.functions : null;
        const action = httpsCallable(functions, 'api/user/create');
        await action(user).then(() => { if (msg) helper.NotificationTxt({ text: 'Usuario creado correctamente' }); })
            .catch((error) => { if (msg) helper.NotificationTxt({ text: 'No se ha podido crear el usuario. ' + error }); });
    },

    search: async function(uid) {
        let user = [];
        let functions = _firebase.firebase ? _firebase.functions : null;
        if (!functions) return user;
        const action = httpsCallable(functions, 'api/user/search/uid/' + uid);
        await action({}).then(response => { if (response.data) user = response.data; }).catch(async (error) => { console.log(error) });
        return user;
    },
}

export default user;