<template>
  <div>
    <div class="container">
      <div
        class="error-page flex flex-col lg:flex-row items-center justify-center h-screen text-center lg:text-left"
      >
        <div class="-intro-x lg:mr-20">
          <img
            alt="Icewall Tailwind HTML Admin Template"
            class="h-48 lg:h-auto"
            :src="require(`@/assets/images/error-illustration.svg`)"
          />
        </div>
        <div class="text-white mt-10 lg:mt-0">
          <div class="intro-x text-8xl font-medium">404</div>
          <div class="intro-x text-xl lg:text-3xl font-medium mt-5">
            Oops. Página no encontrada
          </div>
          <div class="intro-x text-lg mt-3">
            Es posible que haya escrito mal la dirección o que la página se haya movido.
          </div>
          <button class="intro-x btn py-3 px-4 text-white border-white dark:border-dark-5 dark:text-gray-300 mt-10" @click="linkTo('side-menu-monitor')">
            Inicio
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import { useRouter } 	from "vue-router";
import cash				from "cash-dom";

export default defineComponent({
  setup() {
    const router = useRouter();
    onMounted(() => {
      cash("body")
        .removeClass("main")
        .removeClass("login")
        .addClass("error-page");
    });

    const linkTo = (page) => {
      router.push({
        name: page
      });
    };

    return {
      router,
      linkTo
    };
  }
});
</script>
