<template>
	<div id="dataset-manage-page">

		<!-- Title & Buttons -->
		<div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center">
			<div class="inline-block text-2xl font-medium mr-4">{{$t('Datasets')}}</div>
			<div class="dropdown" data-placement="bottom-start">
				<button class="btn px-2 box text-gray-700 hover:bg-green-600 hover:text-white" @click="Modal('dataset-create-box'); layout.newDataset.name= 'untitled_' + new Date().getTime()">
					<span class="w-5 h-5 flex items-center justify-center"><PlusIcon class="w-4 h-4" /></span>
				</button>
			</div>
			<button class="btn px-2 box text-gray-700 ml-1 hover:bg-blue-500 hover:text-white" @click="reset()">
				<span class="w-5 h-5 flex items-center justify-center"><RefreshCcwIcon class="w-4 h-4" /></span>
			</button>
		</div>

		<!-- Filters -->
		<div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-5 xl:mt-0" style="min-width: 700px;">
			<div class="mx-auto text-gray-600" v-if="listOptions.value">
				<div class="px-10 py-3 bg-white rounded-full border" :class="listOptions.value.type || listOptions.value.trained ? 'pl-10' : ''">
					
					<select class="w-72 form-select p-2 cursor-pointer bg-white align-top" :disabled="layout.loading.list?true:false"
						v-model="listOptions.value.type" :class="listOptions.value.type ? layout.loading.list ? 'bg-blue-500' : 'bg-blue-500 text-white' : 'text-gray-600'">
							<option value="">{{$t('All types')}}</option>
							<option value="MULTICLASS">{{$t('Classification')}}</option>
							<option value="imageObjectDetection">{{$t('Object detection')}}</option>
							<option value="MULTILABEL">{{$t('Image segmentation')}}</option>
					</select>

					<select class="w-44 form-select p-2 cursor-pointer ml-2 bg-white align-top" :disabled="layout.loading.list?true:false"
						v-model="listOptions.value.trained" :class="listOptions.value.trained ? layout.loading.list ? 'bg-blue-500' : 'bg-blue-500 text-white' : 'text-gray-600'">
							<option value="">{{$t('All')}}</option>
							<option value="true">{{$t('Trained')}}</option>
					</select>

					<select class="w-32 form-select p-2 cursor-pointer ml-2 text-gray-600 bg-white align-top" 
						v-model="listOptions.value.order" :disabled="layout.loading.list? true : false">
							<option value="desc">{{$t('Date desc')}}</option>
							<option value="asc">{{$t('Date asc')}}</option>
					</select>

					<button class="btn px-1 box bg-gray-600 mt-0.5 ml-4 hover:bg-blue-500 text-white h-8" @click="reset()" title="Reset Filters" :class="listOptions.value.type || listOptions.value.trained ? '' : 'hidden'">
						<span class="w-5 h-5 flex items-center justify-center"><TrashIcon class="w-4 h-4" /></span>
					</button>

				</div>
			</div>
		</div>

		<div class="mt-5 w-full" v-if="!layout.loading.list">
			<div class="text-gray-600 text-center text-xs">{{$t('Showing')}} {{ Object.keys(datasetsList).length }} {{ $t('datasets') }}</div>
		</div>

		<!-- Loading -->
		<div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center p-5 mt-10" v-if="layout.loading.list">
			<div class="flex items-center justify-center">
				<img :src="require(`@/assets/images/rosepetal/icon/logoLoadingGray.png`)" class="w-24" />
			</div>
			<LoadingIcon icon="three-dots" class="w-8 h-8" />
		</div>

		<!-- List datasets-->
		<div class="mt-6 grid grid-cols-12 gap-3 mb-60" v-if="!layout.loading.list">
			<div v-for="(d) in datasetsList" :key="d.id" class="col-span-12 md:col-span-6 lg:col-span-4 xxl:col-span-3">
				<div class="box hover:bg-gray-100 border-t-4 pb-2" :class="d.trained ? 'border-green-500' : (tagCounter && tagCounter[d.id] && !tagCounter[d.id].count) ? 'border-red-300' : ' border-gray-500'">
					<div class="flex flex-col lg:flex-row items-center p-5 border-b border-gray-200  h-52 lg:h-auto">
						<a @click="manageDataset(d)">
							<div class="w-16 h-16 xxl:w-20 xxl:h-20 image-fit lg:mr-1" v-if="d?.preview"><img :src="d?.preview" class="w-full h-full rounded-full zoom-in" /></div>
							<div class="w-16 h-16 xxl:w-20 xxl:h-20 image-fit lg:mr-1" v-else><img class="rounded-full border border-gray-300 zoom-in" :src="require(`@/assets/images/rosepetal/icon/logoLoadingGray.png`)" /></div>
						</a>

						<div class="lg:ml-2 lg:mr-auto text-center lg:text-left mt-3 lg:mt-0 cursor-pointer" @click="manageDataset(d)">
							<a class="font-medium" v-if="d.name">{{d.name.slice(0, 35)}} <span v-if="(d.name.length>35)">...</span></a>
							<div class="text-gray-600 text-xs mt-0.5" v-if="d.typeName">{{ d.typeName }}</div>
							<div class="text-gray-600 text-xs mt-0.5" v-else-if="d.type">
								<span v-if="d.type=='MULTICLASS'">{{$t('Image classification')}}</span>
								<span v-else-if="d.type=='MULTILABEL'">{{$t('Image segmentation')}}</span>
								<span v-else-if="d.type=='imageObjectDetection'">{{$t('Object Detection')}}</span>
							</div>
							<span v-if="d.createdAt" class="text-gray-600 text-xs mt-0.5 font-italic" style="font-size:11px">{{ this.helper.getTimestampDate(d.createdAt.toDate(),'date')}}</span>
							<div class="text-gray-600 text-xs mt-0,5" v-if="d.count">
								<ImageIcon class="w-4 h-4 mr-1 text-gray-600" /> <span class="align-middle">{{ this.helper.formatScore(d.count) }} {{$t('Images')}}</span>
							</div>
						</div>
						<div class="absolute right-0 top-0 mr-5 mt-3 dropdown">
							<a class="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false"><MoreHorizontalIcon class="w-5 h-5 text-gray-600 dark:text-gray-300"/></a>
							<div class="dropdown-menu w-40">
								<div class="dropdown-menu__content box dark:bg-dark-1 p-2">
									<a @click="manageDataset(d)" class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md cursor-pointer"><Edit2Icon class="w-4 h-4 mr-2" /> {{ $t('Edit') }}</a>
									<a @click="labeling(d)" class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md cursor-pointer"><ImageIcon class="w-4 h-4 mr-2" /> {{ $t('Labeling') }}</a>
									<a @click="layout.dataset.delete = d; Modal('dataset-delete-box')" class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md cursor-pointer"><TrashIcon class="w-4 h-4 mr-2" /> {{ $t('Delete') }}</a>
								</div>
							</div>
						</div>
						<div class="flex justify-end text-xs sm:mt-2 cursor-pointer" @click="manageDataset(d)"><div v-if="d?.trained" title="Trained" class="mt-1 lg:mr-4"><CodesandboxIcon class="w-8 h-8 mr-0 text-green-600" style="margin-right: 0 !important;" /></div></div>
					</div>
				</div>
			</div>
		</div>

		<!-- No results -->
		<div class="text-center justify-center mt-20 text-gray-600" v-if="!layout.loading.list && !Object.keys(datasetsList).length" >
			<div>
				<SearchIcon class="w-12 h-12" /> <h2 class="text-lg truncate mt-2 font-medium">{{ $t('No results found') }}</h2>
				<div>{{ $t("Sorry, that filter combination has no results") }}</div>
				<div class="mt-2 text-blue-500 cursor-pointer" @click="reset()">{{$t('reset filters')}}</div>
			</div>
		</div>

		<!-- Manage dataset -->
		<div id="dataset-manage" class="modal overflow-y-auto" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static" :class="!layout.dataset.manage || !Object.keys(layout.dataset.manage).length ? 'hidden' : ''">
			
			<imageEditor :options="layout.editor.options" :datasetsList="datasetsList" ref="imageEditorComponent" />

			<div class="modal-dialog modal-xl" style="width: 1170px; max-width: 1170px; transform: none;">
				<div id="dataset-manage-body" style="max-width: 900px; margin-top: 1px; float: left" class="modal-content">
					<label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" data-dismiss="modal" @click="router.push('/datasets/')"><XIcon class="w-8 h-8" /></label>
					
					<div class="modal-body p-8 text-left" v-if="layout.dataset.manage">

						<div class="flex flex-row items-center p-0 h-auto w-full">

							<!-- Preview dataset image -->
							<div class="w-20 h-20 xxl:w-24 xxl:h-24 image-fit mr-1" v-if="layout.dataset.manage?.preview"><img :src="layout.dataset.manage?.preview" class="w-full h-full rounded-full zoom-in cursor-default" /></div>
							<div class="w-20 h-20 xxl:w-24 xxl:h-24 image-fit mr-1" v-else><img class="rounded-full border border-gray-300 zoom-in" :src="require(`@/assets/images/rosepetal/icon/logoLoadingGray.png`)" /></div>

							<!-- Preview dataset name / type -->
							<div class="ml-3 text-left mt-0" v-if="layout.dataset.manage?.name">
								<a class="font-medium text-lg cursor-pointer" @click="edit()">{{layout.dataset.manage?.name.slice(0, 35)}}<span v-if="(layout.dataset.manage.name.length>35)">...</span> <Edit-3Icon class="w-4 h-4" /></a>
								<div class="text-gray-600 text-xs mt-0.5" v-if="layout.dataset.manage.type">
									<span v-if="layout.dataset.manage.type=='MULTICLASS'">{{$t('Classification')}}</span>
									<span v-if="layout.dataset.manage.type=='MULTILABEL'">{{$t('Segmentation')}}</span>
									<span v-else-if="layout.dataset.manage.type=='imageObjectDetection'">{{$t('Object Detection')}}</span>
								</div>
								<span v-if="layout.dataset.manage.createdDate" class="text-gray-600 mt-0.5 font-italic" style="font-size:11px">{{ layout.dataset.manage.createdDate }}</span>
							</div>

							<!-- Preview dataset buttons -->
							<div class="flex justify-end ml-auto">

								<div id="rpBtnReloadDataset" class="btn bg-gray-100 zoom-in border py-1.5 px-3 text-gray-600 mr-1" title="Reload" v-if="layout.dataset.manage" @click="refreshMosaic(); manageDataset(layout.dataset.manage)">
									<Refresh-cwIcon class="w-5 h-5" />
								</div>
								
								<div id="rpBtnDownloadDataset" class="btn bg-gray-100 zoom-in border py-1.5 px-3 text-gray-600 mr-1" title="Download dataset" v-if="layout.dataset.manage" @click="downloadDataset()">
									<DownloadIcon class="w-5 h-5" />
								</div>

								<div id="rpBtnPrevDataset" class="btn bg-gray-100 zoom-in border py-1.5 px-3 text-gray-600 mr-1" title="Previous dataset" @click="prev()" v-if="layout.dataset.idx && Object.keys(datasetsList).length && Object.keys(datasetsList).length>1">
									<Corner-up-leftIcon class="w-5 h-5" />
								</div>

								<div id="rpBtnNextDataset" class="btn bg-gray-100 zoom-in border py-1.5 px-3 text-gray-600" title="Next dataset" @click="next()" v-if="(layout.dataset.idx+1)<Object.keys(datasetsList).length && Object.keys(datasetsList).length && Object.keys(datasetsList).length>1">
									<Corner-up-rightIcon class="w-5 h-5" />
								</div>

							</div>
						</div>

						<div class="grid grid-cols-12 gap-2 mt-5">
							<div class="col-span-12">

								<!-- Tabs list -->
								<div class="intro-y">
									<div class="box py-2 bg-gray-100 px-20 border">
										<div class="chat__tabs nav nav-tabs justify-center" role="tablist">
											
											<a id="mdTabOverview" class="flex-1 py-2 mx-1 rounded-md text-center bg-white cursor-pointer border" @click="layout.tab='overview';" :class="layout.tab=='overview' ? 'border-blue-300 bg-blue-500 text-white' : 'text-gray-600'">
												{{$t('Overview')}}</a>
											
											<a id="mdTabLabels" class="flex-1 py-2 mx-1 rounded-md text-center bg-white text-gray-600 cursor-pointer border" @click="layout.tab='labels';" :class="layout.tab=='labels' ? 'active' : ''">
												{{$t('Tags')}}</a>
											
											<a id="mdTabImages" class="flex-1 py-2 mx-1 rounded-md text-center bg-white text-gray-600 cursor-pointer border" @click="layout.tab='images';" :class="layout.tab=='images' ? 'active' : ''">
												{{$t('Upload')}}</a>
											
											<a id="mdTabModels" class="flex-1 py-2 mx-1 rounded-md text-center bg-white text-gray-600 cursor-pointer border" @click="layout.tab='models';" :class="layout.tab=='models' ? 'active' : ''">
												{{$t('Models')}}</a>
											
											<a id="mdTabCopy" class="flex-1 py-2 mx-1 rounded-md text-center bg-white text-gray-600 cursor-pointer border" style="max-width: 50px" title="Copy" 
												@click="layout.tab='copy';" :class="layout.tab=='copy' ? 'active' : ''"><CopyIcon class="w-5 h-5" /></a>

											<template v-if="User && User.role && User.role.role_id && User.role.role_id == '3'">
												<a id="mdTabTools" class="flex-1 py-2 mx-1 rounded-md text-center bg-white text-gray-600 cursor-pointer border" style="max-width: 50px" title="Tools" 
													@click="layout.tools=true"><SettingsIcon class="w-5 h-5" /></a>
											</template>

										</div>
									</div>
								</div>

								<div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center" v-if="layout.loading.manage">
									<div class="flex items-center justify-center mt-10"><img :src="require(`@/assets/images/rosepetal/icon/logoLoadingGray.png`)" class="w-20" /></div>
									<LoadingIcon icon="three-dots" class="w-8 h-8" />
								</div>
								
								<div class="tab-content mt-3" v-else>

									<div id="overview" class="tab-pane" role="tabpanel" aria-labelledby="chats-tab" :class="layout.tab=='overview' ? 'active' : ''">
										<div class="grid grid-cols-12 gap-5 mt-5">
											
											<!-- Info section -->
											<div class="col-span-7">
												<div class="box px-3 py-10 shadow-none border border-solid border-gray-200">
													<div class="text-gray-700 px-5 font-medium">{{$t('Resume')}}</div>
													<div class="text-xs text-gray-600 px-5 mt-2" v-if="layout.dataset.manage.resumeTxt">{{ layout.dataset.manage.resumeTxt?.resume }}</div>
													<div class="text-gray-700 px-5 mt-5 font-medium">{{$t('A brief description of the goal')}}</div>
													<div v-if="layout.dataset.manage.description" class="text-xs text-gray-600 px-5 mt-2">{{ layout.dataset.manage.description }}</div> 
													<div v-else class="text-xs text-gray-600 px-5 mt-2">{{$t('Not defined')}}.</div>   
													<div class="input-form ml-5">
														<div class="flex justify-left text-xs mt-5">
															<div class="px-3 py-2 zoom-in border bg-gray-300 text-gray-700 hover:bg-theme-6 hover:text-white cursor-pointer mr-2 display-inline" @click="edit()">
																<Edit3Icon class="w-3 h-3" /> {{ $t('Edit') }}
															</div>
														</div>
													</div>
													<div v-if="layout.dataset.manage.descriptionGPT">
														{{ layout.dataset.manage.descriptionGPT }}
													</div>
												</div>
											</div>

											<!-- Status section -->
											<div class="col-span-5 text-left">
												<div v-if="layout.dataset.manage?.tagsCounter">
													<div class="box p-5 shadow-none border border-solid border-gray-200">
														<div class="flex items-center border-b border-gray-200 pb-2 mb-5" v-if="layout.dataset.manage?.datasetStatus">
															<div><div class="text-gray-600">{{ $t('Status') }}</div></div>
															<span class="text-green-600 ml-auto" v-if="!layout.dataset.manage?.datasetStatus?.inProgress">{{ 'Ready' }}</span>
															<span class="text-gray-600 ml-auto" v-if="layout.dataset.manage?.datasetStatus?.inProgress"><LoadingIcon icon="three-dots" class="w-4 h-4" /></span>
															<span class="text-yellow-500 ml-auto text-xs" v-if="layout.dataset.manage?.datasetStatus?.inProgress">{{ layout.dataset.manage?.datasetStatus?.action }}</span>
														</div>
														<div v-else>
															<div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center pb-2">
																<div class="text-xs text-center">{{ $t('Analyzing the dataset') }}</div>
																<LoadingIcon icon="three-dots" class="w-8 h-8" />
															</div>
														</div>
														<div class="flex items-center border-b border-gray-200 pb-2">
															<div><div class="text-gray-600">{{ $t('Images') }}</div><div class="mt-1 text-xs text-gray-500"><span class="mt-1 text-xs text-blue-400 cursor-pointer" @click="layout.tab='images'">{{ $t('manage') }}</span> </div></div>
															<span class="text-gray-600 ml-auto text-xl pb-2"><span v-if="layout.dataset.manage?.tagsCounter?.count">{{ this.helper.formatScore(layout.dataset.manage?.tagsCounter?.count) }}</span><span v-else>0</span></span>
														</div>
														<div class="flex items-center border-b border-gray-200 pb-2 pt-5">
															<div><div class="text-gray-600">{{ $t('Tags') }}</div><div class="mt-1 text-xs text-blue-400 cursor-pointer" @click="layout.tab='labels'">{{ $t('manage') }}</div></div>
															<span v-if="layout.dataset.manage.type=='MULTICLASS' && layout.dataset.manage.tagsCounter?.tags && Object.keys(layout.dataset.manage.tagsCounter.tags).length>1" class="text-gray-600 ml-auto text-xl pb-2">
																{{ Object.keys(layout.dataset.manage?.tagsCounter?.tags).length }}
															</span>
															<span v-else-if="layout.dataset.manage.type=='MULTILABEL' && layout.dataset.manage.tagsCounter?.tagsLabeled && Object.keys(layout.dataset.manage.tagsCounter.tagsLabeled).length" class="text-gray-600 ml-auto text-xl pb-2">
																{{ Object.keys(layout.dataset.manage?.tagsCounter?.tagsLabeled).length }}
															</span>
															<span v-else-if="layout.dataset.manage.type=='imageObjectDetection' && layout.dataset.manage.tagsCounter?.tagsLabeled && Object.keys(layout.dataset.manage.tagsCounter.tagsLabeled).length" class="text-gray-600 ml-auto text-xl pb-2">
																{{ Object.keys(layout.dataset.manage?.tagsCounter?.tagsLabeled).length }}
															</span>
															<span v-else class="text-gray-600 ml-auto text-xl pb-2">0</span>
														</div>
														<div class="flex items-center pt-5">
															<div><div class="text-gray-600">{{ $t('Models') }}</div><div class="mt-1 text-xs text-blue-400 cursor-pointer" @click="layout.tab='models'">{{ $t('manage') }}</div></div>
															<span class="text-gray-600 ml-auto text-xl pb-2" v-if="layout.dataset.manage?.models?.count">{{ layout.dataset.manage?.models?.count }}</span>
															<span v-else class="text-gray-600 ml-auto text-xl pb-2" @click="layout.tab='models'">0</span>
														</div>
													</div>
												</div>
												<div v-else>
													<div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center p-10">
														<div class="text-xs text-center">{{ $t('Analyzing the dataset') }}</div>
														<LoadingIcon icon="three-dots" class="w-8 h-8" />
													</div>
												</div>
											</div>

										</div>
									</div>

									<div class="tab-pane active" role="tabpanel" aria-labelledby="chats-tab" v-if="layout.tab=='labels'">
										<TagsCmp :dataset="layout.dataset.manage" ref="TagsCmp" />
									</div>
									
									<div class="tab-pane active" role="tabpanel" aria-labelledby="chats-tab" v-if="layout.tab=='images'">
										<DropzoneCmp :datasetId="layout.dataset.manage.id" ref="DropzoneCmp" class="m-10 mt-5" />
									</div>

									<div class="tab-pane active" role="tabpanel" aria-labelledby="chats-tab" v-if="layout.tab=='models'">
										<ModelsCmp :dataset="layout.dataset.manage" ref="ModelsCmp" />
									</div>

									<div class="tab-pane active" role="tabpanel" aria-labelledby="chats-tab" v-if="layout.tab=='copy'">
										<CopyCmp :dataset="layout.dataset.manage" ref="CopyCmp" />
									</div>

								</div>

								<div class="col-span-12 text-gray-600 mt-5 p-5 pl-0 font-italic text-xs border-t border-gray-200">
									<span v-if="layout.dataset.manage.updatedDate">{{$t('Dataset updated on')}} {{layout.dataset.manage.updatedDate}}</span>
									<span class="text-red-400 cursor-pointer pl-2" v-if="layout.tab=='overview'" @click="layout.dataset.delete = layout.dataset.manage;Modal('dataset-delete-box')"><Trash2Icon class="w-4 h-4 mr-1" />{{$t('delete dataset')}}</span>
									<span class="float-right text-gray-600 font-italic text-right text-xs">{{$t('Dataset')}} {{ layout.dataset.idx+1 }} {{$t('of')}} {{ Object.keys(datasetsList).length }}</span>
								</div>
								
							</div>
						</div>

					</div>
				</div>
			</div>

			<!-- TOOLS COMPONENT -->
			<div v-if="layout.tools"><ToolsCmp :options="{ 'dataset': layout.dataset.manage.id, 'datasetsList': {} }" ref="ToolsCmp" /></div>

		</div>

		<!-- Create dataset --> 
		<div id="dataset-create-box" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
			<div class="modal-dialog modal-xl">
				<div class="modal-content">
					<div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-12 p-10 pt-5" v-if="layout.loading.create">
						<div class="flex items-center justify-center mt-5"><img :src="require(`@/assets/images/rosepetal/icon/logoLoadingGray.png`)" class="w-20" /></div>
						<LoadingIcon icon="three-dots" class="w-10 h-10" />
					</div>
					<div v-else>
						<div class="modal-header p-5 pb-1 pt-3">
							<h2 class="intro-y text-xl font-medium">{{ $t('New dataset') }} <span class="text-sm pl-1 font-normal text-gray-700">{{ $t('Select a type of data and a goal') }}</span></h2>
							<label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer pt-2" data-dismiss="modal"><XIcon class="w-8 h-8 text-gray-600 hover:text-gray-900" /></label>
						</div>
						<div class="modal-body mt-0 pt-0">
							<form ref="newDataset" class="validate-form" @submit.prevent="addDataset($event)">
								<div class="relative text-gray-700 dark:text-gray-300 p-3">
									<div class="text-normal mb-2 font-bold">{{ $t('Dataset name') }} *</div>
									<input type="text" name="dsname" class="form-control py-3 px-4   pr-10 " v-model="layout.newDataset.name" required />
									<div class="text-xs pt-2 pl-2">{{ $t('Use up to 32 characters, which can include letters, numbers, and underscores') }}.</div>
								</div>
								<div class="grid chat grid-cols-12 gap-5 px-2 text-gray-600 pt-2 pb-3">
									<div class="col-span-6 xl:col-span-4 bg-gray-200 rounded p-2 text-left">
										<img :src="require(`@/assets/images/rosepetal/icon/newdataset_class.png`)" class="w-full" />
										<div class="text-lg mb-3 font-medium text-center text-gray-900 mt-3">{{ $t('Classification') }}</div>
										<div class="text-xs text-center mx-5 pb-4 text-gray-900 h-16">
											{{ $t('Images are assigned to tags') }}.
										</div>
										<div class="px-3">
											<div class="text-gray-800 pb-2 text-xs text-center">{{ $t('Types of models') }}</div>
											<div class="text-xs text-gray-600 bg-white p-3 rounded mb-3 h-20">
												<ul>
													<li>- {{ $t('Supervised') }}</li>
													<li>- {{ $t('Semi-supervised anomaly detection') }}</li>
													<li class="text-gray-500">- {{ $t('Unsupervised anomaly detection') }}</li>
												</ul>
											</div>
										</div>
										<div class="pb-3 text-center">
											<button type="submit" @click="layout.newDataset.type='MULTICLASS';" class="btn w-44 bg-green-600 text-white zoom-in font-normal">{{ $t('Create') }}</button> 
										</div>    
									</div>
									<div class="col-span-6 xl:col-span-4 bg-gray-200 rounded p-2 text-left">
										<img :src="require(`@/assets/images/rosepetal/icon/newdataset_od.png`)" class="w-full" />
										<div class="text-lg mb-3 font-medium text-center text-gray-900 mt-3">{{ $t('Object detection') }}</div>
										<div class="text-xs text-center mx-5 pb-4 text-gray-900 h-16">
											{{ $t('Delimiting rectangles are drawn around the objects to be detected') }}.
										</div>
										<div class="px-3">
											<div class="text-gray-800 pb-2 text-xs text-center">{{ $t('Types of models') }}</div>
											<div class="text-xs text-gray-600 bg-white p-3 rounded mb-3 h-20">
												<ul>
													<li>- {{ $t('Supervised') }}</li>
													<li class="text-gray-500">- {{ $t('Unsupervised anomaly detection') }}</li>
												</ul>
											</div> 
										</div>
										<div class="pb-3 text-center">
											<button type="submit" @click="layout.newDataset.type='imageObjectDetection'" class="btn w-44 bg-green-600 text-white zoom-in font-normal">{{ $t('Create') }}</button> 
										</div>
									</div>
									<div class="col-span-6 xl:col-span-4 bg-gray-200 rounded p-2 text-left">
										<img :src="require(`@/assets/images/rosepetal/icon/newdataset_segmentation.png`)" class="w-full" />
										<div class="text-lg mb-3 font-medium text-center text-gray-900 mt-3">{{ $t('Image segmentation') }}</div>
										<div class="text-xs text-center mx-5 pb-4 text-gray-900 h-16">
											{{ $t('To identify areas, defective areas are marked on each image. This task requires a higher level of detail') }}.
										</div>
										<div class="px-3">
											<div class="text-gray-800 pb-2 text-xs text-center">{{ $t('Types of models') }}</div>
											<div class="text-xs text-gray-600 bg-white p-3 rounded mb-3 h-20">
												<ul>
													<li>- {{ $t('Semi-supervised anomaly detection') }}</li>
													<li class="text-gray-500">- {{ $t('Unsupervised anomaly detection') }}</li>
												</ul>
											</div> 
										</div>
										<div class="pb-3 text-center">
											<button type="submit" @click="layout.newDataset.type='MULTILABEL'" class="btn w-44 bg-green-600 text-white zoom-in font-normal">{{ $t('Create') }}</button> 
										</div>
									</div>
									<input type="hidden" name="dstype" :value="layout.newDataset.type" />
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Delete dataset --> 
		<div id="dataset-delete-box" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
			<div class="modal-dialog modal-md">
				<div class="modal-content">
					<label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" data-dismiss="modal"><XIcon class="w-8 h-8" /></label>
					<div class="modal-body p-5 text-center">
						<div class="p-5 px-0 text-center" v-if="layout.dataset.delete">
							<div class="text-xl">{{  layout.dataset.delete.name }}</div>
							<div class="text-base mt-2">{{ $t('Are you sure to delete this dataset') }}?</div>
							<div class="text-xs mt-2" v-if="layout.dataset.delete.trained">{{ $t('There are models associated with this dataset') }}</div>
							<div class="text-gray-600 mt-2 text-xs">{{ $t('This action is irreversible') }}.</div>
						</div>
						<div class="px-5 pb-5 text-center">
							<button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1">{{ $t('Cancel') }}</button>
							<button type="button" class="btn bg-red-500 text-white w-24" @click="deleteDataset()">{{ $t('Delete') }}</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Edit dataset description -->
		<div id="dataset-edit-box" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
			<div class="modal-dialog modal-md">
				<div class="modal-content p-5 pb-2" v-if="layout.dataset.edit">
					<div class="modal-header p-5 pt-3 pb-2">
						<h2 class="intro-y text-xl font-medium">{{ $t('Dataset profile') }}</h2>
						<label class="form-label  :hover:text-blue-500 cursor-pointer float-right ml-auto" data-dismiss="modal"><XIcon class="w-8 h-8 text-gray-600 hover:text-gray-900" /></label>
					</div>
					<div class="relative text-gray-700 dark:text-gray-300 px-5 pt-3">
						<div class="text-normal mb-2 font-bold">{{ $t('Name') }}</div>
						<input type="text" class="form-control py-3 px-4 border-transparent bg-gray-200 pr-10" v-model="layout.dataset.edit.name"  />
						<div class="text-xs pt-2 text-gray-600 text-left">{{ $t('Use up to 32 characters, which can include letters, numbers, and underscores') }}.</div>
					</div>
					<div class="relative text-gray-700 dark:text-gray-300 px-5 pt-5 pb-5">
						<div class="text-normal mb-2 font-bold">{{ $t('A brief description of the goal') }}</div>
						<textarea v-model="layout.dataset.edit.description" class="form-control h-32 rounded border bg-gray-200 text-gray-600" :style="'font-size: 13px'" :placeholder="$t('add a description of the dataset goal...')"></textarea>
					</div>
					<div class="px-5 pb-5 text-center">
						<button class="btn box bg-green-600 text-white hover:bg-green-800 px-12 py-2 font-normal  text-center" @click="save()">{{$t('SAVE')}}</button>
					</div>
				</div>
			</div>
		</div>

		<!-- MODAL DOWNLOAD IMAGES -->
		<div id="modal-download-selected-preview" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
			<div class="modal-dialog modal-sm">
				<div class="modal-content">
					<label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" data-dismiss="modal"><XIcon class="w-8 h-8" /></label>
					<div class="modal-body px-5 text-center">
						<div v-if="layout.zip.loading" class="pb-2">
							<div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-5 mb-5"><LoadingIcon icon="ball-triangle" class="w-12 h-12" /></div>
							<div class="text-normal">{{ $t('Creating Zip file...') }} ({{ layout.zip.counter }} / {{ selectedImages.length }})</div>
							<div class="text-normal pb-5" v-if="selectedImages.length && layout.zip.counter==selectedImages.length">
								{{ $t('Your download will start automatically') }}
								<div>{{ $t('Please wait...') }}</div>
							</div>
						</div>
						<div v-else>
							<div class="p-2 text-center">
								<DownloadCloudIcon class="w-16 h-16 text-theme-1 mx-auto" />
								<div class="text-2xl ">{{ $t('Download') }}</div>
								<div class="text-normal">{{ selectedImages.length }} {{ $t('selected media') }}</div>
							</div>
							<div class="px-5 pb-3 text-center mt-5">
								<button id="dismiss-modal-download-selected-preview" type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-2">{{ $t('Cancel') }}</button>
								<button type="button" @click="downloadMedia()" class="btn btn-primary w-24 rpbtn1">{{ $t('Download') }}</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
   
	</div>
</template>

<script>
import { defineComponent, onMounted, computed, onUnmounted, ref, reactive, watch } from "vue";
import { useRouter } 	from "vue-router";
import { useStore } 	from "@/store";
import cash 			from "cash-dom";

import DropzoneCmp		from "@/components/dropzone/Main.vue";
import TagsCmp			from "@/components/manage-dataset/Tags.vue";
import ModelsCmp 		from "@/components/manage-dataset/Models.vue";
import CopyCmp			from "@/components/manage-dataset/Copy.vue";
import ToolsCmp 		from "@/components/manage-dataset/Tools.vue";
import imageEditor  	from "@/components/manage-image/Main.vue";

import * as rosepetalModel  from "rosepetal-model";
import { helper as $h } 	from "@/utils/helper";

let $dataset = rosepetalModel.default.dataset;
let $model   = rosepetalModel.default.model;

export default defineComponent({
	components: {
		DropzoneCmp,
		TagsCmp,
		ModelsCmp,
		CopyCmp,
		ToolsCmp,
		imageEditor
	},

	created () {
		this.helper = $h;
	},

	setup() {
		const User				= computed(() => store.state.main.User);
		const store				= useStore();
		const router			= useRouter();
		const listOptions		= reactive({});
		const datasetsList		= ref(false);
		const selectedImages	= ref([]);
		const selectToDataset 	= ref([]);

		const layout = ref({
			tab:    	"overview",
			tools: 		false,
			loading: 	{ create: false , list: false, manage: false },
			dataset: 	{ manage: false , delete: false , idx: 0, mosaic: false, edit: false },
			editor:  	{ options: {} },
			newDataset:	{ name: '', type: "MULTICLASS"},
			zip:  		{ loading: false, counter: 0 },
		});
    
		const Modal 		= (m) => { if (cash("#" + m)) { cash("#" + m).modal("show"); }};
		const modalClose 	= (m) => { cash("#" + m).modal("hide"); };

		const manageDataset = async(dataset, tab = false, modal = true) => {
			let dsIndex = Object.values(datasetsList.value).findIndex(d => d.id === dataset.id);
			if (dsIndex !== -1) layout.value.dataset.idx = dsIndex;

			layout.value.loading.manage = true;

			if (modal) { Modal('dataset-manage'); }

			layout.value.editor.options = { 
				'format'	: 'mosaic',
				'dataset'	: dataset.id,
				'limit'		: 10
			};

			let currentUrl = window.location.pathname.replace("/", "").toString().split('/');
			if (currentUrl[0] === 'datasets' && currentUrl[2]) { 
				if (layout.value.dataset.manage.id) {
					if (layout.value.dataset.manage.id === dataset.id) {
						router.push('/datasets/' + dataset.id + '/' + currentUrl[2]);
						layout.value.editor.options.id = currentUrl[2];
					}
					if (currentUrl[2] === 'tools') { 
						layout.value.tools = true; 
					}
				} else {
					router.push('/datasets/' + dataset.id + '/' + currentUrl[2]);
					layout.value.editor.options.id = currentUrl[2];
				}
			} else { 
				router.push('/datasets/' + dataset.id); 
			}

			layout.value.tab = "overview";
			layout.value.dataset.manage = dataset;
			layout.value.loading.manage = false;

			if (dataset.type)	layout.value.editor.options.type = dataset.type;
			if (tab) 			layout.value.tab = tab;

			const datasetData = await $dataset.get(dataset.id, { models: true, tagsCounter: true });
			sessionStorage.setItem('datasetData', JSON.stringify(datasetData));

			layout.value.dataset.manage.models 			= datasetData.models;
			layout.value.dataset.manage.tags			= datasetData.tags;
			layout.value.dataset.manage.tagsCounter		= datasetData.tagsCounter;

			if (document.getElementById("fixedMosaicBox") && document.getElementById("dataset-manage-body")) {
				cash('#fixedMosaicBox').insertBefore(document.getElementById("dataset-manage-body"));
			}
			
			layout.value.dataset.manage.datasetStatus 	= await $dataset.getStatus(datasetData);
			layout.value.dataset.manage.resumeTxt 		= await $dataset.generateResume(datasetData.id, { tagsCounter: datasetData.tagsCounter, models: datasetData.models });
		}

		const deleteDataset = async () => { 
			cash("#dataset-delete-box").modal("hide"); 
			await $dataset.delete(layout.value.dataset.delete.id).then( async () => { 
				$h.NotificationTxt({ text: "Dataset successfully deleted"});
				await reset().then( async () => { router.push('/datasets') }) 
			})
		}

		const save = async () => {
			if (layout.value.dataset.edit) {
				cash('#dataset-edit-box').modal('hide');
				layout.value.loading.manage = true;
				await $dataset.update(layout.value.dataset.edit.id, { name: layout.value.dataset.edit.name, description: layout.value.dataset.edit.description }).then( async () => { 
					$h.NotificationTxt({ text: "Dataset profile updated"});
					layout.value.dataset.edit = {}
					await load().then( async () => { await manageDataset(datasetsList.value[layout.value.dataset.idx]) })
					layout.value.loading.manage = false
				})
			}
		}

		const edit 	= async () => { 
			Modal('dataset-edit-box'); 
			layout.value.dataset.edit = { 
				id: layout.value.dataset.manage?.id, 
				name: layout.value.dataset.manage?.name, 
				description: layout.value.dataset.manage?.description 
			}
		}
		
		const reset	= async () => { 
			cash("#dataset-manage").modal("hide");
			listOptions.value = { 
				type: "",
				trained: "",
				order: "desc"
			}
		}
		
		const labeling = async (dataset) => { 
			manageDataset(dataset).then( () => { 
				setTimeout(() => { 
					if (document.getElementById('BtnDatasetLabeling')) {
						document.getElementById('BtnDatasetLabeling').click(); 
						router.push('/datasets/' + dataset.id + '/labeling') 
					}
				}, 200)
			})
		}

		const downloadDataset = async () => {
			const images 			= await $dataset.getImages(layout.value.dataset.manage);
			selectedImages.value 	= images.media;
			Modal("modal-download-selected-preview");
		}

		const downloadMedia = async () => {
			layout.value.zip.loading = true;
			layout.value.zip.counter = 0;

			selectToDataset.value = await $dataset.getImagesData({ 
				images: 			selectedImages.value, 
				zipCounter:			layout.value.zip.counter,
				completeDataset:	true
			}, (currentProgress) => {{
				layout.value.zip.counter = currentProgress;
			}});

			await $dataset.createZip(layout.value.dataset.manage, selectToDataset.value);

			cash("#modal-download-selected-preview").modal("hide");
			layout.value.zip.loading = false;
		}

		const prev = async () => {
			let idx = layout.value.dataset.idx;
			if ((idx - 1) >= 0) idx--;
			layout.value.dataset.idx = idx;
			await manageDataset(datasetsList.value[idx])
		}

		const next = async () => {
			let idx = layout.value.dataset.idx;
			if ((idx + 1) < Object.keys(datasetsList.value).length) idx++;
			layout.value.dataset.idx = idx;
			await manageDataset(datasetsList.value[idx]);
		}

		const load = async () => {
			let currentDate = new Date();
			layout.value.newDataset.name = "untitled_" + currentDate.getTime();

			layout.value.loading.list = true;
			datasetsList.value = await $dataset.list(listOptions.value);
			layout.value.loading.list = false;

			if (router.currentRoute.value.params.id) { await loadDataset(router.currentRoute.value.params.id); }

			if (datasetsList.value.length) {
				const promises = datasetsList.value.map(dataset => {
					if (dataset) {
						return $dataset.getPreview(dataset.id)
						.then(resp => { if (resp.preview) {  dataset.preview = resp.preview; } });
					}
				});
				await Promise.all(promises);
			}
		}

		const loadDataset = async (datasetId) => {
			let dsIndex = Object.values(datasetsList.value).findIndex(dataset => dataset.id === datasetId);
			if (dsIndex !== -1) {
				layout.value.dataset.idx = dsIndex;
				await manageDataset(datasetsList.value[dsIndex]);
			}
		}

		watch(listOptions , async () => { await load(); });

		window.onpopstate = async function() {
			let currentUrl   = window.location.pathname;
			currentUrl       = currentUrl.replace("/", "");
			let _cDs         = currentUrl.toString().split('/');

			if (_cDs[0] && _cDs[0] === 'datasets') {
				if (!_cDs[1]) {
					cash('#dataset-manage, #modal-image-editor').modal('hide') 
				}
				else if (_cDs.length == 2) {
					cash('#modal-image-editor').modal('hide'); 
					layout.value.editor.options.id = false;
					if (_cDs[1] !== layout.value.dataset.manage.id) await loadDataset(_cDs[1]);
				} else { console.log(_cDs); }
			}
		}

		onMounted( async () => { 
			await reset();
			if (!router.currentRoute.value.params.id) router.push('/datasets/');
			cash("body").css("overflow-y", "hidden");
		});

		onUnmounted( async () => { 
			if (cash("#dataset-manage")) cash("#dataset-manage").remove();
			layout.value.dataset.idx = 0;
			cash("body").css("overflow-y", "scroll");
		});

		return {
			layout,
			User,
			router,
			datasetsList,
			listOptions,
			selectedImages,
			selectToDataset,
			manageDataset,
			deleteDataset,
			downloadDataset,
			downloadMedia,
			Modal,
			modalClose,
			load,
			reset,
			edit,
			save,
			labeling,
			next,
			prev
		};
	},

	methods: {
		refreshMosaic: async function() { 
			this.$refs['imageEditorComponent'].reLoad() 
		},

		refreshDataset: async function() { 
			await this.manageDataset(this.layout.dataset.manage) 
		},

		goDataset: async function(ds) { 
			await this.manageDataset(ds, false, false) 
		},
		
		hideTools: async function() { 
			this.layout.tools = false; 
		},

		addDataset: async function(event) {
			if (event) event.preventDefault();
			this.layout.loading.create = true;

			if (this.layout.newDataset.name && this.layout.newDataset.type) {
				await $dataset.create({ name: this.layout.newDataset.name, type: this.layout.newDataset.type }).then( async response => {
					if (response.datasetId) {
						await $model.createVertex(response.datasetId).then( async resp => {
							cash("#dataset-create-box").modal("hide");
							console.log('Dataset created: ', response, resp);
							this.layout.loading.create = false;
							await this.reset();
						});
					}
				});
			}
		},
	}
});
</script>

<style>
#dataset-manage-page {
	position: relative;
	overflow-y: scroll;
	height: 100vh; 
	-ms-overflow-style: none; 
	scrollbar-width: none;
}

#dataset-manage-page::-webkit-scrollbar {
	display: none;
}

#dataset-manage { 
	background-color: #f2f3f3 !important;
	padding-left: 0 !important;
}

#dataset-manage .modal-dialog { 
	min-width: 900px !important; 
}

#dataset-manage .nav.nav-tabs .active {
	--tw-border-opacity: 1;
	background-color: #1a73e8;
	border-color: #1a73e8;
	font-weight: 500;
	color: #fff;
}

.rpalert1 { 
	padding: 20px 15px 20px 15px !important; 
	background-color: #fff8ec !important;
}

.rpalert2 { 
	padding: 10px 0 !important; 
	background-color: #FFCDD2 !important;
}

.rpalert3 { 
	padding: 2px 0 !important; 
	background-color: #fff8ec !important;
}

.rpalert4 { 
	padding: 2px 0 !important; 
	background-color: #f2f2f2 !important;
}

.rpalert5 { 
	padding: 20px 15px 20px 15px !important; 
	background-color: #fff !important; 
	border-left: 3px solid #ff0000
}

.rpalert5 .btn { 
	border: 1px solid #202020 !important
}
</style>