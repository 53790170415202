<template>
    <div id="helper-tour" v-if="layout.helper.step">
        <div class="helpTourStep" :class="layout.helper.step === 1 ? '' : 'hidden'">
            <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" @click="closeHelper()"><XIcon class="w-10 h-10" /></label>
            <h2 class="text-xl pt-3"><Help-circleIcon class="w-10 h-10 mr-2" />Use guide</h2>
            <p class="mb-5 pl-3 pt-5">
                The image editing environment has a variety of tools for labeling the images in your data set.
                The purpose of this labeling process is to prepare the images for training a machine learning model.
            </p>
            <div class="border-t-2 py-2 mr-5 text-base">
                <button @click="closeHelper()" class="text-gray-700 hover:text-blue-700">Exit</button>
                <button @click="layout.helper.step = 2" class="text-blue-700 float-right ml-auto">Next</button>
            </div>
        </div>
        <div class="helpTourStep" id="htsMetas" :class="layout.helper.step === 2 ? '' : 'hidden'">
            <label class="float-right ml-auto :hover:text-blue-500 cursor-pointer pr-0 mr-0" @click="closeHelper()"><XIcon class="w-8 h-8" /></label>
            <h2 class="text-lg"><Corner-left-upIcon class="w-8 h-8 align-middle" /> <span class="pt-5 align-middle">Image info</span></h2>
            <div class="text-left mt-3 mb-5 ml-2">
                <div class="text-sm font-medium">Editor mode</div>
                <div class="text-sm font-medium mt-2">Created</div>
                <div class="text-left text-gray-600 ml-3">
                    <p class="pt-1">The date and time that the image was created.</p>
                </div>
                <div class="text-sm font-medium mt-2">Image size</div>
                <div class="text-left text-gray-600 ml-3">
                    <p class="pt-1">The size of the image in KB.</p>
                </div>
                <div class="text-sm font-medium mt-2">Image name</div>
                <div class="text-left text-gray-600 ml-3">
                    <p class="pt-1">The name of the image.</p>
                </div>
            </div>
            <div class="border-t-2 py-2 mr-5 text-base">
                <button @click="layout.helper.step = 1" class="text-gray-700 hover:text-blue-700">Previous</button>
                <button @click="layout.helper.step = 3" class="text-blue-700 float-right ml-auto">Next</button>
            </div>
        </div>
        <div class="helpTourStep" id="htsTools" :class="layout.helper.step === 3 ? '' : 'hidden'">
            <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" @click="closeHelper()"><XIcon class="w-8 h-8 " /></label>
            <Corner-up-leftIcon class="w-12 h-12" />
            <h2 class="text-lg">Design tools</h2>
            <div class="mr-3 mb-5">
                <div class="text-left mt-3"><span class="mr-5 font-medium text-black">Position:</span></div>
                <div class="text-left text-gray-600 ml-3 mr-10 mt-3"><p>You can adjust the image position in the canvas by dragging it with the mouse.</p></div>  
                <div class="mr-5 font-medium text-black mt-3">Zoom</div>
                <p class="pt-1 ml-3 text-gray-600">Use the zoom function with the wheel of your mouse to take a closer look at the details of each image.</p>
                <div class="mr-5 font-medium text-black mt-3">Reset image position and filters</div>
                <p class="pt-1"><button type="button" class="btn bg-gray-600 text-white mt-3 display-inline cursor-default" title="Reset filters"><RotateCcwIcon class="w-4 h-4" /></button></p>
                <div class="mr-5 font-medium text-black mt-3">Acces to the dataset labeling gallery for filter the images</div>
                <div class="pt-2"><div class="w-44"><button type="button" class="btn bg-gray-200 text-gray-600 mt-2 display-inline text-xs px-0 w-full py-3 cursor-default">{{ $t('DATASET LABELING')}}</button></div></div>
            </div>
            <div class="border-t-2 py-2 mr-5 text-base">
                <button @click="layout.helper.step = 2" class="text-gray-700 hover:text-blue-700">Previous</button>
                <button @click="layout.helper.step = 4" class="text-blue-700 float-right ml-auto">Next</button>
            </div>
        </div>
        <div class="helpTourStep" id="htsTools2" :class="layout.helper.step === 4 ? '' : 'hidden'">
            <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" @click="closeHelper()"><XIcon class="w-8 h-8 " /></label>
            <Corner-up-leftIcon class="w-12 h-12" />
            <h2 class="text-lg">Design tools</h2>
            <div class="mr-3 mb-5">
                <div v-if="props?.type=='MULTICLASS'">
                    <div class="text-left mt-2">
                        <div class="mr-5 font-medium text-black mb-3">Mask drawing</div>
                        <div class="grid grid-cols-12">
                        <div class="col-span-1 text-center"><img :src="require(`@/assets/images/rosepetal/icon/pencil.png`)" style="vertical-align: top; width: 30px;"  /></div> 
                        <div class="col-span-11 text-left mr-5">
                            <div class="col-span-12 text-gray-600 p-5 pt-0 pb-2">
                                The mask drawing brush in image editing is a tool used in semi-supervised machine learning model training. 
                                Its purpose is to allow the user to mark or label areas of interest in the images so that the model can learn to recognize those areas and assign categories to them.
                                <p class="pt-3">The accuracy of the labels is crucial to the model's proper functioning, as they are used as training data.</p> 
                            </div>
                        </div>   
                        </div>   
                    </div>
                    <div class="text-left">
                        <div class="mr-5 font-medium text-black mb-3">Erase</div>
                        <div class="grid grid-cols-12">
                        <div class="col-span-1 text-center"><img :src="require(`@/assets/images/rosepetal/icon/erase.png`)" style="vertical-align: top; width: 30px;"  /></div> 
                        <div class="col-span-11 text-left mr-5">
                            <div class="col-span-12 text-gray-600 p-5 pt-0">
                                Erase tool is used to remove the mask from the image, can delete the entire mask or just a part of it.
                            </div>
                        </div>   
                        </div>   
                    </div>
                    <p class="mb-5 text-gray-600 mx-5">
                        When tagging images, it's important to be consistent in your tagging, as this will help minimize errors in the machine learning model. 
                        For example, if you are tagging objects in images, be sure to use the same term for the same object in each image.
                    </p>
                </div>
                <div v-if="props?.type=='imageObjectDetection'">
                    <div class="text-left mt-3">
                        <div class="mr-5 font-medium text-black mb-3">Move bounding box</div>
                        <div class="grid grid-cols-12">
                            <div class="col-span-1 text-center"><MoveIcon class="w-10 ml-1" /></div> 
                            <div class="col-span-11 text-left mr-5">
                                <div class="col-span-12 text-gray-600 p-5 pt-0 pb-2">
                                    Use to move any bounding box to the desired position, you can scale the box by dragging the corners. 
                                </div>
                            </div>   
                        </div>   
                    </div>
                    <div class="text-left">
                        <div class="mr-5 font-medium text-black mb-3">Draw bounding box</div>
                        <div class="grid grid-cols-12">
                            <div class="col-span-1 text-center"><CrosshairIcon class="w-10 ml-1" /></div> 
                            <div class="col-span-11 text-left mr-5">
                                <div class="col-span-12 text-gray-600 p-5 pt-0 pb-2">
                                    Use to draw a new bounding box around the object you want to tag, when you are done, click on the new item in the right labeling list to assign a label to the bounding box.
                                </div>
                            </div>   
                        </div>   
                    </div>
                    <div class="text-left">
                        <div class="mr-5 font-medium text-black mb-3">Filter controls</div>
                        <div class="grid grid-cols-12">
                            <div class="col-span-1 text-center"><SlidersIcon class="w-10 ml-1" /></div> 
                            <div class="col-span-11 text-left mr-5">
                                <div class="col-span-12 text-gray-600 p-5 pt-0 pb-2">
                                    Use the filter controls to adjust the brightness, contrast, saturation, vibrance of the image. The adjustment can allow you to better define the details of the part of the image you want to tag.
                                </div>
                            </div>   
                        </div>   
                    </div>
                    <div class="text-left">
                        <div class="mr-5 font-medium text-black mb-3">Auto detection</div>
                        <div class="grid grid-cols-12">
                            <div class="col-span-1 text-center"><CpuIcon class="w-10 ml-1" /></div> 
                            <div class="col-span-11 text-left mr-5">
                                <div class="col-span-12 text-gray-600 p-5 pt-0 pb-2">
                                    If the image does not have tag elements, the system will automatically detect the elements and create the bounding boxes, you can then assign the labels to them.
                                    <p>You have two detection environments to use.</p>
                                    <div>
                                        <button type="button" class="btn bg-theme-21 text-white mt-3 display-inline cursor-default"><CpuIcon class="w-4 h-4" /></button>
                                        <span class="ml-2">Use this button to auto detect the elements.</span>
                                    </div>
                                </div>
                            </div>   
                        </div>   
                    </div>
                </div>
            </div>
            <div class="border-t-2 py-2 mr-5 text-base">
                <button @click="layout.helper.step = 3" class="text-gray-700 hover:text-blue-700">Previous</button>
                <button @click="layout.helper.step = 5" class="text-blue-700 float-right ml-auto">Next</button>
            </div>
        </div>
        <div class="helpTourStep" id="htsComments" :class="layout.helper.step === 5 ? '' : 'hidden'">
            <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" @click="closeHelper()"><XIcon class="w-8 h-8" /></label>
            <Corner-up-leftIcon class="w-12 h-12" />
            <h2 class="text-lg">Image comments</h2>
            <div class="text-left mb-5 mt-3 text-gray-600">Use this area to comment everything you can contribute about the image and the objects in it, for example, the image quality, the objects position or size... and anything you think is important.</div>
            <div class="border-t-2 py-2 mr-5 text-base">
                <button @click="layout.helper.step = 4" class="text-gray-700 hover:text-blue-700">Previous</button>
                <button @click="layout.helper.step = 6" class="text-blue-700 float-right ml-auto">Next</button>
            </div>
        </div>
        <div class="helpTourStep" id="htsTags" :class="layout.helper.step === 6 ? '' : 'hidden'">
            <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" @click="closeHelper()"><XIcon class="w-8 h-8" /></label>
            <h2 class="text-lg pt-1">Data division and labeling</h2>
            <div class="text-left mt-3 mr-2 mb-5">
                <div class="text-base font-medium">Data division</div>
                <div class="float-right ml-auto pl-3 pb-1"><Corner-up-rightIcon class="w-12 h-12" /></div>
                <div class="text-left text-gray-700">
                    <p class="pt-3">After you have labeled all the images, you can divide the data into training and test sets. 
                    The training set is used to train the machine learning model, while the test set is used to evaluate the model's performance. 
                    The test set is not used to train the model, so it is important to ensure that the test set is representative of the data that the model will be used on in the future.</p>
                </div>
                <div v-if="props?.type=='MULTICLASS'">
                    <div class="text-base font-medium mt-5">Labeling image classification with a single label</div>
                    <div class="text-left text-gray-600">
                        <p class="pt-3">
                        The labeling process is done by assigning a tag to each image. 
                        The tags are defined by the user and can be anything that is relevant to the data. 
                        For example, if you are labeling images of cars, you can define the tags as "sedan", "SUV", "truck", etc. 
                        The tags are defined in the <a class="text-blue-500">Tags section</a> of the dataset management.</p>
                    </div>
                </div>
                <div v-if="props?.type=='imageObjectDetection'">
                    <div class="text-base font-medium mt-5">Labeling image with vision Object Detection</div>
                    <div class="text-left text-gray-600">
                        <div class="pt-3">
                            The labeling process is done by drawing bounding boxes around the objects in the image and assigning a tag to each object.
                            <p class="pt-1">When creating a new bounding box the system assign a "New Label", you can change the name for create a new tag or select an existing tag class.</p>
                            <p class="pt-1">Each label is defined by color that you can manage in the edition of Tags of the dataset, the color is used to identify the label in the image.</p>
                        </div>
                    </div>
                </div>
                <div id="hideDrawingStatusBtn" @click="drawingStatus(false)"></div><div id="showDrawingStatusBtn" @click="drawingStatus(true)"></div>
            </div>
            <div class="border-t-2 py-2 mr-5 text-base">
                <button @click="layout.helper.step = 5" class="text-gray-700 hover:text-blue-700">Previous</button>
                <button @click="layout.helper.step = 7" class="text-blue-700 float-right ml-auto">Next</button>
            </div>
        </div>
        <div class="helpTourStep" id="hSave" :class="layout.helper.step === 7 ? '' : 'hidden'">
            <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" @click="closeHelper()"><XIcon class="w-8 h-8" /></label>
            <h2 class="text-lg">Save or discard</h2>
            <div class="text-left mt-3 mb-5">
                <div class="text-sm font-medium">Save</div>
                <div class="text-left text-gray-600 ml-3">
                    <p class="pt-1">When you are done, you can save the labels by clicking the <SaveIcon class="w-5 h-5" />.</p>
                    <p class="pt-1">You will be redirected to the next image in the dataset.</p>
                </div>
                <div class="text-sm font-medium mt-2">Download</div>
                <div class="text-left text-gray-600 ml-3">
                    <p class="pt-1">You can download the original image by clicking the <DownloadIcon class="w-5 h-5" />.</p>
                </div>    
                <div class="text-sm font-medium mt-2">Delete</div>
                <div class="text-left text-gray-600 ml-3">
                    <p class="pt-1">You can delete the image by clicking the <TrashIcon class="w-5 h-5" />.</p>
                    <p>This action is irreversible.</p>
                </div>   
                <div class="text-sm font-medium mt-2">Previous</div>
                <div class="text-left text-gray-600 ml-3">
                    <p class="pt-1">You can go to the previous image by clicking the <Skip-backIcon class="w-5 h-5" />.</p>
                </div> 
                <div class="text-sm font-medium mt-2">Next</div>
                <div class="text-left text-gray-600 ml-3">
                    <p class="pt-1">You can go to the next image by clicking the <Skip-forwardIcon class="w-5 h-5" />.</p>
                </div>   
                <div class="text-sm font-medium mt-2">Exit</div>
                <div class="text-left text-gray-600 ml-3">
                    <p class="pt-1">You can exit the labeling process by clicking the <Log-outIcon class="w-5 h-5" />.</p>
                    <p>Changes made will not be saved.</p>
                </div> 
            </div>
            <div class="border-t-2 py-2 mr-5 text-base">
                <button @click="layout.helper.step = 6" class="text-gray-700 hover:text-blue-700">Previous</button>
                <button @click="closeHelper()" class="text-blue-700 float-right ml-auto">Exit</button>
            </div>
        </div>
        <div class="helpTourOverlay"></div>
    </div>
</template>

<script>
import { defineComponent, onMounted, onUnmounted, ref, watch} from "vue"; 

export default defineComponent({
    props: {
        type: {
            type: String,
            required: true
        }
    },
    setup(props) {
        
        const layout   = ref( { helper: { step: 1 }});
        
        const restartValues = async () => { 
            var e  = document.getElementsByClassName("helpTourStepFocusBorder"); for (var i = 0; i < e.length; i++) { e[i].classList.remove("helpTourStepFocusBorder"); }
            var e2 = document.getElementsByClassName("helpTourStepFocus"); for (var ii = 0; ii < e2.length; ii++) { e2[ii].classList.remove("helpTourStepFocus"); }
            var e3 = document.getElementsByClassName("editorTopTool"); for (var b = 0; b < e3.length; b++) { e3[b].classList.remove("helpTourStepFocus"); }
            var e4 = document.getElementsByClassName("editorTopTool"); for (var c = 0; c < e4.length; c++) { e4[c].classList.remove("helpTourStepFocusYellow"); }
            document.getElementById("editorLeftPanel").style.zIndex = "99";
        }

        onMounted( async () => { restartValues(); console.log(props)});
        onUnmounted( async () => { restartValues() });

        watch(() => layout.value.helper.step, async () => { 
            restartValues()
            switch(layout.value.helper.step){
                case 2: 
                    if(document.getElementById("muticlass-image-mode-top")){
                        document.getElementById("htsMetas").style.left = "10px"
                        document.getElementById("htsMetas").style.top  = "40px"
                        document.getElementById("htsMetas").style.transform = 'none';
                        var e = document.getElementsByClassName("editorTopTool"); for (var ii = 0; ii < e.length; ii++) { e[ii].classList.add("helpTourStepFocusYellow"); }
                    }
                    break
                case 3:  
                    document.getElementById("hideDrawingStatusBtn").click()
                    document.getElementById("htsTools").style.left = parseFloat(document.getElementById("canvasImageTools").style.left) + 200 + 'px'
                    document.getElementById("htsTools").style.top  = (document.getElementById("canvasImageTools").style.top)
                    document.getElementById("htsTools").style.transform = 'none';
                    document.getElementById("canvasImageTools").classList.add("helpTourStepFocusBorder");
                    break
                case 4:  
                    document.getElementById("showDrawingStatusBtn").click()
                    document.getElementById("htsTools2").style.left = parseFloat(document.getElementById("canvasImageTools").style.left) + 200 + 'px'
                    document.getElementById("htsTools2").style.top  = parseFloat(document.getElementById("canvasImageTools").style.top) + 60 + 'px'
                    document.getElementById("htsTools2").style.transform = 'none';
                    document.getElementById("canvasImageTools").classList.add("helpTourStepFocusBorder");
                break
                case 5:
                    document.getElementById("htsComments").style.top      = "auto"
                    document.getElementById("htsComments").style.right    = "auto"
                    document.getElementById("htsComments").style.left     = "90px"
                    document.getElementById("htsComments").style.bottom   = "50px"
                    document.getElementById("htsComments").style.transform = 'none';
                    document.getElementById("imgEditComments").style.left     = "20px"
                    document.getElementById("imgEditComments").style.bottom   = "250px"
                    document.getElementById("imgEditComments").classList.add("helpTourStepFocusBorder");
                break   
                case 6:  
                    document.getElementById("htsTags").style.right = parseFloat(document.getElementById("editorLeftPanel").offsetWidth) + 20 + 'px'
                    document.getElementById("htsTags").style.left  = "auto"
                    document.getElementById("htsTags").style.top   = "60px"
                    document.getElementById("htsTags").style.transform = 'none';
                    document.getElementById("editorLeftPanel").style.zIndex = "99999";
                    document.getElementsByClassName("editorLabelingTools")[0].classList.add("helpTourStepFocus");
                break  
                case 7:  
                    document.getElementById("hSave").style.right     = parseFloat(document.getElementById("editorLeftPanel").offsetWidth) + 20 + 'px'
                    document.getElementById("hSave").style.left      = "auto"
                    document.getElementById("hSave").style.top       = "20px"
                    document.getElementById("hSave").style.transform = 'none';
                    document.getElementById("editorLeftPanel").style.zIndex = "99999";
                    document.getElementsByClassName("editorSaveTools")[0].classList.add("helpTourStepFocus");
                break   
            }
            });
        
        return {
            props,
            layout
        };
    },
    methods: {
        closeHelper: async function () { await this.$parent.closeHelper()},
        drawingStatus: async function (newStat) { await this.$parent.drawingStatus(newStat) },
    }
});
</script>

<style>
.helpTourStep {position: absolute;top: 250px;left: 50%;transform: translate(-50%, -50%);background: white;padding: 10px 10px 20px 20px;box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);border-radius: 10px;z-index: 9999;width: 480px;}
.helpTourOverlay {position: fixed;top: 0;right: 0;bottom: 0;left: 0;background: rgba(0, 0, 0, 0.7);z-index: 999;}
.helpTourStepFocusYellow{ background-color: #f0d78d !important; z-index: 99999 !important; color: #000 !important; opacity: 1 !important; }
.helpTourStepFocus{ background-color: #fcfad3 !important; z-index: 99999 !important; color: #000 !important; opacity: 1 !important; border-color: #fcfad3 !important }
.helpTourStepFocus .roundTagIcon{ border: 3px solid #fff !important;}
.helpTourStepFocus select{ background-color: #fff !important;}
.helpTourStepFocus .editorSaveBtn{ border-color: #ccc !important;}
.helpTourStepFocusBorder{ border: 3px solid #f2e2a3 !important; z-index: 99999 !important;  }
</style>